import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import './Topbar.css';
export default function Topbar() {
  const [location, setLocation] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countryIso, setCountryIso] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  const [cookies, setCookie] = useCookies(["city", "state", "country", "countryiso"]);

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  useLayoutEffect(() => {
    const storedLocation = [cookies.city, cookies.state, cookies.country, cookies.countryiso];
    if (storedLocation[2] !== undefined) {
      const city = storedLocation[0];
      const state = storedLocation[1];
      const country = storedLocation[2];
      const countryiso = storedLocation[3];
      setCity(city);
      setState(state);
      setCountry(country);
      setCountryIso(countryiso);
      setUserLocation(`${city}, ${state}, ${country}`);
    } else {
      getLocation();
    }
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

          reverseGeocode(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser!");
    }
  };

  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      );
      const data = await response.json();
      setLocation(data.results[0].formatted_address);
      if (data.results && data.results.length > 0) {
        let city = "",
          state = "",
          country = "",
          countryiso = "";
        data.results.forEach((result) => {
          result.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              city = component.long_name;
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              state = component.long_name;
            } else if (component.types.includes("country")) {
              country = component.long_name;
              countryiso = component.short_name;
            }
          });
        });
        setCity(city);
        setState(state);
        setCountry(country);
        setCountryIso(countryiso);
        setUserLocation(`${city}, ${state}, ${country}`);
        // Set location cookie with a 1-day expiry
        setCookie("city", city, {
          expire: new Date(Date.now() + 86400 * 1000),
          path: "/",
        });
        setCookie("state", state, {
          expire: new Date(Date.now() + 86400 * 1000),
          path: "/",
        });
        setCookie("country", country, {
          expire: new Date(Date.now() + 86400 * 1000),
          path: "/",
        });
        setCookie("countryiso", countryiso, {
          expire: new Date(Date.now() + 86400 * 1000),
          path: "/",
        });
      } else {
        setError("Location not found!");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      setError("Error fetching location");
    }
  };

  return (
    <>
      <div className="sticky-header">
        <div className="upper-container">
          <div className="icon-container">
            <i className="fa fa-global"></i>
          </div>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              {userLocation === "" ? (
                <span className="nav-link-location-not-available header-text">
                  <i className="bi bi-geo-alt-fill"></i> Please Allow Location!
                </span>
              ) : (
                <span className="nav-link-location header-text">
                  <i className="bi bi-geo-alt-fill"></i> {userLocation}
                </span>
              )}
            </li>
          </ul>
          <div className="icon-container">
            <i className="fa fa-global"></i>
          </div>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link nav-global header-text" to="/global-presence">
                <i className="bi bi-globe"></i> Global
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
