import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  getToken,
  storeLocation,
  getBookingHistory,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import Logo from "../Logo/Logo";
import Mainmenu from "../Mainmenu/Mainmenu";
import "./Navbar.css";
import { LoginButton, RegisterButton } from "../Button/Button";
import Topbar from "../Topbar/Topbar";
export default function Navbar() {
  const userLoggedData = getToken();
  const countryUrl = process.env.REACT_APP_URL_COUNTRY_CODE;
  const scrollRef = useRef(null);
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    const handleMenuClick = () => {
      setNavbarCollapsed(true);
    };

    const menuLinks = document.querySelectorAll(".nav-link");
    menuLinks.forEach((link) => {
      link.addEventListener("click", handleMenuClick);
    });
    return () => {
      // Clean up the event listeners
      menuLinks.forEach((link) => {
        link.removeEventListener("click", handleMenuClick);
      });
    };
  }, []);
  const scrollToContent = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Topbar />
      <nav className="navbar navbar-expand-sm bg-light navbar-light">
        <div className="container" id="header-width">
          {/* Logo File */}
          <Logo />
          {/* Mainmenu */}
          <Mainmenu />
          {/* Login Button */}
        </div>
      </nav>
    </>
  );
}
