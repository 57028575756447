import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
export default function Services() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("services");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);
  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/white-sport-sedan-with-colorful-tuning-road.jpg"}
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Services</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width">
        <div className="pricing-header p-3 pb-md-4 text-center">
          <h2 className="mb-0 fw-bold lh-lg">Types of Services</h2>
          <p>
            In the market of car rental services, We provide you with various
            best services which would make you choose us.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="rn-dot-feature text-center p-4 border rounded shadow">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/EmployeeTransportation.png"
                }
                className="img-fluid w-25"
                alt="services"
              />
              <div className="rn-dot-feature-info pt-4 text-center">
                <h5 className="fw-bold">Employee Transportation</h5>
                <p className="pt-2 lh-lg">
                  This service involves providing transportation for employees
                  to and from work or other events. Companies may offer shuttle
                  services or hire third-party transportation companies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="rn-dot-feature text-center p-4 border rounded shadow">
              <img
                src={process.env.PUBLIC_URL + "/images/selfDrive.png"}
                className="img-fluid w-25"
                alt="services"
              />
              <div className="rn-dot-feature-info pt-4 text-center">
                <h5 className="fw-bold">Self Drive</h5>
                <p className="pt-2 lh-lg">
                  This service allows customers to rent a car and drive it
                  themselves for a specific period. Customers can choose from a
                  range of vehicles depending on their needs and preferences.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="rn-dot-feature text-center p-4 border rounded shadow">
              <img
                src={process.env.PUBLIC_URL + "/images/Chaufferservices.png"}
                className="img-fluid w-25"
                alt="chaufferservices"
              />
              <div className="rn-dot-feature-info pt-4 text-center">
                <h5 className="fw-bold">Chauffeur Drive</h5>
                <p className="pt-2 lh-lg">
                  Our fleet latest models operated by professionally
                  trained drivers dedicated to ensuring ride is as smooth
                  comfortable possible. Their local knowledge proves invaluable
                  navigating cities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4 col-md-4">
            <div className="rn-dot-feature text-center p-4 border rounded shadow">
              <img
                src={process.env.PUBLIC_URL + "/images/RoadsideAssistance.png"}
                className="img-fluid w-25"
                alt="Roadside Assistance"
              />
              <div className="rn-dot-feature-info pt-4 text-center">
                <h5 className="fw-bold">Roadside Assistance</h5>
                <p className="pt-2 lh-lg">
                  This service provides assistance to drivers who experience a
                  breakdown or other issues with their vehicles while on the
                  road. Roadside assistance companies typically offer services
                  such as jump-starts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="rn-dot-feature text-center p-4 border rounded shadow">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/MeetandGreetServices.png"
                }
                className="img-fluid w-25"
                alt="Meet and Greet Services"
              />
              <div className="rn-dot-feature-info pt-4 text-center">
                <h5 className="fw-bold">Meet and Greet Services</h5>
                <p className="pt-2 lh-lg">
                  This service provides a personal greeter to meet customers at
                  the airport or other locations and assist with transportation.
                  Customers can hire a greeter to help them navigate the airport
                  or other busy locations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="rn-dot-feature text-center p-4 border rounded shadow">
              <img
                src={process.env.PUBLIC_URL + "/images/Lease.png"}
                className="img-fluid w-25"
                alt="Lease"
              />
              <div className="rn-dot-feature-info pt-4 text-center">
                <h5 className="fw-bold">Lease</h5>
                <p className="pt-2 lh-lg">
                  This service involves the long-term rental of a vehicle,
                  typically for several years. Customers pay a monthly fee for
                  the use of the car, and at the end of the lease term, they can
                  either return the car or buy it outright.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="car-looking">
        <div className="image-rent">
          <div className="container full-width">
            <div className="row align-items-center text-left">
              <div className="col-md-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/services_car.png"}
                  width="100%"
                  alt="services"
                />
              </div>
              <div className="col-md-7">
                <h2 className="pt-2 fw-bold">
                  Are you looking for renting your car?
                </h2>
                <div className="pt-4 mb-4">
                  <Link to="/contact-us">
                    <button
                      type="button"
                      className="btn btn-success btn-lg rounded fw-bold"
                    >
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ServicesBanner />
            <TypesofServices />
            <Servicesbanner /> */}
      <Footer />
    </>
  );
}
