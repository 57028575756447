import React from "react";
import "./discover-features.css";

const DiscoverFeatures = () => {
  return (
    <section className="mt-5">
      <div className="container pt-5 m-height">
        <div className="pt-5">
          <h1 className="pb-2 fw-bold text-center">
            Discover Our High-Quality Travel Features
          </h1>
          <p className="border-bottom pb-2 text-center"></p>
        </div>
        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-first order-md-first order-lg-first order-xl-first">
            <img
              src="/images/why-choose-us/corporate-travel.png"
              alt="Corporate Travel"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-last order-md-last order-lg-last order-xl-last">
            <div>
              <h1 className="fw-bold pb-3">Corporate Travel</h1>
              <p>
                Corporate travelers can go to any predetermined place at their
                convenience with the Cars in Africa's flexible services, which
                are designed to fulfill their demands. You may travel smoothly
                to meetings, site visits, and other work-related activities with
                our dependable transportation.
              </p>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-last order-md-last order-lg-last order-xl-last">
            <img
              src="/images/why-choose-us/sme-travel.png"
              alt="SME Travel"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-first order-md-first order-lg-first order-xl-first">
            <div>
              <h1 className="fw-bold pb-3">SME Travel</h1>
              <p>
                Enhance the travel experience for your African SME with
                specialized solutions tailored to meet the unique needs of small
                and medium-sized businesses. Take advantage of efficient
                services and seamless travel arrangements offered by Cars In
                Africa to optimize corporate travel and facilitate business
                expansion.
              </p>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-first order-md-first order-lg-first order-xl-first">
            <img
              src="/images/why-choose-us/air-travel-solutions.png"
              alt="Corporate Travel"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-last order-md-last order-lg-last order-xl-last">
            <div>
              <h1 className="fw-bold pb-3">Air Travel Solutions</h1>
              <p>
                You may put your worries about parking, traffic, and anxiety
                before your journey to rest when you choose our all-inclusive
                airport transportation service. Our services are tailored to
                your specific needs, ensuring a hassle-free and joyful journey
                to the airport.
              </p>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-last order-md-last order-lg-last order-xl-last">
            <img
              src="/images/why-choose-us/business-journey-management.png"
              alt="SME Travel"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-first order-md-first order-lg-first order-xl-first">
            <div>
              <h1 className="fw-bold pb-3">Business Journey Management</h1>
              <p>
                We at Cars In Africa are aware of the particular travel needs of
                Public Sector Units (PSUs) and government agencies. Our expert
                services provide smooth, dependable, and effective
                transportation options that follow rules and guidelines set out
                by the government.
              </p>
            </div>
          </div>
        </div>

        <div className="row pb-1 pt-1 align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-xs-first order-sm-first order-md-first order-lg-first order-xl-first">
            <img
              src="/images/why-choose-us/traveling-in-hospitality.png"
              alt="Corporate Travel"
              className="rounded-discover-features-img img-fluid"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last order-sm-last order-md-last order-lg-last order-xl-last">
            <div>
              <h1 className="fw-bold pb-3">Traveling in Hospitality</h1>
              <p>
                Our Hospitality Travel Solutions at Cars In Africa provide
                trustworthy cars for employees and easy transportation for
                visitors, enhancing their experience with on-time, comfortable
                trips, and clear invoicing. Improve client happiness and
                cultivate a sense of loyalty with our customized hotel-focused
                offerings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverFeatures;
