import React, { useState, useLayoutEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Helmet from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Careers.css";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
export default function Careers() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type === "application/pdf" && file.size <= 5 * 1024 * 1024) {
        setSelectedFile(file);
        setErrorMessage("");
      } else {
        setSelectedFile(null);
        setErrorMessage("Please upload a PDF file below 5 MB in size.");
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Combine form data with selected file
      const formDataWithFile = new FormData();
      formDataWithFile.append("attach_file", selectedFile);
      for (const key in formData) {
        formDataWithFile.append(key, formData[key]);
      }
      // Send a POST request to the API endpoint
      const response = await axios.post(
        "https://backend.carsinafrica.com/frontend/get-careers-data",
        formDataWithFile
      );

      if (response.data.status === "failed") {
        Swal.fire({
          title: "Error",
          text: "Unable To Process",
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Success",
          text: response.data.message,
          icon: "success",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          window.location.href = "/";
        });
      }
    } catch (error) {
      // Handle errors (e.g., display an error message)
      Swal.fire({
        title: "Error",
        text: "Unable To Process",
        icon: "error",
      });
    }
  };

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("careers");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/careers.jpg"}
          className="img-fluid"
          alt="careers"
        />
        <div className="banner-overlay"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption shadow-text">Career - Join Us</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width">
        <div className="row">
          <div className="col-md-6">
            <form className="rn-contact-form" onSubmit={handleSubmit}>
              <hr />
              <h3
                className="rn-section-title text-center"
                style={{ marginBottom: 20 }}
              >
                Applicant Information
              </h3>
              <hr />
              <div className="rn-contact-form">
                <div className="row mb-30">
                  <div className="col-6">
                    <div className="rn-icon-input">
                      <i className="far fa-user"></i>
                      <input
                        type="text"
                        name="firstname"
                        className="cia-career"
                        placeholder="First Name"
                        value={formData.firstname}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="rn-icon-input">
                      <i className="far fa-user"></i>
                      <input
                        type="text"
                        name="lastname"
                        className="cia-career"
                        placeholder="Last Name"
                        value={formData.lastname}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-30">
                  <div className="col-6">
                    <div className="rn-icon-input">
                      <i className="far fa-envelope"></i>
                      <input
                        type="text"
                        name="email"
                        className="cia-career"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="rn-icon-input">
                      <i className="fas fa-phone"></i>
                      <input
                        type="number"
                        name="phone_number"
                        className="cia-career"
                        placeholder="Phone Number"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="rn-icon-input rn-icon-top mb-30" id="uploadcv">
                  <label>Upload CV</label>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="uploadfile"
                    placeholder="Upload CV"
                    name="attach_file"
                  />
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
                <div className="rn-icon-input rn-icon-top">
                  {/* <i className="far fa-comment" style={{ top: "15%" }}></i> */}
                  <textarea
                    placeholder="Additional Information"
                    rows={3}
                    defaultValue={""}
                    style={{ padding: "7px 12px" }}
                  />
                </div>
                <div className="text-right mb-5 pt-2">
                  <input
                    className="btn btn-primary btn-lg btn-shadow"
                    type="submit"
                    style={{ background: "#10572d", border: "none" }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <hr />
            <img src="/images/career_01.jpg" alt="career-01" className="img-fluid" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
