import React, { useState } from "react";
import { Input } from "antd";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import "./Registration.css";

export default function RegisterComp() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    address: "",
    gender: "1",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneNumberChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  const handleGenderChange = (event) => {
    setFormData({
      ...formData,
      gender: event.target.value,
    });
  };

  const handleBlur = () => {
    if (formData.phoneNumber && formData.phoneNumber.length > 15) {
      setFormData({
        ...formData,
        phoneNumber: formData.phoneNumber.substring(0, 15),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Passwords do not match",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    const actualData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      emailid: formData.email,
      password: formData.password,
      address: formData.address,
      confirm_password: formData.confirmPassword,
      gender: formData.gender,
      mobno: formData.phoneNumber,
    };

    try {
      const res = await axios.post(
        `https://backend.carsinafrica.com/frontend/user-register`,
        actualData
      );

      setLoading(false);

      if (res.data.status === "failed") {
        Swal.fire({
          title: "Error",
          text: res.data.message,
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      } else {
        Swal.fire({
          title: "Success",
          text: res.data.message,
          icon: "success",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          window.location.href = `/`;
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Something went wrong. Please try again later.",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
              alt="Loading..."
            />
          </div>
        </div>
      ) : (
        <div className="registrar registrar-container">
          <div className="row pt-3 mb-3 registration-shadow">
            <div className="col-md-6">
              <div className="registrar">
                <div className="container">
                  <h3 className="title text-center fw-bold">Registration</h3>
                  <hr></hr>
                  <div className="content">
                    <form data-gtm-form-interact-id={1} onSubmit={handleSubmit}>
                      <div className="user-details">
                        <div className="input-box">
                          <span className="details">
                            First Name<span style={{ color: "red" }}> *</span>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter your First Name"
                            id="first_name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="input-box">
                          <span className="details">
                            Last Name<span style={{ color: "red" }}> *</span>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter your Last Name"
                            id="last_name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="input-box">
                          <span className="details">
                            Email<span style={{ color: "red" }}> *</span>
                          </span>
                          <input
                            type="email"
                            placeholder="Enter Your Email"
                            id="emailid"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="input-box">
                          <span className="details">
                            Phone Number<span style={{ color: "red" }}> *</span>
                          </span>
                          <PhoneInput
                            defaultCountry="IN"
                            country="IN"
                            type="text"
                            id="mobile-number"
                            name="phoneNumber"
                            className="phoneinputfield regsiter-phone-number"
                            placeholder="Mobile Number"
                            value={formData.phoneNumber}
                            onChange={handlePhoneNumberChange}
                            onBlur={handleBlur}
                            style={{ height: "54px" }}
                            required
                          />
                        </div>
                      </div>

                      <div className="input-box">
                        <span className="details">
                          Address<span style={{ color: "red" }}> *</span>
                        </span>
                        <textarea
                          type="text"
                          placeholder="Enter Your Address"
                          id="address"
                          name="address"
                          className="rounded custom-textarea"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="user-details">
                        <div className="input-box">
                          <span className="details">
                            Password<span style={{ color: "red" }}> *</span>
                          </span>
                          <Input.Password
                            type="password"
                            placeholder="Enter your password"
                            id="password"
                            className="custom-input-password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            required
                          />
                        </div>

                        <div className="input-box">
                          <span className="details">
                            Confirm Password
                            <span style={{ color: "red" }}> *</span>
                          </span>
                          <Input.Password
                            type="password"
                            placeholder="Confirm password"
                            id="confirm_password"
                            className="custom-input-password confirm-password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <span className="details">
                          Gender<span style={{ color: "red" }}> *</span>
                        </span>
                        <div className="row pt-2">
                          <div className="col-md-3">
                            <div className="form-check custom-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                id="male"
                                value="1"
                                checked={formData.gender === "1"}
                                onChange={handleGenderChange}
                              />
                              <label
                                className="form-check-label custom-gender male-gender"
                                htmlFor="male"
                              >
                                Male
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-check custom-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                id="female"
                                value="0"
                                checked={formData.gender === "0"}
                                onChange={handleGenderChange}
                              />
                              <label
                                className="form-check-label custom-gender female-gender"
                                htmlFor="female"
                              >
                                Female
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-check custom-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                id="other"
                                value="2"
                                checked={formData.gender === "2"}
                                onChange={handleGenderChange}
                              />
                              <label
                                className="form-check-label custom-gender female-gender"
                                htmlFor="other"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button">
                        <input
                          className="reg-button"
                          type="submit"
                          name="send"
                          id="send"
                          value="Submit"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="/images/register_image.png"
                className="img-fluid custom-height"
                alt="Registration"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
