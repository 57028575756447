import React from 'react';
import ChangePasswordComp from '../../components/ChangePassword/ChangePasswordComp';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function ChangePassword() {
  return (
    <>
      <Header />
      <ChangePasswordComp />
      <Footer />
    </>
  );
}