/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./lease-offers.css";
import {
  getLeaseUserToken,
  storeBooking,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import Swal from "sweetalert2";
import { useLeaseBookingMutation } from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";

export default function LeaseBookingComp(data) {
  const TotalMonthsFromYears = Number(data.data.years) * 12;
  const totalMonths = TotalMonthsFromYears + Number(data.data.months);
  const roundedTotalMonths = Math.ceil(totalMonths / 12) * 12;

  const currentDate = new Date().toISOString().slice(0, 10);
  const currentTime = new Date().toLocaleTimeString("en-US", { hour12: false });

  const navigate = useNavigate();
  const [leaseBooking] = useLeaseBookingMutation();

  const decimalsToRound = (num, decimalPlaces = 2) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  };

  const userLoggedData = getLeaseUserToken();
  const [isProtectionPlanOpen, setIsProtectionPlanOpen] = useState(true);
  const [isTariffsPackagesOpen, setIsTariffsPackagesOpen] = useState(true);
  const [isTermOpen, setIsTermOpen] = useState(true);
  const [openAvailableReviewBooking, setOpenAvailableReviewBooking] =
    useState(true);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [vehicleDetailsFlag, setVehicleDetailsFlag] = useState(false);
  const [vehicleDetailsLoadingFlag, setVehicleDetailsLoadingFlag] =
    useState(true);
  const [tax, setTax] = useState({});
  const [selectedProtectionPlan, setSelectedProtectionPlan] = useState({});
  const [selectedDriver, setSelectedDriver] = useState({});
  const [selectedFuel, setSelectedFuel] = useState({});
  const [selectedTariffsPackages, setSelectedTariffsPackages] = useState([]);
  const [basicPackages, setBasicPackages] = useState({});
  const [usersCartTotal, setUsersCartTotal] = useState({
    basicPackages: {},
    protectionPackages: { is_protection_plan: false },
    tariffsPackages: [],
    fuelAddon: { is_fuel: false },
    driverAddon: { is_driver: false },
    cartTotal: {},
    cartTotalWithFullPrice: {},
  });
  const [cartTotal, setCartTotal] = useState({
    basePrice: 0,
    totalBasePrice: 0,
    baseProtectionPlanPrice: 0,
    totalProtectionPlanPrice: 0,
    protectionPlanDurationMonths: roundedTotalMonths,
    baseTariffsPrice: 0,
    totalTariffsPrice: 0,
    baseDriverPrice: 0,
    totalDriverPrice: 0,
    baseFuelPrice: 0,
    totalFuelPrice: 0,
    baseOthersPrice: 0,
    totalOthersPrice: 0,
    totalTaxPercentage: 0,
    totalTax: 0,
    baseTax: 0,
    totalMonths: 0,
    priceBeforeTax: 0,
    priceAfterTax: 0,
    totalPriceBeforeTax: 0,
    totalPriceAfterTax: 0,
  });
  const [cartTotalWithFullPrice, setCartTotalWithFullPrice] = useState({
    basePrice: 0,
    totalBasePrice: 0,
    baseProtectionPlanPrice: 0,
    totalProtectionPlanPrice: 0,
    protectionPlanDurationMonths: roundedTotalMonths,
    baseTariffsPrice: 0,
    totalTariffsPrice: 0,
    baseDriverPrice: 0,
    totalDriverPrice: 0,
    baseFuelPrice: 0,
    totalFuelPrice: 0,
    baseOthersPrice: 0,
    totalOthersPrice: 0,
    totalTaxPercentage: 0,
    totalTax: 0,
    baseTax: 0,
    totalMonths: 0,
    priceBeforeTax: 0,
    priceAfterTax: 0,
    totalPriceBeforeTax: 0,
    totalPriceAfterTax: 0,
  });

  const searchPayload = {
    term: data.data.term,
    vehicleTypeId: data.data.vehicleTypeId,
    pAddress: data.data.pAddress,
    pCity: data.data.pCity,
    pState: data.data.pState,
    pCountry: data.data.pCountry,
    pCountryIso: data.data.pCountryIso,
    pLatitude: data.data.pLatitude,
    pLongitude: data.data.pLongitude,
    userId: data.data.userId,
    months: data.data.months,
    years: data.data.years,
    vehicleAgeType: data.data.vehicleAgeType,
  };

  const duration = {
    year: data.data.years,
    months: data.data.months,
  };

  const [bookingData, setBookingData] = useState({
    searchPayload: searchPayload,
    lease_id: 0,
    duration: duration,
    driver: {
      is_driver: true,
      driver_amount: 0,
    },
    insurance: {},
    products: [],
    fuel: {
      is_fuel: true,
      fuel_amount: 0,
    },
    tax: {
      tax_id: 0,
      tax_amount: 0,
      tax_per: 0,
    },
    booking_data: {
      userId: 0,
      base_fare: 0,
      total_amount: 0,
      created_date: 0,
      created_time: 0,
    },
    base_fare: 0,
    total_amount: 0,
    cartTotal: {},
  });

  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [tariffsExpandedDescriptions, setTariffsExpandedDescriptions] =
    useState({});

  useEffect(() => {
    axios
      .post(
        "https://backend.carsinafrica.com/frontend/getLeaseListing",
        data.data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          const data = response.data.data[0];
          const baseRate = parseFloat(data.base_rate);
          const taxPercentage = parseFloat(data.tax.tax_per);
          const totalTax = decimalsToRound(
            (baseRate * totalMonths * taxPercentage) / 100
          );
          const totalBaseTax = decimalsToRound(
            (baseRate * taxPercentage) / 100
          );
          const totalTaxFullPrice = decimalsToRound(
            (baseRate * totalMonths * taxPercentage) / 100
          );
          const totalBaseTaxFullPice = decimalsToRound(
            (baseRate * taxPercentage) / 100
          );
          setVehicleDetails(response.data.data[0]);
          setBasicPackages({
            base_rate: decimalsToRound(
              parseFloat(response.data.data[0].base_rate)
            ),
            currency: response.data.data[0].currency,
            tax: decimalsToRound(parseFloat(response.data.data[0].tax.tax_per)),
            tax_id: response.data.data[0].tax.tax_id,
          });
          setCartTotal((prevCartTotal) => ({
            ...prevCartTotal,
            basePrice: decimalsToRound(baseRate),
            totalBasePrice: decimalsToRound(baseRate * totalMonths),
            totalTaxPercentage: decimalsToRound(taxPercentage),
            totalTax: totalTax,
            baseTax: totalBaseTax,
            priceBeforeTax: decimalsToRound(baseRate),
            priceAfterTax: decimalsToRound(baseRate + totalBaseTax),
            totalPriceBeforeTax: decimalsToRound(baseRate * totalMonths),
            totalPriceAfterTax: decimalsToRound(
              baseRate * totalMonths + totalTax
            ),
            totalMonths: totalMonths,
          }));
          setCartTotalWithFullPrice((prevCartTotal) => ({
            ...prevCartTotal,
            basePrice: baseRate,
            totalBasePrice: baseRate * totalMonths,
            totalTaxPercentage: taxPercentage,
            totalTax: totalTaxFullPrice,
            baseTax: totalBaseTaxFullPice,
            priceBeforeTax: baseRate,
            priceAfterTax: baseRate + totalBaseTax,
            totalPriceBeforeTax: baseRate * totalMonths,
            totalPriceAfterTax: baseRate * totalMonths + totalTax,
            totalMonths: totalMonths,
          }));
          setBookingData((bookingData) => ({
            ...bookingData,
            lease_id: response.data.data[0].id,
          }));
          setTax(response.data.data[0].tax);
          setVehicleDetailsFlag(true);
          setVehicleDetailsLoadingFlag(false);
        } else {
          setVehicleDetailsLoadingFlag(false);
          setVehicleDetailsFlag(false);
        }
      })
      .catch((err) => {
        setVehicleDetailsLoadingFlag(false);
        setVehicleDetailsFlag(false);
      });
  }, []);

  useEffect(() => {
    setUsersCartTotal({
      basicPackages: basicPackages,
      protectionPackages: selectedProtectionPlan,
      tariffsPackages: selectedTariffsPackages,
      cartTotal: cartTotal,
      fuelAddon: selectedFuel,
      driverAddon: selectedDriver,
      cartTotalWithFullPrice: cartTotalWithFullPrice,
    });
    setBookingData((bookingData) => ({
      ...bookingData,
      insurance: selectedProtectionPlan,
      products: selectedTariffsPackages,
      cartTotal: cartTotal,
      cartTotalWithFullPrice: cartTotalWithFullPrice,
      base_fare: cartTotal.basePrice,
      total_amount: cartTotal.totalPriceAfterTax,
      total_tax: cartTotal.totalTax,
      tax: tax,
      driver: selectedDriver,
      fuel: selectedFuel,
      booking_data: {
        userId: userLoggedData.lease_user_id,
        base_fare: cartTotal.basePrice,
        total_amount: cartTotal.totalPriceAfterTax,
        created_date: currentDate,
        created_time: currentTime,
      },
    }));
  }, [
    selectedProtectionPlan,
    selectedTariffsPackages,
    basicPackages,
    cartTotal,
  ]);

  const toggleReadMore = (index) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getTruncatedDescription = (description, index) => {
    const isExpanded = expandedDescriptions[index];
    const truncatedDescription =
      description.split(" ").slice(0, 10).join(" ") + "...";
    return (
      <>
        {isExpanded ? description : truncatedDescription}
        <br />
        <span
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => toggleReadMore(index)}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </span>
      </>
    );
  };

  const toggleTariffsReadMore = (index) => {
    setTariffsExpandedDescriptions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getTariffsTruncatedDescription = (description, index) => {
    const isExpanded = tariffsExpandedDescriptions[index];
    const truncatedDescription =
      description.split(" ").slice(0, 10).join(" ") + "...";
    return (
      <>
        {isExpanded ? description : truncatedDescription}
        <br />
        <span
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => toggleTariffsReadMore(index)}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </span>
      </>
    );
  };

  const protectionPlanToggleAccordion = () => {
    setIsProtectionPlanOpen(!isProtectionPlanOpen);
  };

  const tariffsPackagesToggleAccordion = () => {
    setIsTariffsPackagesOpen(!isTariffsPackagesOpen);
  };

  const TermsToggleAccordion = () => {
    setIsTermOpen(!isTermOpen);
  };

  const toggleAccordionAvailableReviewBooking = () => {
    setOpenAvailableReviewBooking(!openAvailableReviewBooking);
  };

  const handleProtectionPlanSelection = (plan) => {
    const isSelected = plan.id === selectedProtectionPlan.id;
    if (isSelected) {
      const monthly_amount = 0;
      const totalAmount = decimalsToRound(monthly_amount * roundedTotalMonths);
      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice +
          totalAmount) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice +
          monthly_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const totalOthers = decimalsToRound(
        totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice
      );

      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice +
          totalTax
      );

      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice
      );
      const priceAfterTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice +
          totalBaseTax
      );

      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseProtectionPlanPrice: monthly_amount,
        totalProtectionPlanPrice: totalAmount,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        totalOthersPrice: totalOthers,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
      }));

      const monthly_amountWithFullPrice = 0;
      const totalAmountWithFullPrice = monthly_amount * roundedTotalMonths;
      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalDriverPrice +
          cartTotalWithFullPrice.totalFuelPrice +
          totalAmountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          cartTotalWithFullPrice.baseTariffsPrice +
          cartTotalWithFullPrice.baseDriverPrice +
          cartTotalWithFullPrice.baseFuelPrice +
          monthly_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const totalPriceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalFuelPrice;

      const totalOthersWithFullPrice =
        totalAmount +
        cartTotal.totalTariffsPrice +
        cartTotal.totalDriverPrice +
        cartTotal.totalFuelPrice;

      const totalPriceAfterTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalFuelPrice +
        totalTaxWithFullPrice;

      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const priceAfterTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        cartTotalWithFullPrice.baseFuelPrice +
        totalBaseTaxWithFullPrice;

      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseProtectionPlanPrice: monthly_amountWithFullPrice,
        totalProtectionPlanPrice: totalAmountWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        totalOthersPrice: totalOthersWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
      }));
      setSelectedProtectionPlan({ is_protection_plan: false });
    } else {
      const monthly_amount = decimalsToRound(parseFloat(plan.monthly_amount));
      const total_amount = decimalsToRound(monthly_amount * roundedTotalMonths);
      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice +
          total_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice +
          monthly_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          total_amount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        totalPriceBeforeTax + totalTax
      );
      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice
      );
      const priceAfterTax = decimalsToRound(priceBeforeTax + totalBaseTax);
      const othersBaseTotal = decimalsToRound(
        monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice
      );
      const othersTotal = decimalsToRound(
        total_amount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice
      );
      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseProtectionPlanPrice: monthly_amount,
        totalProtectionPlanPrice: total_amount,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
        baseOthersPrice: othersBaseTotal,
        totalOthersPrice: othersTotal,
      }));

      const monthly_amountWithFullPrice = parseFloat(plan.monthly_amount);
      const total_amountWithFullPrice =
        monthly_amountWithFullPrice * roundedTotalMonths;
      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalDriverPrice +
          cartTotalWithFullPrice.totalFuelPrice +
          total_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          cartTotalWithFullPrice.baseTariffsPrice +
          cartTotalWithFullPrice.baseDriverPrice +
          cartTotalWithFullPrice.baseFuelPrice +
          monthly_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalPriceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        total_amountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalFuelPrice;
      const totalPriceAfterTaxWithFullPrice =
        totalPriceBeforeTaxWithFullPrice + totalTaxWithFullPrice;
      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const priceAfterTaxWithFullPrice =
        priceBeforeTaxWithFullPrice + totalBaseTaxWithFullPrice;
      const othersBaseTotalWithFullPrice =
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const othersTotalWithFullPrice =
        total_amountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalFuelPrice;

      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseProtectionPlanPrice: monthly_amountWithFullPrice,
        totalProtectionPlanPrice: total_amountWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
        baseOthersPrice: othersBaseTotalWithFullPrice,
        totalOthersPrice: othersTotalWithFullPrice,
      }));

      setSelectedProtectionPlan({ ...plan, is_protection_plan: true });
    }
  };

  const handleTariffsPackagesSelection = (plan) => {
    setSelectedTariffsPackages((prevSelectedTariffsPackages) => {
      const isSelected = prevSelectedTariffsPackages.some(
        (selectedPackage) => selectedPackage.id === plan.id
      );
      let updatedPackages;
      if (isSelected) {
        updatedPackages = prevSelectedTariffsPackages.filter(
          (selectedPackage) => selectedPackage.id !== plan.id
        );
      } else {
        updatedPackages = [...prevSelectedTariffsPackages, plan];
      }

      const baseTariffsPrice = updatedPackages.reduce(
        (total, pkg) => total + parseFloat(pkg.price),
        0
      );
      const baseAllTariffsPrice = decimalsToRound(baseTariffsPrice);
      const totalTariffsPrice = decimalsToRound(baseTariffsPrice * totalMonths);

      const baseOthersPrice = decimalsToRound(
        baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice
      );
      const totalOthersPrice = decimalsToRound(
        totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice
      );

      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          baseAllTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice +
          cartTotal.baseFuelPrice
      );
      const priceAfterTax = decimalsToRound(priceBeforeTax + totalBaseTax);

      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalFuelPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        totalPriceBeforeTax + totalTax
      );

      const baseAllTariffsPriceWithFullPrice = baseTariffsPrice;
      const totalTariffsPriceWithFullPrice = baseTariffsPrice * totalMonths;

      const baseOthersPriceWithFullPrice =
        baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const totalOthersPriceWithFullPrice =
        totalTariffsPriceWithFullPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalFuelPrice;

      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          totalTariffsPriceWithFullPrice +
          cartTotalWithFullPrice.totalProtectionPlanPrice +
          cartTotalWithFullPrice.totalDriverPrice +
          cartTotalWithFullPrice.totalFuelPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          baseAllTariffsPriceWithFullPrice +
          cartTotalWithFullPrice.baseProtectionPlanPrice +
          cartTotalWithFullPrice.baseDriverPrice +
          cartTotalWithFullPrice.baseFuelPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const priceAfterTaxWithFullPrice =
        priceBeforeTaxWithFullPrice + totalBaseTaxWithFullPrice;

      const totalPriceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        totalTariffsPriceWithFullPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalFuelPrice;
      const totalPriceAfterTaxWithFullPrice =
        totalPriceBeforeTaxWithFullPrice + totalTaxWithFullPrice;

      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseTariffsPrice: baseTariffsPrice,
        totalTariffsPrice: totalTariffsPrice,
        baseOthersPrice: baseOthersPrice,
        totalOthersPrice: totalOthersPrice,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
      }));

      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseTariffsPrice: baseTariffsPrice,
        totalTariffsPrice: totalTariffsPriceWithFullPrice,
        baseOthersPrice: baseOthersPriceWithFullPrice,
        totalOthersPrice: totalOthersPriceWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
      }));

      return updatedPackages;
    });
  };

  const handleDriverSelection = (plan) => {
    const isSelected = plan.lease_id === selectedDriver.lease_id;
    if (isSelected) {
      const monthly_amount = 0;
      const totalAmount = decimalsToRound(monthly_amount * totalMonths);
      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice +
          totalAmount) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseFuelPrice +
          monthly_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const totalOthers = decimalsToRound(
        totalAmount +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice +
          cartTotal.totalTariffsPrice
      );

      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice +
          totalTax
      );

      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseFuelPrice
      );
      const priceAfterTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseFuelPrice +
          totalBaseTax
      );
      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseDriverPrice: monthly_amount,
        totalDriverPrice: totalAmount,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        totalOthersPrice: totalOthers,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
      }));
      const monthly_amountWithFullPrice = 0;
      const totalAmountWithFullPrice = monthly_amount * totalMonths;
      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalProtectionPlanPrice +
          cartTotalWithFullPrice.totalFuelPrice +
          totalAmountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          cartTotalWithFullPrice.baseTariffsPrice +
          cartTotalWithFullPrice.baseProtectionPlanPrice +
          cartTotalWithFullPrice.baseFuelPrice +
          monthly_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const totalOthersWithFullPrice =
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalFuelPrice +
        cartTotalWithFullPrice.totalTariffsPrice;

      const totalPriceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalFuelPrice;
      const totalPriceAfterTaxWithFullPrice =
        cartTotal.totalBasePrice +
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalFuelPrice +
        totalTaxWithFullPrice;

      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const priceAfterTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseFuelPrice +
        totalBaseTaxWithFullPrice;
      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseDriverPrice: monthly_amountWithFullPrice,
        totalDriverPrice: totalAmountWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        totalOthersPrice: totalOthersWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
      }));
      setSelectedDriver({ is_driver: false });
    } else {
      const monthly_amount = decimalsToRound(parseFloat(plan.driver_salary));
      const total_amount = decimalsToRound(monthly_amount * totalMonths);
      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice +
          total_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          cartTotal.baseTariffsPrice +
          cartTotal.baseFuelPrice +
          monthly_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          total_amount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        totalPriceBeforeTax + totalTax
      );
      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseFuelPrice
      );
      const priceAfterTax = decimalsToRound(priceBeforeTax + totalBaseTax);
      const othersBaseTotal = decimalsToRound(
        monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseFuelPrice
      );
      const othersTotal = decimalsToRound(
        total_amount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalFuelPrice
      );
      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseDriverPrice: monthly_amount,
        totalDriverPrice: total_amount,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
        baseOthersPrice: othersBaseTotal,
        totalOthersPrice: othersTotal,
      }));

      const monthly_amountWithFullPrice = parseFloat(plan.driver_salary);
      const total_amountWithFullPrice =
        monthly_amountWithFullPrice * totalMonths;
      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalProtectionPlanPrice +
          cartTotalWithFullPrice.totalFuelPrice +
          total_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          cartTotalWithFullPrice.baseTariffsPrice +
          cartTotalWithFullPrice.baseFuelPrice +
          monthly_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalPriceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        total_amountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalFuelPrice;
      const totalPriceAfterTaxWithFullPrice =
        totalPriceBeforeTaxWithFullPrice + totalTaxWithFullPrice;
      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const priceAfterTaxWithFullPrice =
        priceBeforeTaxWithFullPrice + totalBaseTaxWithFullPrice;
      const othersBaseTotalWithFullPrice =
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseFuelPrice;
      const othersTotalWithFullPrice =
        total_amountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalFuelPrice;
      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseDriverPrice: monthly_amountWithFullPrice,
        totalDriverPrice: total_amountWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
        baseOthersPrice: othersBaseTotalWithFullPrice,
        totalOthersPrice: othersTotalWithFullPrice,
      }));
      setSelectedDriver({
        ...plan,
        is_driver: true,
      });
    }
  };

  const handleFuelSelection = (plan) => {
    const isSelected = plan.lease_id === selectedFuel.lease_id;
    if (isSelected) {
      const monthly_amount = 0;
      const totalAmount = decimalsToRound(monthly_amount * totalMonths);
      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          totalAmount) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice +
          monthly_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );

      const totalOthers = decimalsToRound(
        totalAmount +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          cartTotal.totalTariffsPrice
      );

      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        cartTotal.totalBasePrice +
          totalAmount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          totalTax
      );

      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice
      );
      const priceAfterTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice +
          totalBaseTax
      );

      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseFuelPrice: monthly_amount,
        totalFuelPrice: totalAmount,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        totalOthersPrice: totalOthers,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
      }));

      const monthly_amountWithFullPrice = 0;
      const totalAmountWithFullPrice = decimalsToRound(
        monthly_amountWithFullPrice * totalMonths
      );
      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalProtectionPlanPrice +
          cartTotalWithFullPrice.totalDriverPrice +
          totalAmountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          cartTotalWithFullPrice.baseTariffsPrice +
          cartTotalWithFullPrice.baseProtectionPlanPrice +
          cartTotalWithFullPrice.baseDriverPrice +
          monthly_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;

      const totalOthersWithFullPrice =
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        cartTotalWithFullPrice.totalTariffsPrice;

      const totalPriceBeforeTaxWithFullPrice =
        cartTotal.totalBasePrice +
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalDriverPrice;
      const totalPriceAfterTaxWithFullPrice =
        cartTotalWithFullPrice.totalBasePrice +
        totalAmountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalDriverPrice +
        totalTaxWithFullPrice;

      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseDriverPrice;
      const priceAfterTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseDriverPrice +
        totalBaseTaxWithFullPrice;

      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseFuelPrice: monthly_amountWithFullPrice,
        totalFuelPrice: totalAmountWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        totalOthersPrice: totalOthersWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
      }));

      setSelectedFuel({ is_fuel: false });
    } else {
      const monthly_amount = decimalsToRound(parseFloat(plan.fuel));
      const total_amount = decimalsToRound(monthly_amount * totalMonths);
      const totalTax = decimalsToRound(
        ((cartTotal.totalBasePrice +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice +
          total_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalBaseTax = decimalsToRound(
        ((cartTotal.basePrice +
          cartTotal.baseTariffsPrice +
          cartTotal.baseDriverPrice +
          monthly_amount) *
          cartTotal.totalTaxPercentage) /
          100
      );
      const totalPriceBeforeTax = decimalsToRound(
        cartTotal.totalBasePrice +
          total_amount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice
      );
      const totalPriceAfterTax = decimalsToRound(
        totalPriceBeforeTax + totalTax
      );
      const priceBeforeTax = decimalsToRound(
        cartTotal.basePrice +
          monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice
      );
      const priceAfterTax = decimalsToRound(priceBeforeTax + totalBaseTax);
      const othersBaseTotal = decimalsToRound(
        monthly_amount +
          cartTotal.baseTariffsPrice +
          cartTotal.baseProtectionPlanPrice +
          cartTotal.baseDriverPrice
      );
      const othersTotal = decimalsToRound(
        total_amount +
          cartTotal.totalTariffsPrice +
          cartTotal.totalProtectionPlanPrice +
          cartTotal.totalDriverPrice
      );
      setCartTotal((prevCartTotal) => ({
        ...prevCartTotal,
        baseFuelPrice: monthly_amount,
        totalFuelPrice: total_amount,
        totalTax: totalTax,
        baseTax: totalBaseTax,
        priceBeforeTax: priceBeforeTax,
        priceAfterTax: priceAfterTax,
        totalPriceBeforeTax: totalPriceBeforeTax,
        totalPriceAfterTax: totalPriceAfterTax,
        baseOthersPrice: othersBaseTotal,
        totalOthersPrice: othersTotal,
      }));
      const monthly_amountWithFullPrice = parseFloat(plan.fuel);
      const total_amountWithFullPrice = monthly_amount * totalMonths;
      const totalTaxWithFullPrice =
        ((cartTotalWithFullPrice.totalBasePrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalProtectionPlanPrice +
          cartTotalWithFullPrice.totalDriverPrice +
          total_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalBaseTaxWithFullPrice =
        ((cartTotalWithFullPrice.basePrice +
          cartTotalWithFullPrice.baseTariffsPrice +
          cartTotalWithFullPrice.baseDriverPrice +
          monthly_amountWithFullPrice) *
          cartTotalWithFullPrice.totalTaxPercentage) /
        100;
      const totalPriceBeforeTaxWithFullPrice = decimalsToRound(
        cartTotalWithFullPrice.totalBasePrice +
          total_amountWithFullPrice +
          cartTotalWithFullPrice.totalTariffsPrice +
          cartTotalWithFullPrice.totalProtectionPlanPrice +
          cartTotalWithFullPrice.totalDriverPrice
      );
      const totalPriceAfterTaxWithFullPrice =
        totalPriceBeforeTaxWithFullPrice + totalTaxWithFullPrice;
      const priceBeforeTaxWithFullPrice =
        cartTotalWithFullPrice.basePrice +
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseDriverPrice;
      const priceAfterTaxWithFullPrice =
        priceBeforeTaxWithFullPrice + totalBaseTaxWithFullPrice;
      const othersBaseTotalWithFullPrice =
        monthly_amountWithFullPrice +
        cartTotalWithFullPrice.baseTariffsPrice +
        cartTotalWithFullPrice.baseProtectionPlanPrice +
        cartTotalWithFullPrice.baseDriverPrice;
      const othersTotalWithFullPrice =
        total_amountWithFullPrice +
        cartTotalWithFullPrice.totalTariffsPrice +
        cartTotalWithFullPrice.totalProtectionPlanPrice +
        cartTotalWithFullPrice.totalDriverPrice;
      setCartTotalWithFullPrice((prevCartTotal) => ({
        ...prevCartTotal,
        baseFuelPrice: monthly_amountWithFullPrice,
        totalFuelPrice: total_amountWithFullPrice,
        totalTax: totalTaxWithFullPrice,
        baseTax: totalBaseTaxWithFullPrice,
        priceBeforeTax: priceBeforeTaxWithFullPrice,
        priceAfterTax: priceAfterTaxWithFullPrice,
        totalPriceBeforeTax: totalPriceBeforeTaxWithFullPrice,
        totalPriceAfterTax: totalPriceAfterTaxWithFullPrice,
        baseOthersPrice: othersBaseTotalWithFullPrice,
        totalOthersPrice: othersTotalWithFullPrice,
      }));
      setSelectedFuel({ ...plan, is_fuel: true });
    }
  };

  const handleLeaseBookingSubmit = async (e) => {
    e.preventDefault();
    setVehicleDetailsLoadingFlag(true);

    try {
      const res = await leaseBooking(bookingData);
      setVehicleDetailsLoadingFlag(false);

      if (res.data.success) {
        Swal.fire({
          title: "Success",
          text: res.data.message,
          icon: "success",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          storeBooking(res.data.success, res.data.message);
          navigate("/lease");
        });
      } else {
        Swal.fire({
          title: "Error",
          text: res.data.message,
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      setVehicleDetailsLoadingFlag(false);
      Swal.fire({
        title: "Error",
        text: "An error occurred while processing your request. Please try again.",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      }).then(() => {
        window.location.reload();
      });
    }
  };

  // useEffect(() => {
  //   document.querySelectorAll("#imageCarousel img").forEach((img) => {
  //     img.addEventListener("click", (event) => {
  //       document.getElementById("zoomedImage").src = event.target.src;
  //     });
  //   });
  // }, []);

  useEffect(() => {
    const images = document.querySelectorAll("#imageCarousel img");
  
    const handleImageClick = (event) => {
      document.getElementById("zoomedImage").src = event.target.src;
    };
  
    images.forEach((img) => {
      img.addEventListener("click", handleImageClick);
    });
  
    // Clean up the event listeners when the component unmounts
    return () => {
      images.forEach((img) => {
        img.removeEventListener("click", handleImageClick);
      });
    };
  }, [vehicleDetails.lease_vehicle_image]);
  

  if (vehicleDetailsLoadingFlag === true) {
    return (
      <div className="container pt-5 mt-4 mb-5">
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid rounded"
            />
          </div>
        </div>
      </div>
    );
  } else if (
    vehicleDetailsFlag === true &&
    vehicleDetailsLoadingFlag === false
  ) {
    return (
      <>
        <div className="col-lg-12 col-md-12 col-sm-12 p-5 pt-5">
          <div className="bg row d-flex justify-content-center pb-5 mt-5">
            {/* START -> Vehicle Location & Include Details  */}
            <div className="row">
              {/* START -> Car Location */}
              <div className="col-md-9">
                <div
                  className="bg row mt-4 p-3 d-flex justify-content-center"
                  id="payment-section"
                >
                  <div className="pt-2 pb-2">
                    <h3 className="fw-bold ms-2">Location</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="pl-2">
                        <p className="fw-bold">Pick up Address</p>
                        <p className="fw-bold">
                          <i className="bi bi-geo-alt-fill"></i>{" "}
                          {vehicleDetails.pickup_location}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END -> Car Location */}

              {/* START -> Include Details */}
              <div className="custom-col col-md-3">
                <div
                  className="bg-white mt-4 p-3 d-flex flex-column card-include-information"
                  id="payment-section"
                >
                  <div className="pt-1 mb-3">
                    <h5 className="fw-bold">
                      Include Details
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/ic_outline-rate-review.svg"
                        }
                        style={{ float: "right" }}
                      />
                    </h5>
                  </div>
                  <div className="pl-2">
                    <p>
                      Alloted Hours : {vehicleDetails.alloted_hrs} Hours / Month
                    </p>
                    <p>
                      Alloted Km's : {vehicleDetails.alloted_kms} Km / Month
                    </p>
                  </div>
                </div>
              </div>
              {/* END -> Include Details */}
            </div>
            {/* END -> Vehicle Location & Include Details  */}

            {/* START -> Vehicle Details & Cart Total  */}
            <div className="row">
              {/* START -> Vehicle Details */}
              <div className="col-md-9">
                <div
                  className="bg row mt-4 p-3 d-flex justify-content-center"
                  id="payment-section"
                >
                  <div className="row">
                    <div className="col-md-9">
                      <div className="pt-2 pb-2">
                        <h3 className="fw-bold">Vehicle Details</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {vehicleDetails.lease_vehicle_image.length === 0 ? (
                          <img
                            src={`https://backend.carsinafrica.com/uploads/${vehicleDetails.vehicle_type.icon}`}
                            className="img-fluid rounded rounded-start"
                          />
                        ) : (
                          <>
                            <div class="card">
                              <div
                                id="imageCarousel"
                                class="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div class="carousel-inner">
                                  {vehicleDetails.lease_vehicle_image.map(
                                    (vehicle_image, index) => (
                                      <div
                                        className={`carousel-item ${
                                          index === 0 ? "active" : ""
                                        }`}
                                        key={index}
                                      >
                                        <img
                                          src={`${vehicleDetails.leaseVehicleImagesUrl}${vehicle_image.images}`}
                                          className="d-block w-100"
                                          alt={`Image ${index + 1}`}
                                          data-bs-toggle="modal"
                                          data-bs-target="#zoomModal"
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                                <button
                                  class="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#imageCarousel"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    class="carousel-control-prev-icon next-left-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="visually-hidden">Previous</span>
                                </button>
                                <button
                                  class="carousel-control-next"
                                  type="button"
                                  data-bs-target="#imageCarousel"
                                  data-bs-slide="next"
                                >
                                  <span
                                    class="carousel-control-next-icon next-right-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="visually-hidden">Next</span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className="modal fade"
                        id="zoomModal"
                        tabIndex="-1"
                        aria-labelledby="zoomModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="zoomModalLabel">
                                {vehicleDetails.vehicle_type.displayname}
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <img
                                src=""
                                className="d-block w-100"
                                id="zoomedImage"
                                alt="Zoomed"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="pl-2">
                          <div className="car-name">
                            <p className="fw-bold fs-4">
                              {vehicleDetails.vehicle_type.displayname}
                              <small> ( Or Similar ) </small>
                            </p>
                          </div>
                          <p className="fw-bold fs-5.5">
                            Car Type:{"  "}
                            <span>
                              {vehicleDetails.vehicle_type.vehicletype}
                            </span>
                          </p>
                          <p className="fw-bold fs-5">Features</p>
                          <div className="fw-bold col-lg-12">
                            {/* SEATS */}
                            <div className="d-inline-flex align-items-center m-1">
                              <svg
                                alt="Petrol"
                                className="mr-1 petrol-icon"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <defs>
                                  <path
                                    d="M17.918 7.718a2.765 2.765 0 011.828 2.596v11.062h-8.832V10.314c0-1.195.764-2.212 1.829-2.596a3.884 3.884 0 005.175 0zm-11.244.257a3.584 3.584 0 003.558.59 3.838 3.838 0 00-.423 1.75v9.805H5v-9.607c0-1.136.69-2.114 1.674-2.538zm2.29-5.271c1.24 0 2.269.91 2.46 2.096 0 .16.013.317.032.472a2.497 2.497 0 01-2.492 2.423 2.499 2.499 0 01-2.496-2.496 2.498 2.498 0 012.496-2.495zM15.33 2c1.544 0 2.8 1.256 2.8 2.8 0 1.545-1.256 2.8-2.8 2.8a2.804 2.804 0 01-2.8-2.8c0-1.544 1.256-2.8 2.8-2.8z"
                                    id="pax_svg__a"
                                  ></path>
                                </defs>
                                <use
                                  xlinkHref="#pax_svg__a"
                                  fillRule="evenodd"
                                ></use>
                              </svg>
                              {vehicleDetails.vehicle_type.seats}
                            </div>
                            {/* DOORS */}
                            <div className="d-inline-flex align-items-center m-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                className="ecw-icon__svg doors-icon"
                              >
                                <defs>
                                  <path
                                    d="M18.897 11.009H5.773c-.5-.198-.567-.496-.36-.86l4.719-4.656c.106-.09.19-.214.375-.187h8.39v5.703zm-.66 3.051h-1.81a.38.38 0 110-.762h1.81a.381.381 0 010 .762zm-7.67-10.056a1.456 1.456 0 00-.11-.004c-.64 0-1.033.373-1.169.502l-.036.03L4.37 9.349l-.09.156c-.23.407-.293.773-.278 1.076L4 10.578v9.3h16.198V4.005h-9.63z"
                                    id="doors_svg__a"
                                  ></path>
                                </defs>
                                <use
                                  xlinkHref="#doors_svg__a"
                                  fillRule="evenodd"
                                ></use>
                              </svg>
                              {vehicleDetails.vehicle_type.doors}
                            </div>
                            {/* BAG QUANTITY */}
                            <div className="d-inline-flex align-items-center m-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                className="ecw-icon__svg bag-icon"
                              >
                                <defs>
                                  <path
                                    d="M14 12h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1zm-3-5h2V4h-2v3zm4 0V4c.6 0 1-.4 1-1s-.4-1-1-1H9c-.6 0-1 .4-1 1s.4 1 1 1v3c-1.7 0-3 1.3-3 3v8c0 1.3.8 2.4 2 2.8v.8c0 .2.2.4.4.4h1.2c.2 0 .4-.2.4-.4V21h4v.6c0 .2.2.4.4.4h1.2c.2 0 .4-.2.4-.4v-.8c1.2-.4 2-1.5 2-2.8v-8c0-1.7-1.3-3-3-3z"
                                    id="luggage_svg__a"
                                  ></path>
                                </defs>
                                <use
                                  xlinkHref="#luggage_svg__a"
                                  fillRule="evenodd"
                                ></use>
                              </svg>
                              {vehicleDetails.vehicle_type.luggage}
                            </div>

                            {/* MANUAL/AUTOMATIC */}
                            <div className="d-inline-flex align-items-center m-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                className="ecw-icon__svg manual-automatic"
                              >
                                <defs>
                                  <path
                                    d="M17.875 4a2.125 2.125 0 00-1.063 3.963v3.474h-4.249V7.963a2.133 2.133 0 001.062-1.838 2.125 2.125 0 10-3.188 1.838v3.474h-4.25V7.963A2.122 2.122 0 005.125 4a2.125 2.125 0 00-1.063 3.963v9.073A2.122 2.122 0 005.125 21a2.125 2.125 0 001.063-3.964v-3.474h4.25v3.475A2.122 2.122 0 0011.5 21a2.125 2.125 0 001.063-3.963v-3.474h5.312c.587 0 1.063-.476 1.063-1.063V7.963A2.122 2.122 0 0017.875 4"
                                    id="transmission_svg__a"
                                  ></path>
                                </defs>
                                <use
                                  xlinkHref="#transmission_svg__a"
                                  fillRule="evenodd"
                                ></use>
                              </svg>
                              {vehicleDetails.vehicle_type.vehicle_engine_type}
                            </div>

                            {/* AC/NON-AC */}
                            <div className="d-inline-flex align-items-center m-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                className="ecw-icon__svg non-ac"
                              >
                                <defs>
                                  <path
                                    d="M12.001 3a.774.774 0 00-.782.766v1.431L9.85 3.734a.796.796 0 00-1.107-.047.756.756 0 00-.047 1.083l2.523 2.696v3.207L8.384 9.07 7.26 5.58a.789.789 0 00-.983-.5.765.765 0 00-.51.962l.61 1.894-1.267-.717a.79.79 0 00-1.07.281.756.756 0 00.286 1.047l1.267.716-1.98.43a.766.766 0 00-.595.913.78.78 0 00.933.583l3.65-.791L10.436 12 7.6 13.603l-3.648-.792a.781.781 0 00-.933.582.767.767 0 00.594.915l1.98.43-1.266.715a.757.757 0 00-.286 1.047.786.786 0 001.069.28l1.267-.716-.61 1.895c-.13.403.1.834.51.962a.81.81 0 00.236.035.782.782 0 00.747-.535l1.124-3.491 2.835-1.603v3.204l-2.525 2.7a.756.756 0 00.048 1.082.795.795 0 001.106-.047l1.37-1.465v1.433c0 .423.35.766.783.766a.774.774 0 00.783-.766V18.8l1.37 1.466a.792.792 0 001.105.048.754.754 0 00.05-1.084l-2.525-2.7v-3.203l2.834 1.602 1.122 3.49a.781.781 0 00.981.5.764.764 0 00.512-.96l-.609-1.896 1.267.717c.122.07.256.102.39.102.27 0 .533-.137.678-.383a.756.756 0 00-.286-1.047l-1.266-.716 1.98-.43a.767.767 0 00.594-.914.782.782 0 00-.933-.582l-3.648.791L13.566 12l2.834-1.602 3.648.791a.778.778 0 00.932-.583.767.767 0 00-.595-.913l-1.978-.43 1.267-.716a.757.757 0 00.285-1.047.79.79 0 00-1.068-.28l-1.266.715.609-1.893a.764.764 0 00-.512-.962.787.787 0 00-.981.5l-1.122 3.49-2.835 1.603V7.467l2.524-2.698a.755.755 0 00-.05-1.083.794.794 0 00-1.104.047l-1.37 1.465V3.766A.774.774 0 0012 3"
                                    id="air_conditionning_svg__a"
                                  ></path>
                                </defs>
                                <use
                                  xlinkHref="#air_conditionning_svg__a"
                                  fillRule="evenodd"
                                ></use>
                              </svg>
                              {vehicleDetails.vehicle_type.vehicle_air_cond}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END -> Vehicle Details */}
              {/* START -> Cart Total */}
              <div className="custom-col col-md-3">
                <div
                  className="bg-white mt-4 p-3 d-flex flex-column card-cart-total"
                  id="payment-section"
                >
                  <div className="pt-2 mb-2">
                    <h5 className="fw-bold">
                      Cart Total
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/ic_outline-rate-review.svg"
                        }
                        style={{ float: "right" }}
                      />
                    </h5>
                  </div>
                  <div className="fare-details-container">
                    <p className="fare-item">
                      <span className="label">Base Fare:</span>
                      <span className="value">
                        {cartTotal.basePrice} USD / Month
                      </span>
                    </p>
                    <p className="fare-item">
                      <span className="label">Duration:</span>
                      <span className="value">{totalMonths} Months</span>
                    </p>
                    <p className="fare-item">
                      <span className="label">Others:</span>
                      <span className="value">
                        {cartTotal.totalOthersPrice} USD
                      </span>
                    </p>
                    <p className="fare-item">
                      <span className="label">Tax:</span>
                      <span className="value">
                        {cartTotal.totalTaxPercentage} %
                      </span>
                    </p>
                    <p className="fare-item">
                      <span className="label">Final Fare:</span>
                      <span className="value">
                        {cartTotal.totalPriceAfterTax} USD
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* END -> Cart Total */}
            </div>
            {/* END -> Vehicle Details & Cart Total  */}

            {/* START -> Protection Plans, Available Extras, Review Booking, Booking Form  */}
            <div className="row">
              <section className="faqs-section">
                <div className="row">
                  <div className="custom-col col-md-9 mt-4 p-0 d-flex flex-column">
                    {/* START -> Protection Plans */}
                    <div className="faqs pb-3" id="accordion">
                      <div className="cards">
                        <div
                          className="card-header protection-plans"
                          id="faqsHeading-1"
                        >
                          <section className="container-fluid mb-2">
                            <div className="plans">
                              <div className="bg row p-3 d-flex justify-content-center">
                                <div className="col-lg-12">
                                  <div className="cards">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                    >
                                      <h3 className="fw-bold mb-3">
                                        <div
                                          onClick={
                                            protectionPlanToggleAccordion
                                          }
                                        >
                                          Insurance & Protection Plans{" "}
                                          <i
                                            className={`fas fa-chevron-${
                                              isProtectionPlanOpen
                                                ? "up"
                                                : "down"
                                            }`}
                                            style={{ float: "right" }}
                                          ></i>
                                        </div>
                                      </h3>
                                    </div>
                                    {isProtectionPlanOpen && (
                                      <div
                                        id="collapseOne"
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                      >
                                        <div className="card-body">
                                          <div className="row pt-1">
                                            {vehicleDetails.insurance_lease.map(
                                              (
                                                vehicleProtectionsPlanDetails,
                                                index
                                              ) => (
                                                <div
                                                  className="col-12 col-md-6 col-lg-4 mb-4"
                                                  key={index}
                                                >
                                                  <div className="card promo-card">
                                                    <div className="card-body text-left">
                                                      <img
                                                        src={`https://backend.carsinafrica.com/assets/images/${vehicleProtectionsPlanDetails.insurance_img}`}
                                                        className="img-fluid rounded"
                                                        alt="protection plan"
                                                      />
                                                      <h5 className="card-title pt-3">
                                                        {
                                                          vehicleProtectionsPlanDetails.insurance_name
                                                        }
                                                      </h5>
                                                      <p className="card-text">
                                                        {vehicleProtectionsPlanDetails.description.split(
                                                          " "
                                                        ).length < 18
                                                          ? vehicleProtectionsPlanDetails.description
                                                          : getTruncatedDescription(
                                                              vehicleProtectionsPlanDetails.description,
                                                              index
                                                            )}
                                                      </p>
                                                      <br />
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <div className="row plans-duel-price">
                                                          <p className="p-plans-duel-price">
                                                            {decimalsToRound(
                                                              vehicleProtectionsPlanDetails.yearly_amount
                                                            )}{" "}
                                                            USD / Year
                                                          </p>
                                                          <p className="p-plans-duel-price">
                                                            {decimalsToRound(
                                                              vehicleProtectionsPlanDetails.currency_yearly_amount
                                                            )}{" "}
                                                            {
                                                              vehicleDetails.currency
                                                            }{" "}
                                                            / Year
                                                          </p>
                                                        </div>
                                                        <div className="remove-button">
                                                          {selectedProtectionPlan.id ===
                                                          vehicleProtectionsPlanDetails.id ? (
                                                            <button
                                                              className="promo-code btn btn-primary remove-button remove-plans-button"
                                                              onClick={() =>
                                                                handleProtectionPlanSelection(
                                                                  vehicleProtectionsPlanDetails
                                                                )
                                                              }
                                                            >
                                                              Remove
                                                            </button>
                                                          ) : (
                                                            <button
                                                              className="promo-code btn btn-primary add-button add-plans-button"
                                                              onClick={() =>
                                                                handleProtectionPlanSelection(
                                                                  vehicleProtectionsPlanDetails
                                                                )
                                                              }
                                                            >
                                                              Add
                                                            </button>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    {/* END -> Protection Plans */}

                    {/* START -> Tariffs Packages */}
                    <div className="faqs pb-3" id="accordion">
                      <div className="cards">
                        <div
                          className="card-header tariff-packages"
                          id="faqsHeading-1"
                        >
                          <section className="container-fluid mb-2">
                            <div className="plans">
                              <div className="bg row p-3 d-flex justify-content-center">
                                <div className="col-lg-12">
                                  <div className="cards">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                    >
                                      <h3 className="fw-bold mb-3">
                                        <div
                                          onClick={
                                            tariffsPackagesToggleAccordion
                                          }
                                        >
                                          Add Extras{" "}
                                          <i
                                            className={`fas fa-chevron-${
                                              isTariffsPackagesOpen
                                                ? "up"
                                                : "down"
                                            }`}
                                            style={{ float: "right" }}
                                          ></i>
                                        </div>
                                      </h3>
                                    </div>
                                    {isTariffsPackagesOpen && (
                                      <div
                                        id="collapseOne"
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                      >
                                        <div className="card-body">
                                          <div className="row pt-1">
                                            {vehicleDetails.driver.lease_id !==
                                            0 ? (
                                              <div className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="card promo-card">
                                                  <div className="card-body text-left">
                                                    <img
                                                      src="https://backend.carsinafrica.com/assets/images/Young-Driver-Surcharge.jpg"
                                                      className="img-fluid rounded"
                                                      alt="driver"
                                                    />
                                                    <h5 className="card-title mb-2 pt-3">
                                                      {"Driver"}
                                                    </h5>
                                                    <ul className="card-text list-unstyled pt-1">
                                                      <li>
                                                        Daily Working Hours :{" "}
                                                        {
                                                          vehicleDetails.driver
                                                            .daily_working_hrs
                                                        }
                                                      </li>
                                                      <li>
                                                        Extra Hours Rate :{" "}
                                                        {decimalsToRound(
                                                          vehicleDetails.driver
                                                            .driver_extra_hrs_rate
                                                        )}{" "}
                                                        USD
                                                      </li>
                                                      <li>
                                                        Working Days :{" "}
                                                        {
                                                          vehicleDetails.driver
                                                            .working_days
                                                        }
                                                      </li>
                                                    </ul>
                                                    <br />
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <div className="row plans-duel-price">
                                                        <p className="p-plans-duel-price">
                                                          {decimalsToRound(
                                                            vehicleDetails
                                                              .driver
                                                              .driver_salary
                                                          )}{" "}
                                                          USD / Month
                                                        </p>
                                                        <p className="p-plans-duel-price">
                                                          {decimalsToRound(
                                                            vehicleDetails
                                                              .driver
                                                              .currency_driver_salary
                                                          )}{" "}
                                                          {
                                                            vehicleDetails.currency
                                                          }{" "}
                                                          / Month
                                                        </p>
                                                      </div>
                                                      <div className="remove-button">
                                                        {selectedDriver.lease_id ===
                                                        vehicleDetails.driver
                                                          .lease_id ? (
                                                          <button
                                                            className="promo-code btn btn-primary remove-button remove-plans-button"
                                                            onClick={() =>
                                                              handleDriverSelection(
                                                                vehicleDetails.driver
                                                              )
                                                            }
                                                          >
                                                            Remove
                                                          </button>
                                                        ) : (
                                                          <button
                                                            className="promo-code btn btn-primary add-button add-plans-button"
                                                            onClick={() =>
                                                              handleDriverSelection(
                                                                vehicleDetails.driver
                                                              )
                                                            }
                                                          >
                                                            Add
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {vehicleDetails.fuel.lease_id !==
                                            0 ? (
                                              <div className="col-12 col-md-6 col-lg-4 mb-4">
                                                <div className="card promo-card">
                                                  <div className="card-body text-left">
                                                    <img
                                                      src="https://backend.carsinafrica.com/assets/images/Fuel.jpg"
                                                      className="img-fluid rounded"
                                                      alt="fuel"
                                                    />
                                                    <h5 className="card-title pt-3">
                                                      Fuel
                                                    </h5>
                                                    <p className="card-text">
                                                      add fuel with your
                                                      vehicle!
                                                    </p>
                                                    <p className="mb-5 pt-4"></p>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <div className="row plans-duel-price">
                                                        <p className="p-plans-duel-price">
                                                          {decimalsToRound(
                                                            vehicleDetails.fuel
                                                              .fuel
                                                          )}{" "}
                                                          USD / Month
                                                        </p>
                                                        <p className="p-plans-duel-price">
                                                          {decimalsToRound(
                                                            vehicleDetails.fuel
                                                              .currency_fuel
                                                          )}{" "}
                                                          {
                                                            vehicleDetails.currency
                                                          }{" "}
                                                          / Month
                                                        </p>
                                                      </div>
                                                      <div className="remove-button">
                                                        {selectedFuel.lease_id ===
                                                        vehicleDetails.fuel
                                                          .lease_id ? (
                                                          <button
                                                            className="promo-code btn btn-primary remove-button remove-plans-button"
                                                            onClick={() =>
                                                              handleFuelSelection(
                                                                vehicleDetails.fuel
                                                              )
                                                            }
                                                          >
                                                            Remove
                                                          </button>
                                                        ) : (
                                                          <button
                                                            className="promo-code btn btn-primary add-button add-plans-button"
                                                            onClick={() =>
                                                              handleFuelSelection(
                                                                vehicleDetails.fuel
                                                              )
                                                            }
                                                          >
                                                            Add
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {vehicleDetails.tariff_products.map(
                                              (
                                                vehicleTariffsPackagesDetails,
                                                index
                                              ) => (
                                                <div
                                                  className="col-12 col-md-6 col-lg-4 mb-4"
                                                  key={index}
                                                >
                                                  <div className="card promo-card lease-card-height">
                                                    <div className="card-body text-left">
                                                      <img
                                                        src={`https://backend.carsinafrica.com/assets/images/${vehicleTariffsPackagesDetails.image}`}
                                                        className="img-fluid rounded"
                                                        alt={
                                                          vehicleTariffsPackagesDetails.title
                                                        }
                                                        style={{
                                                          height: "200px",
                                                          objectFit: "cover",
                                                        }} // Adjust the image height as needed
                                                      />
                                                      <h5 className="card-title pt-3">
                                                        {
                                                          vehicleTariffsPackagesDetails.title
                                                        }
                                                      </h5>
                                                      <p className="card-text description-scroll">
                                                        {vehicleTariffsPackagesDetails.description.split(
                                                          " "
                                                        ).length < 11
                                                          ? vehicleTariffsPackagesDetails.description
                                                          : getTariffsTruncatedDescription(
                                                              vehicleTariffsPackagesDetails.description,
                                                              index
                                                            )}
                                                      </p>

                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <div className="row plans-duel-price">
                                                          <p className="p-plans-duel-price">
                                                            {decimalsToRound(
                                                              vehicleTariffsPackagesDetails.price
                                                            )}{" "}
                                                            USD / Month
                                                          </p>
                                                          <p className="p-plans-duel-price">
                                                            {decimalsToRound(
                                                              vehicleTariffsPackagesDetails.currency_price
                                                            )}{" "}
                                                            {
                                                              vehicleDetails.currency
                                                            }{" "}
                                                            / Month
                                                          </p>
                                                        </div>
                                                        <div className="remove-button">
                                                          {selectedTariffsPackages.some(
                                                            (selectedPackage) =>
                                                              selectedPackage.id ===
                                                              vehicleTariffsPackagesDetails.id
                                                          ) ? (
                                                            <button
                                                              className="promo-code btn btn-primary remove-button remove-plans-button"
                                                              onClick={() =>
                                                                handleTariffsPackagesSelection(
                                                                  vehicleTariffsPackagesDetails
                                                                )
                                                              }
                                                            >
                                                              Remove
                                                            </button>
                                                          ) : (
                                                            <button
                                                              className="promo-code btn btn-primary add-button add-plans-button"
                                                              onClick={() =>
                                                                handleTariffsPackagesSelection(
                                                                  vehicleTariffsPackagesDetails
                                                                )
                                                              }
                                                            >
                                                              Add
                                                            </button>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    {/* END -> Tariffs Packages */}

                    {/* START -> Terms & Conditions */}
                    <div className="faqs pb-3" id="accordion">
                      <div className="cards">
                        <div
                          className="card-header tariff-packages"
                          id="faqsHeading-1"
                        >
                          <section className="container-fluid mb-2">
                            <div className="plans">
                              <div className="bg row p-3 d-flex justify-content-center">
                                <div className="col-lg-12">
                                  <div className="cards">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                    >
                                      <h3 className="fw-bold mb-3">
                                        <div onClick={TermsToggleAccordion}>
                                          More Information{" "}
                                          <i
                                            className={`fas fa-chevron-${
                                              isTermOpen ? "up" : "down"
                                            }`}
                                            style={{ float: "right" }}
                                          ></i>
                                        </div>
                                      </h3>
                                    </div>
                                    {isTermOpen && (
                                      <div
                                        id="collapseOne"
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                      >
                                        <div className="card-body">
                                          <div className="row pt-1">
                                            {vehicleDetails.tariff_lease_terms.map(
                                              (vehicleTerms, index) => (
                                                <div
                                                  className="p-3 justify-content-center"
                                                  key={index}
                                                >
                                                  <li>
                                                    {vehicleTerms.term_name}
                                                    {" : "}
                                                    {
                                                      vehicleTerms.term_description
                                                    }
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    {/* END -> Terms & Conditions */}

                    {/* START -> Review Booking */}
                    <div className="faqs pb-0" id="accordion">
                      <div className="cards">
                        <div
                          className="card-header review-booking"
                          id="faqsHeading-1"
                        >
                          <section className="container-fluid mb-2">
                            <div className="plans">
                              <div className="bg row p-3 d-flex justify-content-center">
                                <div className="col-lg-12">
                                  <div className="cards">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                    >
                                      <h3 className="fw-bold mb-3">
                                        <div
                                          onClick={
                                            toggleAccordionAvailableReviewBooking
                                          }
                                        >
                                          Review And Proceed To Booking{" "}
                                          <i
                                            className={`fas fa-chevron-${
                                              openAvailableReviewBooking
                                                ? "up"
                                                : "down"
                                            }`}
                                            style={{ float: "right" }}
                                          ></i>
                                        </div>
                                      </h3>
                                    </div>
                                    {openAvailableReviewBooking && (
                                      <div
                                        id="collapseOne"
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                      >
                                        <div className="card-body review-booking-desktop">
                                          <div className="table-responsive d-block">
                                            <table
                                              className="table table-bordered"
                                              style={{
                                                border: "1px solid #cdcdcd",
                                                fontSize: "0.85rem",
                                              }}
                                            >
                                              <thead className="table-dark">
                                                <tr className="table-background">
                                                  <th scope="col">Names</th>
                                                  <th scope="col">
                                                    Local Currency
                                                  </th>
                                                  <th scope="col">Price</th>
                                                  <th scope="col">Duration</th>
                                                  <th scope="col">Total</th>
                                                  <th scope="col">
                                                    Yearly Local Currency
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th scope="row">Base Fare</th>
                                                  <td>
                                                    {decimalsToRound(
                                                      usersCartTotal.cartTotal
                                                        .basePrice *
                                                        vehicleDetails.currency_base
                                                    )}{" "}
                                                    {vehicleDetails.currency}
                                                  </td>
                                                  <td>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .basePrice
                                                    }{" "}
                                                    USD
                                                  </td>
                                                  <td>
                                                    {totalMonths} months(s)
                                                  </td>
                                                  <th>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalBasePrice
                                                    }{" "}
                                                    USD
                                                  </th>
                                                  <td>
                                                    {decimalsToRound(
                                                      usersCartTotal.cartTotal
                                                        .totalBasePrice *
                                                        vehicleDetails.currency_base
                                                    )}{" "}
                                                    {vehicleDetails.currency}
                                                  </td>
                                                </tr>
                                                {usersCartTotal.driverAddon
                                                  .is_driver === true ? (
                                                  <tr>
                                                    <th scope="row">Driver</th>
                                                    <td>
                                                      {decimalsToRound(
                                                        usersCartTotal.cartTotal
                                                          .baseDriverPrice *
                                                          vehicleDetails.currency_base
                                                      )}{" "}
                                                      {vehicleDetails.currency}
                                                    </td>
                                                    <td>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .baseDriverPrice
                                                      }{" "}
                                                      USD
                                                    </td>
                                                    <td>
                                                      {totalMonths} Month(s)
                                                    </td>
                                                    <th>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .totalDriverPrice
                                                      }{" "}
                                                      USD
                                                    </th>
                                                    <td>
                                                      {decimalsToRound(
                                                        usersCartTotal.cartTotal
                                                          .totalDriverPrice *
                                                          vehicleDetails.currency_base
                                                      )}{" "}
                                                      {vehicleDetails.currency}
                                                    </td>
                                                  </tr>
                                                ) : null}
                                                {usersCartTotal.fuelAddon
                                                  .is_fuel === true ? (
                                                  <tr>
                                                    <th scope="row">Fuel</th>
                                                    <td>
                                                      {decimalsToRound(
                                                        usersCartTotal.cartTotal
                                                          .baseFuelPrice *
                                                          vehicleDetails.currency_base
                                                      )}{" "}
                                                      {vehicleDetails.currency}
                                                    </td>
                                                    <td>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .baseFuelPrice
                                                      }{" "}
                                                      USD
                                                    </td>
                                                    <td>
                                                      {totalMonths} Month(s)
                                                    </td>
                                                    <th>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .totalFuelPrice
                                                      }{" "}
                                                      USD
                                                    </th>
                                                    <td>
                                                      {decimalsToRound(
                                                        usersCartTotal.cartTotal
                                                          .totalFuelPrice *
                                                          vehicleDetails.currency_base
                                                      )}{" "}
                                                      {vehicleDetails.currency}
                                                    </td>
                                                  </tr>
                                                ) : null}
                                                {usersCartTotal
                                                  .protectionPackages
                                                  .is_protection_plan ===
                                                true ? (
                                                  <tr>
                                                    <th scope="row">
                                                      Protection Plan
                                                    </th>
                                                    <td>
                                                      {decimalsToRound(
                                                        usersCartTotal.cartTotal
                                                          .baseProtectionPlanPrice *
                                                          vehicleDetails.currency_base
                                                      )}{" "}
                                                      {vehicleDetails.currency}
                                                    </td>
                                                    <td>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .baseProtectionPlanPrice
                                                      }{" "}
                                                      USD
                                                    </td>
                                                    <td>
                                                      {roundedTotalMonths}{" "}
                                                      Month(s)
                                                    </td>
                                                    <th>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .totalProtectionPlanPrice
                                                      }{" "}
                                                      USD
                                                    </th>
                                                    <td>
                                                      {decimalsToRound(
                                                        usersCartTotal.cartTotal
                                                          .totalProtectionPlanPrice *
                                                          vehicleDetails.currency_base
                                                      )}{" "}
                                                      {vehicleDetails.currency}
                                                    </td>
                                                  </tr>
                                                ) : null}
                                                {usersCartTotal.tariffsPackages.map(
                                                  (tariff, index) => (
                                                    <tr key={index}>
                                                      <th scope="row">
                                                        {tariff.title}
                                                      </th>
                                                      <td>
                                                        {decimalsToRound(
                                                          tariff.price *
                                                            vehicleDetails.currency_base
                                                        )}{" "}
                                                        {
                                                          vehicleDetails.currency
                                                        }
                                                      </td>
                                                      <td>
                                                        {decimalsToRound(
                                                          tariff.price
                                                        )}{" "}
                                                        USD
                                                      </td>
                                                      <td>
                                                        {totalMonths} Month(s)
                                                      </td>
                                                      <th>
                                                        {decimalsToRound(
                                                          tariff.price *
                                                            totalMonths
                                                        )}{" "}
                                                        USD
                                                      </th>
                                                      <td>
                                                        {decimalsToRound(
                                                          tariff.price *
                                                            totalMonths *
                                                            vehicleDetails.currency_base
                                                        )}{" "}
                                                        {
                                                          vehicleDetails.currency
                                                        }
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                                <tr>
                                                  <th scope="row">Tax</th>
                                                  <td>
                                                    {decimalsToRound(
                                                      usersCartTotal.cartTotal
                                                        .baseTax *
                                                        vehicleDetails.currency_base
                                                    )}{" "}
                                                    {vehicleDetails.currency}
                                                  </td>
                                                  <td>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .baseTax
                                                    }{" "}
                                                    USD
                                                  </td>
                                                  <td>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalTaxPercentage
                                                    }{" "}
                                                    %
                                                  </td>
                                                  <th>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalTax
                                                    }{" "}
                                                    USD
                                                  </th>
                                                  <td>
                                                    {decimalsToRound(
                                                      usersCartTotal.cartTotal
                                                        .totalTax *
                                                        vehicleDetails.currency_base
                                                    )}{" "}
                                                    {vehicleDetails.currency}
                                                  </td>
                                                </tr>
                                              </tbody>
                                              <thead className="table-dark">
                                                <tr>
                                                  <th scope="col">
                                                    Final Fare
                                                  </th>
                                                  <th scope="col">
                                                    {decimalsToRound(
                                                      usersCartTotal.cartTotal
                                                        .priceAfterTax *
                                                        vehicleDetails.currency_base
                                                    )}{" "}
                                                    {vehicleDetails.currency}
                                                  </th>
                                                  <th scope="col">
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .priceAfterTax
                                                    }{" "}
                                                    USD
                                                  </th>
                                                  <th scope="col">
                                                    {totalMonths} Month(s)
                                                  </th>
                                                  <th scope="col">
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalPriceAfterTax
                                                    }{" "}
                                                    USD
                                                  </th>
                                                  <th scope="col">
                                                    {decimalsToRound(
                                                      usersCartTotal.cartTotal
                                                        .totalPriceAfterTax *
                                                        vehicleDetails.currency_base
                                                    )}{" "}
                                                    {vehicleDetails.currency}
                                                  </th>
                                                </tr>
                                              </thead>
                                            </table>
                                          </div>
                                        </div>
                                        {/* <div className="card-body review-booking-mobile">
                                          <div className="table-responsive d-block">
                                            <table
                                              className="table table-bordered"
                                              style={{
                                                border: "1px solid #cdcdcd",
                                                fontSize: "0.85rem",
                                              }}
                                            >
                                              <thead className="table-dark">
                                                <tr className="table-background">
                                                  <th scope="col">Names</th>
                                                  <th scope="col">Duration</th>
                                                  <th scope="col">Total</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th scope="row">Base Fare</th>
                                                  <td>
                                                    {totalMonths} months(s)
                                                  </td>
                                                  <th>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalBasePrice
                                                    }{" "}
                                                    USD
                                                  </th>
                                                </tr>
                                                {usersCartTotal.driverAddon
                                                  .is_driver === true ? (
                                                  <tr>
                                                    <th scope="row">Driver</th>

                                                    <td>
                                                      {totalMonths} Month(s)
                                                    </td>
                                                    <th>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .totalDriverPrice
                                                      }{" "}
                                                      USD
                                                    </th>
                                                  </tr>
                                                ) : null}
                                                {usersCartTotal.fuelAddon
                                                  .is_fuel === true ? (
                                                  <tr>
                                                    <th scope="row">Fuel</th>

                                                    <td>
                                                      {totalMonths} Month(s)
                                                    </td>
                                                    <th>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .totalFuelPrice
                                                      }{" "}
                                                      USD
                                                    </th>
                                                  </tr>
                                                ) : null}
                                                {usersCartTotal
                                                  .protectionPackages
                                                  .is_protection_plan ===
                                                true ? (
                                                  <tr>
                                                    <th scope="row">
                                                      Protection Plan
                                                    </th>

                                                    <td>
                                                      {roundedTotalMonths}{" "}
                                                      Month(s)
                                                    </td>
                                                    <th>
                                                      {
                                                        usersCartTotal.cartTotal
                                                          .totalProtectionPlanPrice
                                                      }{" "}
                                                      USD
                                                    </th>
                                                  </tr>
                                                ) : null}
                                                {usersCartTotal.tariffsPackages.map(
                                                  (tariff, index) => (
                                                    <tr key={index}>
                                                      <th scope="row">
                                                        {tariff.title}
                                                      </th>

                                                      <td>
                                                        {totalMonths} Month(s)
                                                      </td>
                                                      <th>
                                                        {decimalsToRound(
                                                          tariff.price *
                                                            totalMonths
                                                        )}{" "}
                                                        USD
                                                      </th>
                                                    </tr>
                                                  )
                                                )}
                                                <tr>
                                                  <th scope="row">Tax</th>

                                                  <td>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalTaxPercentage
                                                    }{" "}
                                                    %
                                                  </td>
                                                  <th>
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalTax
                                                    }{" "}
                                                    USD
                                                  </th>
                                                </tr>
                                              </tbody>
                                              <thead className="table-dark">
                                                <tr>
                                                  <th scope="col">
                                                    Final Fare
                                                  </th>

                                                  <th scope="col">
                                                    {totalMonths} Month(s)
                                                  </th>
                                                  <th scope="col">
                                                    {
                                                      usersCartTotal.cartTotal
                                                        .totalPriceAfterTax
                                                    }{" "}
                                                    USD
                                                  </th>
                                                </tr>
                                              </thead>
                                            </table>
                                          </div>
                                        </div> */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    {/* END -> Review Billing */}
                  </div>
                  {/* START -> Booking Form */}

                  <div className="custom-col col-md-3 booking-details-sticky">
                    <div
                      className="bg-white mt-4 p-3 d-flex flex-column booking-details"
                      id="payment-section"
                    >
                      <div className="rn-cart-total-form">
                        <h5 className="fw-bold pt-2 mb-4">Booking Details</h5>
                        <form onSubmit={handleLeaseBookingSubmit}>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="rn-icon-input mb-3"
                                id="booking-input-icon"
                              >
                                <i className="fa fa-user " />
                                <input
                                  type="text"
                                  className="form-control lease-inputfield"
                                  value={userLoggedData.lease_user_name}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-12" />
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="rn-icon-input mb-3"
                                id="booking-input-icon"
                              >
                                <i className="bi bi-envelope-fill me-3" />
                                <input
                                  type="text"
                                  className="form-control lease-inputfield"
                                  value={userLoggedData.lease_emailid}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="rn-icon-input mb-3"
                                id="booking-input-icon"
                              >
                                <i
                                  className="fa fa-mobile"
                                  aria-hidden="true"
                                />
                                <input
                                  type="text"
                                  className="form-control lease-inputfield"
                                  placeholder="Pickup Address"
                                  id="source_address"
                                  name="source_address"
                                  defaultValue={userLoggedData.lease_mobno}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="rn-icon-input mb-3"
                                id="booking-input-icon"
                              >
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                />
                                <input
                                  type="text"
                                  className="form-control lease-inputfield"
                                  placeholder="Pickup Address"
                                  id="source_address"
                                  name="source_address"
                                  defaultValue={vehicleDetails.pickup_location}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="rn-icon-input mb-3"
                                id="booking-input-icon"
                              >
                                <i
                                  className="bi bi-calendar-check"
                                  aria-hidden="true"
                                />
                                <input
                                  type="text"
                                  className="form-control lease-inputfield"
                                  placeholder="Pickup Address"
                                  id="source_address"
                                  name="source_address"
                                  defaultValue={totalMonths + "  " + "Months"}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <Checkbox required size="large">
                            <medium>
                              By proceeding to book, I Agree to CarsInAfrica{" "}
                              <Link
                                to="/privacy-policy"
                                style={{ color: "#f57b20" }}
                              >
                                Privacy Policy
                              </Link>{" "}
                              and{" "}
                              <Link
                                to="/terms-and-conditions"
                                style={{ color: "#f57b20" }}
                              >
                                Terms of Service.
                              </Link>
                            </medium>
                          </Checkbox>
                          <div className="pt-2">
                            <input
                              type="submit"
                              defaultValue="submit"
                              name="send"
                              id="send"
                              value="Book Now"
                              style={{ background: "#f57b20", border: "none" }}
                              className="btn btn-primary btn-lg btn-shadow btn-block"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* END -> Booking Form */}
                </div>
              </section>
            </div>
            {/* END -> Protection Plans, Available Extras, Review Booking, Booking Form  */}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="rn-section rn-car-search-results loader-container p-5 pt-5">
          <div>
            <div className=""></div>
            <div>
              <h1>Vehicles Not Available!</h1>
              <div className=""></div>
              <Link
                className="btn btn-success btn-lg btn-shadow custom-link-vehicle"
                to={`/lease`}
              >
                Go Home!
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
