/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Select } from "antd";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button, Form, DatePicker, Input, Checkbox, InputNumber } from "antd";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import "./Carbooking.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  AudioOutlined,
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  getToken,
  storeBooking,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import {
  useBookingMutation,
  useSavaariBookingMutation,
} from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";
import { setUserToken } from "../../services/Apis/CarsInAfrica/Auth/features/authSlice";
import { useDispatch } from "react-redux";
import Topbar from "../../components/Topbar/Topbar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import savaarisourcecities from "../../services/Apis/Savaari/Json/source-cities.json";
import savaaridestinationcities from "../../services/Apis/Savaari/Json/destination-cities.json";

const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

const onSearch = (value: string) => console.log(value);
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */
const onChange = (e: CheckboxChangeEvent) => {
  console.log(`checked = ${e.target.checked}`);
};
const onFinish = (values: any) => {
  console.log(values);
};
export default function Booking() {
  const [tariffId, setTariffId] = useState(0);
  const [initialFare, setInitialFare] = useState(0);
  const [totalFare, setTotalFare] = useState(0);
  const [vehicledetails, setVehicledetails] = useState([]);
  const [bookingdetails, setBookingdetails] = useState([]);
  const [couponDetail, setCouponDetail] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponAppliedFlag, setCouponAppliedFlag] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponId, setCouponId] = useState(0);
  const [termsAndCondition, setTermsAndCondition] = useState(0);
  const [tnCDetailsLoading, setTnCDetailsLoading] = useState(false);
  const [tnCDetailsStatus, setTnCDetailsStatus] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [formData, setFormData] = useState("");
  const [guestname, setGuestname] = useState("");
  const [email, setEmail] = useState("");
  const [contact_number, setContactnumber] = useState("");
  const [vehicleLoading, setVehicleLoading] = useState(true);
  const [vehicleResultFlag, setVehicleResultFlag] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [travellerName, setTravellerName] = useState("");
  const [travellerEmail, setTravellerEmail] = useState("");
  const [travellerPhoneNumber, setTravellerPhoneNumber] = useState("");

  const [savaariApiToken, setSavaariApiToken] = useState(null);

  const [savaariSource, setSavaariSource] = useState({});
  const [savaariDestination, setSavaariDestination] = useState({});

  const [savaariVehicleService, setSavaariVehicleService] = useState(null);
  const [savaariVehicleSubService, setSavaariVehicleSubService] =
    useState(null);

  const [savaariVehicleLoading, setSavaariVehicleLoading] = useState(true);
  const [savaaariVehicleResultFlag, setSavaariVehicleResultFlag] =
    useState(false);

  const [savaariVehicleDetail, setSavaariVehicleDetail] = useState([]);
  const [savaariVehiclePriceWithMarkup, setSavaariVehiclePriceWithMarkup] =
    useState([]);
  const [savaariCarName, setSavaariCarName] = useState(null);
  const [savaariVehicles, setSavaariVehicles] = useState([]);

  const [rzPaymentId, setRzPaymentId] = useState(null);

  const noOfDaysDuration = 1;

  const {
    service,
    subservice,
    paddress,
    pcity,
    pstate,
    pcountry,
    pcountryiso,
    platcoordinates,
    plngcoordinates,
    daddress,
    dcity,
    dstate,
    dcountry,
    dcountryiso,
    dlatcoordinates,
    dlngcoordinates,
    pdate,
    ptime,
    ddate,
    dtime,
    vehicletid,
    vehicleid,
    searchId,
    userid,
    svri,
    carid,
  } = useParams();

  const savaariServiceTax = 1.1;

  const decimalsToRound = (num, decimalPlaces = 2) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  };

  useEffect(() => {
    // Make an API request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=vehicle-booking&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });

  useEffect(() => {
    if (svri === "svri" && pcountryiso === "IN") {
      getSavaariToken();
      setSavaariCityIds();
      setSavaariVehicleServices();
    } else {
      getVehicledetails();
      getTermsAndCondition();
    }
  }, []);

  useEffect(() => {
    if (savaariApiToken) {
      getSavaariVehicledetails();
    }
  }, [savaariApiToken]);

  // START - Coupon
  function handleDiscountAmount(couponDetail) {
    setDiscountAmount(totalFare / couponDetail.amount);
  }
  function handleCouponId(couponDetail) {
    setCouponId(couponDetail.id);
  }

  const getCouponDetails = async (couponCode) => {
    try {
      const response = await axios.get(
        `https://backend.carsinafrica.com/frontend/checkCouponData?coupon_id=${couponCode}&user_id=${userLoggedData.user_id}`
      );

      if (response.data.message === "success") {
        setCouponDetail(response.data.data);
        setCouponAppliedFlag(true);
        handleDiscountAmount(response.data.data);
        handleCouponId(response.data.data);
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.error,
          icon: "error",
        });
      }
    } catch (error) {
      // console.error("Error fetching coupon details:", error);
    }
  };

  const handleCouponSubmit = (event) => {
    event.preventDefault();
    getCouponDetails(coupon);
  };

  const handleCouponEdit = (event) => {
    event.preventDefault();
    setCouponDetail(null);
    setDiscountAmount(0);
    setCouponId(0);
    setCouponAppliedFlag(false);
  };

  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
  };

  // END - Coupon

  function getVehicledetails() {
    const url = "https://backend.carsinafrica.com/frontend/getDetailsTariffs";

    // Define the request data as an object
    const requestData = {
      vid: vehicleid,
      tariffid: vehicletid,
      searchID: searchId,
      user_id: userLoggedData.user_id,
    };

    const jsonData = JSON.stringify(requestData);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(url, jsonData, { headers: headers })
      .then(function (response) {
        if (response.data.message === "failed") {
          setVehicleLoading(false);
          setVehicleResultFlag(false);
        } else {
          setVehicledetails(response.data.data);
          setInitialFare(response.data.data[0].base_fare);
          setTotalFare(response.data.data[0].total_fare);
          setTariffId(response.data.data[0].tid);
          setBookingdetails(response.data.data[0].searchData);
          setVehicleLoading(false);
          setVehicleResultFlag(true);
        }
      })
      .catch(function (error) {
        setVehicleLoading(false);
        setVehicleResultFlag(false);
      });
  }

  function getTermsAndCondition() {
    axios
      .get(
        `https://backend.carsinafrica.com/onlineApi/searchTermsGet.php?city=${pcity}&service=${service}`
      )
      .then(function (response) {
        setTermsAndCondition(response.data);
        setTnCDetailsLoading(true);
        setTnCDetailsStatus(true);
      });
  }

  function getSavaariToken() {
    axios
      .get(`https://backend.carsinafrica.com/savaari_api/get_savaari_token.php`)
      .then(function (response) {
        setSavaariApiToken(response.data.data.token);
      });
  }

  function setSavaariCityIds() {
    setSavaariSource(
      savaarisourcecities.find((tpcity) => tpcity.org === pcity)
    );
    setSavaariDestination(
      savaaridestinationcities.find((tdcity) => tdcity.org === dcity)
    );
  }

  function setSavaariVehicleServices() {
    if (service === "local") {
      if (subservice === "halfday") {
        setSavaariVehicleService("local");
        setSavaariVehicleSubService("880");
      } else if (subservice === "fullday") {
        setSavaariVehicleService("local");
        setSavaariVehicleSubService("12120");
      }
    } else if (service === "outstation") {
      setSavaariVehicleService("outstation");
      setSavaariVehicleSubService("oneway");
    } else if (service === "transfer") {
      if (subservice === "pick-airport") {
        setSavaariVehicleService("airport");
        setSavaariVehicleSubService("pick_airport");
      } else if (subservice === "drop-airport") {
        setSavaariVehicleService("airport");
        setSavaariVehicleSubService("drop_airport");
      }
    }
  }

  function getSavaariVehicledetails() {
    const savaariErrorData = [
      // Add more error objects as needed
      { id: 1, message: "Error occurred: Vehicle not found", carId: null },
    ];
    if (savaariVehicleService === "local") {
      // START - Local -> savaari vehicle GET
      if (savaariSource) {
        axios
          .get(
            `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken}&sourceCity=${savaariSource.cityId}&tripType=${savaariVehicleService}&subTripType=${savaariVehicleSubService}&pickupDateTime=${pdate}%20${ptime}&duration=2`
          )
          .then(function (response) {
            if (response.data.status === "success") {
              setSavaariVehicleResultFlag(true);
              const availableCars = response.data.data.availableCars;
              setSavaariVehicles(response.data.data.availableCars);
              const savaariVehicleDetail = availableCars.find(
                (car) => car.carId == carid
              );
              setSavaariVehicleDetail(savaariVehicleDetail);
              setSavaariVehiclePriceWithMarkup(
                savaariVehicleDetail.rates.discounted
                  ? decimalsToRound(
                      parseFloat(
                        savaariVehicleDetail.rates.discounted.totalAmount
                      ) * savaariServiceTax
                    )
                  : decimalsToRound(
                      parseFloat(
                        savaariVehicleDetail.rates.regular.totalAmount
                      ) * savaariServiceTax
                    )
              );
              setSavaariCarName(savaariVehicleDetail.carName);
              setSavaariVehicleLoading(false);
              setVehicleLoading(false);
            } else {
              setSavaariVehicleResultFlag(false);
              setSavaariVehicleLoading(false);
              setVehicleLoading(false);
            }
          })
          .catch(function (error) {
            setSavaariVehicleResultFlag(false);
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
            console.error(error.message);
          });
      } else {
        console.error("Pickup City Is Invalid");
        setSavaariVehicles(savaariErrorData);
        setSavaariVehicleLoading(false);
        setVehicleLoading(false);
      }
      // END - Local -> savaari vehicle GET
    } else if (savaariVehicleService === "outstation") {
      if (savaariVehicleSubService === "oneway") {
        // START - outstation -> oneway savaari vehicle GET

        if (savaariSource) {
          if (savaariDestination) {
            axios
              .get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken}&sourceCity=${savaariSource.cityId}&tripType=${savaariVehicleService}&subTripType=${savaariVehicleSubService}&pickupDateTime=${pdate}%20${ptime}&duration=1&destinationCity=${savaariDestination.cityId}&customerLatLong=${platcoordinates},${plngcoordinates}`
              )
              .then(function (response) {
                if (response.data.status === "success") {
                  setSavaariVehicleResultFlag(true);
                  const availableCars = response.data.data.availableCars;
                  setSavaariVehicles(response.data.data.availableCars);
                  const savaariVehicleDetail = availableCars.find(
                    (car) => car.carId == carid
                  );
                  setSavaariVehicleDetail(savaariVehicleDetail);
                  setSavaariVehiclePriceWithMarkup(
                    savaariVehicleDetail.rates.discounted
                      ? decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.discounted.totalAmount
                          ) * savaariServiceTax
                        )
                      : decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.regular.totalAmount
                          ) * savaariServiceTax
                        )
                  );
                  setSavaariCarName(savaariVehicleDetail.carName);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                } else {
                  setSavaariVehicleResultFlag(false);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                }
              })
              .catch(function (error) {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                console.error(error.message);
              });
          } else {
            console.error("Drop City not found!");
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
          }
        } else {
          console.error("Pickup City not found!");
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
        }
        // END - outstation -> oneway savaari vehicle GET
      } else if (savaariVehicleSubService === "roundtrip") {
        // START - outstation -> roundtrip savaari vehicle GET
        if (savaariSource) {
          if (savaariDestination) {
            axios
              .get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken}&sourceCity=${savaariSource.cityId}&tripType=${savaariVehicleService}&subTripType=${savaariVehicleSubService}&pickupDateTime=${pdate}%20${ptime}&duration=${noOfDaysDuration}&destinationCity=${savaariDestination.cityId}&customerLatLong=${platcoordinates},${plngcoordinates}`
              )
              .then(function (response) {
                if (response.data.status === "success") {
                  setSavaariVehicleResultFlag(true);
                  const availableCars = response.data.data.availableCars;
                  setSavaariVehicles(response.data.data.availableCars);
                  const savaariVehicleDetail = availableCars.find(
                    (car) => car.carId == carid
                  );
                  setSavaariVehicleDetail(savaariVehicleDetail);
                  setSavaariVehiclePriceWithMarkup(
                    savaariVehicleDetail.rates.discounted
                      ? decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.discounted.totalAmount
                          ) * savaariServiceTax
                        )
                      : decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.regular.totalAmount
                          ) * savaariServiceTax
                        )
                  );
                  setSavaariCarName(savaariVehicleDetail.carName);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                } else {
                  setSavaariVehicleResultFlag(false);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                }
              })
              .catch(function (error) {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                console.error(error.message);
              });
          } else {
            console.error("Drop City not found!");
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
          }
        } else {
          console.error("Pickup City not found!");
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
        }
        // END - outstation -> roundtrip savaari vehicle GET
      }
    } else if (savaariVehicleService === "airport") {
      if (savaariVehicleSubService === "pick_airport") {
        // START - transfer -> Pick Airport savaari vehicle GET
        if (savaariSource) {
          if (savaariDestination) {
            axios
              .get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken}&sourceCity=${savaariSource.cityId}&destinationCity=${savaariDestination.cityId}&tripType=${savaariVehicleService}&subTripType=${savaariVehicleSubService}&pickupDateTime=${pdate}%20${ptime}&duration=1&customerLatLong=${platcoordinates},${plngcoordinates}`
              )
              .then(function (response) {
                if (response.data.status === "success") {
                  setSavaariVehicleResultFlag(true);
                  const availableCars = response.data.data.availableCars;
                  setSavaariVehicles(response.data.data.availableCars);
                  const savaariVehicleDetail = availableCars.find(
                    (car) => car.carId == carid
                  );
                  setSavaariVehicleDetail(savaariVehicleDetail);
                  setSavaariVehiclePriceWithMarkup(
                    savaariVehicleDetail.rates.discounted
                      ? decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.discounted.totalAmount
                          ) * savaariServiceTax
                        )
                      : decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.regular.totalAmount
                          ) * savaariServiceTax
                        )
                  );
                  setSavaariCarName(savaariVehicleDetail.carName);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                } else {
                  setSavaariVehicleResultFlag(false);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                }
              })
              .catch(function (error) {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                console.error(error.message);
              });
          } else {
            console.error("Drop City not found!");
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
          }
        } else {
          console.error("Pickup City not found!");
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
        }
        // END - transfer -> Pick Airport savaari vehicle GET
      } else if (savaariVehicleSubService === "drop_airport") {
        // START - transfer -> Drop Airport savaari vehicle GET
        if (savaariSource) {
          if (savaariDestination) {
            axios
              .get(
                `https://api.savaari.com/partner_api/public/availabilities?token=${savaariApiToken}&sourceCity=${savaariSource.cityId}&destinationCity=${savaariDestination.cityId}&tripType=${savaariVehicleService}&subTripType=${savaariVehicleSubService}&pickupDateTime=${pdate}%20${ptime}&duration=1&customerLatLong=${platcoordinates},${plngcoordinates}`
              )
              .then(function (response) {
                if (response.data.status === "success") {
                  setSavaariVehicleResultFlag(true);
                  const availableCars = response.data.data.availableCars;
                  setSavaariVehicles(response.data.data.availableCars);
                  const savaariVehicleDetail = availableCars.find(
                    (car) => car.carId == carid
                  );
                  setSavaariVehicleDetail(savaariVehicleDetail);
                  setSavaariVehiclePriceWithMarkup(
                    savaariVehicleDetail.rates.discounted
                      ? decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.discounted.totalAmount
                          ) * savaariServiceTax
                        )
                      : decimalsToRound(
                          parseFloat(
                            savaariVehicleDetail.rates.regular.totalAmount
                          ) * savaariServiceTax
                        )
                  );
                  setSavaariCarName(savaariVehicleDetail.carName);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                } else {
                  setSavaariVehicleResultFlag(false);
                  setSavaariVehicleLoading(false);
                  setVehicleLoading(false);
                }
              })
              .catch(function (error) {
                setSavaariVehicleResultFlag(false);
                setSavaariVehicleLoading(false);
                setVehicleLoading(false);
                console.error(error.message);
              });
          } else {
            console.error("Drop City not found!");
            setSavaariVehicleLoading(false);
            setVehicleLoading(false);
          }
        } else {
          console.error("Pickup City not found!");
          setSavaariVehicleLoading(false);
          setVehicleLoading(false);
        }
        // END - transfer -> Pick Airport savaari vehicle GET
      }
    }
  }

  const [pday, pmonth, pyear] = pdate.split("-");
  const [dday, dmonth, dyear] = ddate.split("-");
  const handleBlur = () => {
    if (travellerPhoneNumber && travellerPhoneNumber.length > 15) {
      const truncatedValue = travellerPhoneNumber.substring(0, 15);
      setTravellerPhoneNumber(truncatedValue);
    }
  };
  const { Option } = Select;
  const [selectedTravellers, setSelectedTravellers] = useState([]);

  const handleSelectChange = (value) => {
    setSelectedTravellers(value);
  };
  const [hideFields, setHideFields] = useState(false);

  const handleCheckboxChange = () => {
    setHideFields(!hideFields);
  };
  const handleNameChange = (e) => {
    setTravellerName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setTravellerEmail(e.target.value);
  };

  const handlePhoneNumberChange = (value) => {
    setTravellerPhoneNumber(value);
  };

  // START - CIA User Ride Booking

  const navigate = useNavigate();
  const userLoggedData = getToken();
  const [booking] = useBookingMutation();
  const [savaariBooking] = useSavaariBookingMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBookingLoading(true);
    const data = new FormData(e.currentTarget);
    const bookingData = {
      source_address: bookingdetails.pickup_address,
      dest_address: bookingdetails.dropoff_address,
      journey_date: `${bookingdetails.start_date} ${bookingdetails.start_time}`,
      end_date: `${bookingdetails.drop_date} ${bookingdetails.drop_time}`,
      journey_time: bookingdetails.start_time,
      note: data.get("note"),
      vehicle_typeid: vehicledetails.vehicletype,
      booking_service_type: service,
      user_id: userLoggedData.user_id,
      vehicle_id: vehicleid,
      booking_type: 1,
      base_fare: totalFare,
      tid: vehicletid,
      discounted_fare: totalFare - discountAmount,
      coupon_id: parseInt(couponId),
      guest_name: travellerName,
      g_email: travellerEmail,
      g_phone: travellerPhoneNumber,
    };
    const res = await booking(bookingData);
    setBookingLoading(false);
    if (res.data.success === "1") {
      Swal.fire({
        title: "Success",
        text: res.data.message,
        icon: "success",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      }).then(() => {
        storeBooking(res.data.success, res.data.message);
        navigate("/dashboard");
      });
    }
    if (res.data.success === 0) {
      Swal.fire({
        title: "Error",
        text: res.data.message,
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      }).then(() => {
        window.location.reload();
      });
    }
  };
  // END - CIA User Ride Booking

  // START - Savaari user Ride Booking

  const handleSavaariPaymentSuccess = async (response, savaariBookingData) => {
    setBookingLoading(true);
    try {
      if (response.razorpay_payment_id) {
        // Payment successful
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
        };

        setRzPaymentId(response.razorpay_payment_id);

        // Update the savaari booking data with payment ID
        savaariBookingData = {
          ...savaariBookingData,
          payment_id: response.razorpay_payment_id,
        };

        // Call the savaari API to create booking
        const savaariUrl = `https://api.savaari.com/partner_api/public/booking?token=${savaariApiToken}`;
        const savaariParams = new URLSearchParams();
        savaariParams.append("sourceCity", savaariBookingData.sourceCity);
        savaariParams.append("tripType", savaariBookingData.tripType);
        savaariParams.append("subTripType", savaariBookingData.subTripType);
        savaariParams.append(
          "pickupDateTime",
          savaariBookingData.pickupDateTime
        );
        savaariParams.append("duration", 1);
        savaariParams.append("pickupAddress", savaariBookingData.pickupAddress);
        savaariParams.append("customerName", savaariBookingData.customerName);
        savaariParams.append("customerEmail", savaariBookingData.customerEmail);
        savaariParams.append(
          "customerMobile",
          savaariBookingData.customerMobile
        );
        savaariParams.append("carType", savaariBookingData.carType);
        savaariParams.append("prePayment", savaariBookingData.prePayment);
        savaariParams.append("couponCode", savaariBookingData.couponCode);

        if (service !== "transfer") {
          savaariParams.append(
            "destinationCity",
            savaariBookingData.destinationCity
          );
        } else {
          savaariParams.append(
            "customerLatLong",
            `${platcoordinates},${plngcoordinates}`
          );
        }

        const savaariResponse = await axios.post(savaariUrl, savaariParams);

        if (savaariResponse.data.status === "success") {
          // Booking in Savaari successful, now proceed with booking in CIA
          const bookingData = {
            source_address: savaariBookingData.source_address,
            dest_address: savaariBookingData.dest_address,
            journey_date: savaariBookingData.pickupDateTime,
            end_date: savaariBookingData.end_date,
            journey_time: savaariBookingData.journey_time,
            user_id: savaariBookingData.user_id,
            no_of_persons: savaariBookingData.no_of_person,
            note: savaariBookingData.note,
            vehicle_typeid: "",
            booking_service_type: service,
            vehicle_id: "",
            booking_type: 1,
            base_fare: savaariBookingData.prePayment,
            booking_owner: "savaari",
            total_fare: savaariVehicleDetail.rates.regular.totalAmount,
            booking_id: savaariResponse.data.data.bookingId,
            reservationId: savaariResponse.data.data.reservationId,
            sourceCity: savaariResponse.data.data.sourceCity,
            tripType: savaariResponse.data.data.tripType,
            subTripType: savaariResponse.data.data.subTripType,
            pickupDate: pdate,
            pickupTime: ptime,
            endDate: ddate,
            endTime: dtime,
            pickupAddress: savaariResponse.data.data.pickupAddress,
            dropAddress: savaariResponse.data.data.dropAddress,
            landmark: savaariResponse.data.data.landmark,
            carType: savaariResponse.data.data.carType,
            package: savaariResponse.data.data.package,
            customer_name: savaariResponse.data.data.customer.name,
            customer_email: savaariResponse.data.data.customer.email,
            customer_mobile: savaariResponse.data.data.customer.mobile,
            fareBreakup_baseFare:
              savaariResponse.data.data.fareBreakup.baseFare,
            fareBreakup_serviceTax:
              savaariResponse.data.data.fareBreakup.serviceTax,
            fareBreakup_baseKilometerRate:
              savaariResponse.data.data.fareBreakup.baseKilometerRate,
            fareBreakup_driverDayCharge:
              savaariResponse.data.data.fareBreakup.driverDayCharge,
            fareBreakup_driverNightCharge:
              savaariResponse.data.data.fareBreakup.driverNightCharge,
            extras_extraKilometerRate:
              savaariResponse.data.data.extras.extraKilometerRate,
            extras_extaHourRate: savaariResponse.data.data.extras.extaHourRate,
            totalFare: savaariResponse.data.data.totalFare,
            discount: savaariResponse.data.data.discount,
            cashToCollect: savaariResponse.data.data.cashToCollect,
            prePayment: savaariResponse.data.data.prePayment,
            tripKilometer: savaariResponse.data.data.tripKilometer,
            tripHour: savaariResponse.data.data.tripHour,
            car_name: savaariVehicleDetail.carName,
            car_type: savaariVehicleDetail.carNameAlias,
            itineary: savaariResponse.data.data.itineary,
            pincode_surge_details:
              savaariResponse.data.data.pincode_surge_details,
            pincode_surge_flag: savaariResponse.data.data.pincode_surge_flag,
            payment_id: response.razorpay_payment_id,
            vehicle_image_url: savaariVehicleDetail.carImageLarge,
            guest_name: travellerName,
            g_email: travellerEmail,
            g_phone: travellerPhoneNumber,
            markup: 10,
            total_price_after_markup: savaariVehiclePriceWithMarkup,
          };

          // Call savaariBooking mutation
          const res = await savaariBooking(bookingData);
          if (res.data.success == 1) {
            // Store Token Code here
            storeBooking(res.data.success, res.data.message);
            setBookingLoading(true);
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              navigate("/dashboard");
            });
          } else {
            setError({ status: true, msg: res.data.message, type: "error" });
            setBookingLoading(true);
            Swal.fire({
              title: "Error",
              text: res.data.message,
              icon: "error",
            });
          }
        } else {
          // Savaari booking failed
          setBookingLoading(true);
          setError({
            status: true,
            msg: "booking failed",
            type: "error",
          });
          Swal.fire({
            title: "Error",
            text: "booking failed",
            icon: "error",
          });
          // console.error("Savaari booking failed:", savaariResponse.error);
          // alert("Savaari booking failed. Please try again.");
        }
      } else {
        // Payment failed
        setBookingLoading(true);
        setError({ status: true, msg: "Payment failed", type: "error" });
        Swal.fire({
          title: "Error",
          text: "Payment failed",
          icon: "error",
        });
        // console.error("Payment failed:", response.error);
        // alert("Payment failed. Please try again.");

        // Handle payment failure
      }
    } catch (error) {
      // Error handling
      setBookingLoading(true);
      setError({ status: true, msg: "Payment failed", type: "error" });
      Swal.fire({
        title: "Error",
        text: "Payment failed",
        icon: "error",
      });
      // console.error("Payment failed:", response.error);
      // alert("Payment failed. Please try again.");
      // console.error("Error occurred while handling payment response:", error);
      // alert(
      //   "An error occurred while processing the payment. Please try again later."
      // );
      // Handle error gracefully
    }
  };

  const handleSavaariRazorpay = async (savaariBookingData) => {
    try {
      const loadScript = (url) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.src = url;
          script.onload = () => resolve(true);
          script.onerror = () => reject(false);
          document.body.appendChild(script);
        });
      };

      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("failed to load.");
        return;
      }

      const result = {
        data: { amount: 100, currency: "INR" },
      };

      const { amount, currency } = result.data;

      const options = {
        // key: "rzp_test_31XA5hDu81J8ii",
        key: "rzp_live_R0Cmoj4h6YMCzk", // Live Key
        amount: savaariVehiclePriceWithMarkup * 100,
        currency: "INR",
        name: "cars in africa",
        description: "cars in africa",
        image: "https://carsinafrica.com/assets/images/cia_logo.png",
        handler: (response) =>
          handleSavaariPaymentSuccess(response, savaariBookingData),
        prefill: {
          name: savaariBookingData.customerName,
          email: savaariBookingData.customerEmail,
          contact: savaariBookingData.customerMobile,
        },
        notes: {
          address: savaariBookingData.pickupAddress,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const handleSavaariSubmit = async (e) => {
    const prePayment =
      savaariVehicleDetail.rates?.discounted?.totalAmount ||
      savaariVehicleDetail.rates?.regular?.totalAmount;
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (subservice === "pick-airport") {
      var savaariBookingData = {
        sourceCity: savaariSource.cityId,
        tripType: savaariVehicleService,
        subTripType: savaariVehicleSubService,
        pickupDateTime: pdate + " " + ptime,
        duration: "1",
        pickupAddress: daddress,
        localityId: "",
        customerName: "Cars In Africa",
        customerEmail: "eswar.adaboina@satgurutravel.com",
        customerMobile: "9944339156",
        carType: carid,
        prePayment: prePayment,
        couponCode: "",
        destinationCity: savaariDestination.cityId,
        booking_owner: "cia",
        payment_id: null,
        source_address: data.get("source_address"),
        dest_address: data.get("dest_address"),
        journey_date: `${pdate} ${ptime}`,
        end_date: `${ddate} ${dtime}`,
        journey_time: data.get("journey_time"),
        user_id: userLoggedData.user_id,
        no_of_persons: data.get("no_of_persons"),
        note: data.get("note"),
      };
    } else if (subservice === "drop-airport") {
      var savaariBookingData = {
        sourceCity: savaariSource.cityId,
        tripType: savaariVehicleService,
        subTripType: savaariVehicleSubService,
        pickupDateTime: pdate + " " + ptime,
        duration: "1",
        pickupAddress: paddress,
        localityId: "",
        customerName: "Cars In Africa",
        customerEmail: "eswar.adaboina@satgurutravel.com",
        customerMobile: "9944339156",
        carType: carid,
        prePayment: prePayment,
        couponCode: "",
        destinationCity: savaariDestination.cityId,
        booking_owner: "cia",
        payment_id: null,
        source_address: data.get("source_address"),
        dest_address: data.get("dest_address"),
        journey_date: `${pdate} ${ptime}`,
        end_date: `${ddate} ${dtime}`,
        journey_time: data.get("journey_time"),
        user_id: userLoggedData.user_id,
        no_of_persons: data.get("no_of_persons"),
        note: data.get("note"),
      };
    } else {
      var savaariBookingData = {
        sourceCity: savaariSource.cityId,
        tripType: savaariVehicleService,
        subTripType: savaariVehicleSubService,
        pickupDateTime: pdate + " " + ptime,
        duration: "1",
        pickupAddress: paddress,
        localityId: "",
        customerName: "Cars In Africa",
        customerEmail: "eswar.adaboina@satgurutravel.com",
        customerMobile: "9944339156",
        carType: carid,
        prePayment: prePayment,
        couponCode: "",
        destinationCity:
          savaariDestination === undefined
            ? savaariSource.cityId
            : savaariDestination.cityId,
        booking_owner: "cia",
        payment_id: null,
        source_address: data.get("source_address"),
        dest_address: data.get("dest_address"),
        journey_date: `${pdate} ${ptime}`,
        end_date: `${ddate} ${dtime}`,
        journey_time: data.get("journey_time"),
        user_id: userLoggedData.user_id,
        no_of_persons: data.get("no_of_persons"),
        note: data.get("note"),
      };
    }
    await handleSavaariRazorpay(savaariBookingData);
  };

  // END - Savaari user Ride Booking

  // START -> CIA Vehicle Booking Loading
  if (bookingLoading === true) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // END -> CIA Vehicle Booking Loading

  // START -> CIA Vehicle Details Showing
  else if (vehicleLoading === false && vehicleResultFlag === true) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <div className="container full-width rounded bg-white">
          <div className="bg row d-flex justify-content-center pb-5">
            {/* START -> Car Location */}
            <div className="col-md-6">
              <div
                className="bg-white mt-4 p-3 d-flex flex-column"
                id="payment-section"
              >
                <h5 className="fw-bold me-2">CAR LOCATION</h5>
                <div className="d-flex align-items-center justify-content-end mb-align">
                  <br />
                  {/* <img
                    src={process.env.PUBLIC_URL + "/images/mdi_car-side.svg"}
                    className=""
                  /> */}
                </div>
                <div className="row custom-car-location-row">
                  <div className="col-md-4">
                    <div className="pl-2">
                      <p className="fw-bold">Pick up Location</p>
                      <p>
                        {bookingdetails.start_date} |{" "}
                        <span>{bookingdetails.start_time}</span>
                      </p>
                      <p className="fw-bold">
                        <i className="bi bi-geo-alt-fill"></i>{" "}
                        {bookingdetails.pickup_address}
                      </p>
                    </div>
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/fluent_arrow-swap-20-regular.svg"
                    }
                    className="custom-img-arrow-swap"
                  />
                  <div className="col-md-5">
                    <div className="pl-2">
                      <p className="fw-bold">Drop Location</p>
                      {(bookingdetails.drop_date &&
                        bookingdetails.drop_time) ===
                      (bookingdetails.start_date &&
                        bookingdetails.start_time) ? (
                        <div className=""></div>
                      ) : (
                        <p>
                          {bookingdetails.drop_date} |{" "}
                          <span>{bookingdetails.drop_time}</span>
                        </p>
                      )}
                      <p className="fw-bold">
                        <i className="bi bi-geo-alt-fill"></i>{" "}
                        {bookingdetails.dropoff_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* START -> Car Location */}

            {/* START -> Vehicles */}
            {vehicledetails.map((vehicleDetails, key) => (
              <div className="col-md-2" key={key}>
                <div
                  className="bg-white mt-4 p-3 d-flex flex-column vehicle-section"
                  id="payment-section"
                >
                  <div className="pt-2 mb-4 mb-vehicle-name">
                    <h5 className="fw-bold">
                      VEHICLES
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/mdi_car-side.svg"
                        }
                        className="custom-img-car-icon"
                      />
                    </h5>
                  </div>
                  <div className="pl-2">
                    <p className="fw-bold">
                      {vehicleDetails.make.charAt(0).toUpperCase() +
                        vehicleDetails.make.slice(1)}{" "}
                      {vehicleDetails.model.charAt(0).toUpperCase() +
                        vehicleDetails.model.slice(1)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {/* END -> Vehicles */}

            {/* START -> Review */}
            {vehicledetails.map((vehicleDetails, key) => (
              <div className="col-md-4">
                <div
                  className="bg-white mt-4 p-3 d-flex flex-column"
                  id="payment-section"
                >
                  <div className="pt-2 mb-4 mb-vehicle-name">
                    <h5 className="fw-bold">
                      CART TOTAL
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/ic_outline-rate-review.svg"
                        }
                        className="custom-img-car-icon"
                      />
                    </h5>
                  </div>
                  <div className="pl-2">
                    {vehicleDetails.currency === null ? (
                      <p className="fw-bold">
                        {decimalsToRound(parseFloat(vehicleDetails.base_fare))} USD/
                        {service === "transfer" ? "way" : "day"}
                      </p>
                    ) : (
                      <>
                        <p className="fw-bold">
                          {decimalsToRound(parseFloat(vehicleDetails.base_fare))} USD/
                          {service === "transfer" ? "way" : "day"}
                        </p>
                        <p className="fw-bold">
                          {decimalsToRound(parseFloat(vehicleDetails.currency_base_fare))}{" "}
                          {vehicleDetails.currency}/
                          {service === "transfer" ? "way" : "day"}
                        </p>
                      </>
                    )}

                    {service === "transfer" ? (
                      <p className="fw-bold">{decimalsToRound(parseFloat(vehicleDetails.base_fare))} USD</p>
                    ) : (
                      <p className="fw-bold">
                        {decimalsToRound(parseFloat(vehicleDetails.base_fare))} USD x{" "}
                        {vehicleDetails.numberOfDays} Days
                      </p>
                    )}

                    {couponAppliedFlag ? (
                      <p className="fw-bold">- {discountAmount} USD</p>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
            {/* END -> Review */}

            {/* START -> Review And Process */}
            {vehicledetails.map((vehicleDetails, key) => (
              <div className="row" key={key}>
                <div className="col-md-12">
                  <div
                    className="bg row mt-4 p-3 d-flex justify-content-center"
                    id="payment-section"
                  >
                    <div className="row">
                      <div className="col-md-10">
                        <div className="pt-2">
                          <h5 className="fw-bold">
                            Review And Proceed To Booking
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="pt-2">
                          <h5 className="fw-bold">
                            {decimalsToRound(parseFloat(vehicleDetails.total_fare - discountAmount))} USD
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* END -> Review And Process */}
            {vehicledetails.map((vehicleDetails, key) => (
              <div className="row" key={key}>
                <div className="col-md-8">
                  <div
                    className="bg row mt-4 p-3 d-flex justify-content-center"
                    id="payment-section"
                  >
                    <div className="row">
                      <div className="col-md-9">
                        <div className="pt-2">
                          <h5 className="fw-bold">Vehicle Details</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            src={`https://backend.carsinafrica.com/uploads/${vehicleDetails.vehicle_image}`}
                            className="img-fluid rounded-start"
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="pl-2">
                            <div className="car-name">
                              <p className="fw-bold fs-4">
                                {vehicleDetails.make.charAt(0).toUpperCase() +
                                  vehicleDetails.make.slice(1)}{" "}
                                {vehicleDetails.model.charAt(0).toUpperCase() +
                                  vehicleDetails.model.slice(1)}
                              </p>
                              <small>Or Similar</small>
                              <p></p>
                            </div>
                            <p className="fw-bold">
                              Car Type:{" "}
                              <span>{vehicleDetails.vehicletype}</span>
                            </p>
                            <p className="fw-bold fs-5">Features</p>
                            <div className="fw-bold">
                              <div className="d-inline-flex align-items-center">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/mdi_air-conditioning.svg"
                                  }
                                  alt="AC"
                                  className="mr-1"
                                />
                                AC
                              </div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/arcticonscar.svg"
                                  }
                                  alt="Sedan"
                                  className="mr-1"
                                />
                                {vehicleDetails.vehicletype}
                              </div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/mdi_petrol-pump-outline.svg"
                                  }
                                  alt="Petrol"
                                  className="mr-1"
                                />
                                {vehicleDetails.engine_type}
                              </div>
                              <div className="d-inline-flex align-items-center m-1">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/mdi_seat-passenger.svg"
                                  }
                                  alt="4 Seater"
                                  className="mr-1"
                                />
                                {vehicleDetails.seats} Seater
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {vehicledetails.map((vehicleDetails, key) => (
                    <div
                      className="bg row mt-4 p-3 d-flex justify-content-center"
                      id="payment-section"
                      key={key}
                    >
                      <h5 className="fw-bold pt-2 mb-3">More Information</h5>
                      {vehicleDetails.per_km_fare == 0 ? (
                        <li className="custom-li">
                          Allotted km: Unlimited km/day
                        </li>
                      ) : (
                        <li className="custom-li">
                          Allotted km: {decimalsToRound(parseFloat(vehicleDetails.alloted_kms))}km/day
                        </li>
                      )}
                      <div className=""></div>
                      {vehicleDetails.per_km_fare == 0 ? null : (
                        <li className="custom-li">
                          Extra km: ${decimalsToRound(parseFloat(vehicleDetails.per_km_fare))}/km
                        </li>
                      )}
                      <div className=""></div>
                      {vehicleDetails.alloted_hours == 0 ? null : (
                        <li className="custom-li">
                          Allotted Hours: {vehicleDetails.alloted_hours}{" "}
                          Hour/day
                        </li>
                      )}
                      <div className=""></div>
                      {vehicleDetails.per_hrs_fare == 0 ? null : (
                        <li className="custom-li">
                          Extra Hours: ${decimalsToRound(parseFloat(vehicleDetails.per_hrs_fare))}/Hour
                        </li>
                      )}
                    </div>
                  ))}
                  {termsAndCondition.map((termsnconditions, key) => (
                    <div
                      className="bg row mt-4 p-3 d-flex justify-content-center"
                      id="payment-section"
                      key={key}
                    >
                      <h5 className="fw-bold pt-2 mb-3">Terms & Conditions</h5>
                      <b>{termsnconditions.condition_1}</b>
                      <div className=""></div>
                      <b>{termsnconditions.condition_2}</b>
                      <div className=""></div>
                      <b>{termsnconditions.condition_3}</b>
                      <div className=""></div>
                      <b>{termsnconditions.condition_4}</b>
                      <div className=""></div>
                      <b>{termsnconditions.condition_5}</b>
                      <div className=""></div>
                    </div>
                  ))}
                </div>
                <div className="col-md-4 ">
                  <div
                    className="bg-white mt-4 p-3 d-flex flex-column"
                    id="payment-section"
                  >
                    {couponAppliedFlag ? (
                      <div className="rn-cart-coupon-form">
                        <h2 className="rn-icon-input mb-3">Coupon</h2>
                        <form onSubmit={handleCouponEdit}>
                          <input
                            type="text"
                            className="form-control custom-coupon-input"
                            placeholder="Coupon code"
                            defaultValue={coupon}
                            readOnly
                          />
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="Edit Coupon"
                          />
                        </form>
                      </div>
                    ) : (
                      <div className="rn-cart-coupon-form">
                        <h5 className="fw-bold pt-2 mb-3">Coupon Code</h5>
                        <form onSubmit={handleCouponSubmit}>
                          <input
                            type="text"
                            className="bi bi-tag-fill custom-input-coupon-code mb-2"
                            placeholder="Coupon code"
                            value={coupon}
                            onChange={handleCouponChange}
                          />
                          <input
                            className="btn btn-success"
                            type="submit"
                            value="Apply Coupon"
                          />
                        </form>
                      </div>
                    )}
                  </div>
                  <div
                    className="bg-white mt-4 p-3 d-flex flex-column booking-details"
                    id="payment-section"
                  >
                    <div className="rn-cart-total-form">
                      <h5 className="fw-bold pt-2 mb-4">Booking Details</h5>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="rn-icon-input mb-3">
                              <i className="fa fa-user" />
                              <input
                                type="text"
                                className="form-control custom-input-booking"
                                placeholder="First Name"
                                value={userLoggedData.user_name}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12" />
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="rn-icon-input mb-3">
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                              />
                              <input
                                type="text"
                                className="form-control custom-input-booking"
                                placeholder="Pickup Address"
                                id="source_address"
                                name="source_address"
                                defaultValue={bookingdetails.pickup_address}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="rn-icon-input mb-3">
                              <i
                                className="fa fa-location-arrow"
                                aria-hidden="true"
                              />
                              <input
                                type="text"
                                placeholder="Drop Address"
                                className="form-control custom-input-booking"
                                id="dest_address"
                                name="dest_address"
                                defaultValue={bookingdetails.dropoff_address}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="rn-icon-input mb-3">
                              <i
                                className="bi bi-calendar-check"
                                aria-hidden="true"
                              />
                              <input
                                type="text" // Use text input instead of date input
                                placeholder="Journey From Date"
                                className="form-control custom-input-booking"
                                id="journey_date"
                                name="journey_date"
                                value={bookingdetails.start_date} // Format the default date value
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="rn-icon-input mb-3">
                              <i
                                className="bi bi-calendar-check-fill"
                                aria-hidden="true"
                              />
                              <input
                                type="text"
                                placeholder="Journey To Date"
                                className="form-control custom-input-booking"
                                id="end_date"
                                name="end_date"
                                value={bookingdetails.drop_date}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="rn-icon-input mb-3">
                              <i className="bi bi-clock-fill" />
                              <input
                                type="time"
                                placeholder="Time"
                                id="journey_time"
                                className="form-control custom-input-booking"
                                name="journey_time"
                                defaultValue={bookingdetails.start_time}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-check mb-3 mr-3 d-flex align-items-center">
                              <Checkbox
                                size="large"
                                type="checkbox"
                                className="form-check-input"
                                id="hideFieldsCheckbox"
                                checked={hideFields}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label same-traveller-details"
                                htmlFor="hideFieldsCheckbox"
                              >
                                Same Traveller Details
                              </label>
                            </div>

                            <div
                              className={`rn-icon-input mb-3 ${
                                hideFields ? "d-none" : ""
                              }`}
                            >
                              <i className="fa fa-user" />
                              <input
                                type="text"
                                placeholder="Enter Traveller Name"
                                className="form-control custom-input-booking"
                                value={travellerName}
                                onChange={(e) =>
                                  setTravellerName(e.target.value)
                                }
                                required={!hideFields}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className={`rn-icon-input mb-3 ${
                                hideFields ? "d-none" : ""
                              }`}
                            >
                              <i className="bi bi-envelope-fill" />
                              <input
                                type="email"
                                name="g_email"
                                className="form-control custom-input-booking"
                                value={travellerEmail}
                                onChange={(e) =>
                                  setTravellerEmail(e.target.value)
                                }
                                placeholder="Enter Traveller Email"
                                required={!hideFields}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className={`rn-icon-input mb-3 ${
                                hideFields ? "d-none" : ""
                              }`}
                            >
                              <PhoneInput
                                country="IN"
                                defaultCountry="IN"
                                className="input-phone-traveller phone-length"
                                id="input-height"
                                placeholder="Enter Traveller Phone Number"
                                defaultValue={contact_number.g_phone}
                                onChange={setTravellerPhoneNumber}
                                onBlur={handleBlur}
                                required={!hideFields}
                              />
                            </div>
                          </div>
                        </div>
                        <Checkbox
                          required
                          size="large"
                          className="terms-condition-left"
                        >
                          <medium>
                            By proceeding to book, I Agree to CarsInAfrica{" "}
                            <Link
                              to="/privacy-policy"
                              className="custom-checkbox privacy-policy-link"
                            >
                              Privacy Policy
                            </Link>{" "}
                            and{" "}
                            <Link
                              to="/terms-and-conditions"
                              className="custom-checkbox privacy-policy-link"
                            >
                              Terms of Service.
                            </Link>
                          </medium>
                        </Checkbox>
                        <div>
                          <input
                            type="submit"
                            defaultValue="submit"
                            name="send"
                            id="send"
                            value="Book Now"
                            className="btn btn-primary btn-lg btn-shadow btn-block custom-submit-button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // END -> CIA Vehicle Details Showing

  // START -> CIA Vehicle Details Loading
  else if (vehicleLoading === true && vehicleResultFlag === false) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // END -> CIA Vehicle Details Loading

  // START -> Savaari Vehicle Details Showing
  else if (
    savaariVehicleLoading === false &&
    savaaariVehicleResultFlag === true
  ) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <div className="container full-width rounded bg-white">
          <div className="bg row d-flex justify-content-center pb-5">
            {/* START -> Car Location */}
            <div className="col-md-6">
              <div
                className="bg-white mt-4 p-3 d-flex flex-column"
                id="payment-section"
              >
                <h5 className="fw-bold me-2">CAR LOCATION</h5>
                <div className="d-flex align-items-center justify-content-end mb-align">
                  <br />
                  {/* <img
                    src={process.env.PUBLIC_URL + "/images/mdi_car-side.svg"}
                    className=""
                  /> */}
                </div>
                <div className="row custom-car-location-row">
                  <div className="col-md-4">
                    <div className="pl-2">
                      <p className="fw-bold">Pick up Location</p>
                      <p>
                        {pdate} | <span>{ptime}</span>
                      </p>
                      <p className="fw-bold">
                        <i className="bi bi-geo-alt-fill"></i> {paddress}
                      </p>
                    </div>
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/fluent_arrow-swap-20-regular.svg"
                    }
                    className="custom-img-arrow-swap"
                  />
                  <div className="col-md-5">
                    <div className="pl-2">
                      <p className="fw-bold">Drop Location</p>
                      {ddate === pdate && dtime === ptime ? (
                        <p>
                          <br />
                        </p>
                      ) : (
                        <p>
                          {ddate} | <span>{dtime}</span>
                        </p>
                      )}
                      <p className="fw-bold">
                        <i className="bi bi-geo-alt-fill"></i> {daddress}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* START -> Car Location */}

            {/* START -> Vehicles */}
            <div className="col-md-2">
              <div
                className="bg-white mt-4 p-3 d-flex flex-column vehicle-section"
                id="payment-section"
              >
                <div className="pt-2 mb-4 mb-vehicle-name">
                  <h5 className="fw-bold">
                    VEHICLES
                    <img
                      src={process.env.PUBLIC_URL + "/images/mdi_car-side.svg"}
                      className="custom-img-car-icon"
                    />
                  </h5>
                </div>
                <div className="pl-2">
                  <p className="fw-bold">{savaariVehicleDetail.carName}</p>
                </div>
              </div>
            </div>
            {/* END -> Vehicles */}

            {/* START -> Review */}
            <div className="col-md-4">
              <div
                className="bg-white mt-4 p-3 d-flex flex-column"
                id="payment-section"
              >
                <div className="pt-2 mb-4 mb-vehicle-name">
                  <h5 className="fw-bold">
                    CART TOTAL
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/ic_outline-rate-review.svg"
                      }
                      className="custom-img-car-icon"
                    />
                  </h5>
                </div>
                <div className="pl-2">
                  <p className="fw-bold">{savaariVehicleDetail.package}</p>
                  {savaariVehicleDetail.rates.discounted ? (
                    <p className="fw-bold">
                      {decimalsToRound(
                        parseFloat(
                          savaariVehicleDetail.rates.discounted.totalAmount
                        ) * savaariServiceTax
                      )}{" "}
                      INR
                    </p>
                  ) : (
                    <p className="fw-bold">
                      {decimalsToRound(
                        parseFloat(
                          savaariVehicleDetail.rates.regular.totalAmount
                        ) * savaariServiceTax
                      )}{" "}
                      INR
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* END -> Review */}

            {/* START -> Review And Process */}
            <div className="row">
              <div className="col-md-12">
                <div
                  className="bg row mt-4 p-3 d-flex justify-content-center"
                  id="payment-section"
                >
                  <div className="row">
                    <div className="col-md-10">
                      <div className="pt-2">
                        <h5 className="fw-bold">
                          Review And Proceed To Booking
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="pt-2">
                        {savaariVehicleDetail.rates.discounted ? (
                          <h5 className="fw-bold">
                            {decimalsToRound(
                              parseFloat(
                                savaariVehicleDetail.rates.discounted
                                  .totalAmount
                              ) * savaariServiceTax
                            )}{" "}
                            INR
                          </h5>
                        ) : (
                          <h5 className="fw-bold">
                            {decimalsToRound(
                              parseFloat(
                                savaariVehicleDetail.rates.regular.totalAmount
                              ) * savaariServiceTax
                            )}{" "}
                            INR
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END -> Review And Process */}
            <div className="row">
              <div className="col-md-8">
                <div
                  className="bg row mt-4 p-3 d-flex justify-content-center"
                  id="payment-section"
                >
                  <div className="row">
                    <div className="col-md-9">
                      <div className="pt-2">
                        <h5 className="fw-bold">Vehicle Details</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src={`${savaariVehicleDetail.carImageLarge}`}
                          className="img-fluid rounded-start"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="pl-2">
                          <div className="car-name">
                            <p className="fw-bold fs-4">
                              {savaariVehicleDetail.carName}
                            </p>
                            <p></p>
                          </div>
                          <p className="fw-bold">
                            Car Type:{" "}
                            <span>{savaariVehicleDetail.carNameAlias}</span>
                          </p>
                          <p className="fw-bold fs-5">Features</p>
                          <div className="fw-bold">
                            <div className="d-inline-flex align-items-center">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_air-conditioning.svg"
                                }
                                alt="AC"
                                className="mr-1"
                              />
                              {savaariVehicleDetail.carType}
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/arcticonscar.svg"
                                }
                                alt="Sedan"
                                className="mr-1"
                              />
                              {savaariVehicleDetail.carNameAlias}
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_petrol-pump-outline.svg"
                                }
                                alt="Petrol"
                                className="mr-1"
                              />
                              {savaariVehicleDetail.package}
                            </div>
                            <div className="d-inline-flex align-items-center m-1">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/mdi_seat-passenger.svg"
                                }
                                alt="4 Seater"
                                className="mr-1"
                              />
                              {savaariVehicleDetail.seatCapacity} Seater
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="bg row mt-4 p-3 d-flex justify-content-center"
                  id="payment-section"
                >
                  <h4 className="fw-bold pt-2 mb-3">More Information</h4>
                  <div className="pb-3">
                    <h5
                      className="fw-bold pt-2 mb-3"
                      style={{ color: "#F07B28" }}
                    >
                      Exclusions
                    </h5>
                    {savaariVehicleDetail.exclusions.map(
                      (vehiclesExclude, key) => (
                        <>
                          <b style={{ fontWeight: "600" }}>
                            {key + 1}.{" "}
                            {vehiclesExclude.text.replace(/\\u20B9/, "₹")}
                          </b>
                          <br />
                        </>
                      )
                    )}
                  </div>
                  <div>
                    <h5
                      className="fw-bold pt-2 mb-3"
                      style={{ color: "#F07B20" }}
                    >
                      Inclusions
                    </h5>
                    {savaariVehicleDetail.inclusions.map(
                      (vehiclesinclude, key) => (
                        <>
                          <b style={{ fontWeight: "600" }}>
                            {key + 1}.{" "}
                            {vehiclesinclude.text.replace(/\\u20B9/, "₹")}
                          </b>
                          <br />
                        </>
                      )
                    )}
                  </div>
                  <br />
                  <br />
                </div>
                <div
                  className="bg row mt-4 p-3 d-flex justify-content-center"
                  id="payment-section"
                >
                  <h4 className="fw-bold pt-2 mb-3">Terms & Conditions</h4>
                  {savaariVehicleDetail.tnc_data.map((vehicleDetails, key) => (
                    <b style={{ fontWeight: "600" }} className="pb-2" key={key}>
                      {key + 1}. {vehicleDetails}
                    </b>
                  ))}
                </div>
              </div>
              <div className="col-md-4 ">
                <div
                  className="bg-white mt-4 p-3 d-flex flex-column"
                  id="payment-section"
                >
                  {couponAppliedFlag ? (
                    <div className="rn-cart-coupon-form">
                      <h2 className="rn-icon-input mb-3">Coupon</h2>
                      <form onSubmit={handleCouponEdit}>
                        <input
                          type="text"
                          className="form-control custom-coupon-input"
                          placeholder="Coupon code"
                          defaultValue={coupon}
                          readOnly
                        />
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="Edit Coupon"
                        />
                      </form>
                    </div>
                  ) : (
                    <div className="rn-cart-coupon-form">
                      <h5 className="fw-bold pt-2 mb-3">Coupon Code</h5>
                      <form onSubmit={handleCouponSubmit}>
                        <input
                          type="text"
                          className="bi bi-tag-fill custom-input-coupon-code mb-2"
                          placeholder="Coupon code"
                          value={coupon}
                          onChange={handleCouponChange}
                        />
                        <input
                          className="btn btn-success"
                          type="submit"
                          value="Apply Coupon"
                        />
                      </form>
                    </div>
                  )}
                </div>
                <div
                  className="bg-white mt-4 p-3 d-flex flex-column booking-details"
                  id="payment-section"
                >
                  <div className="rn-cart-total-form">
                    <h5 className="fw-bold pt-2 mb-4">Booking Details</h5>
                    <form onSubmit={handleSavaariSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="rn-icon-input mb-3">
                            <i className="fa fa-user" />
                            <input
                              type="text"
                              className="form-control custom-input-booking"
                              placeholder="First Name"
                              value={userLoggedData.user_name}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12" />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="rn-icon-input mb-3">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />
                            <input
                              type="text"
                              className="form-control custom-input-booking"
                              placeholder="Pickup Address"
                              id="source_address"
                              name="source_address"
                              defaultValue={paddress}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="rn-icon-input mb-3">
                            <i
                              className="fa fa-location-arrow"
                              aria-hidden="true"
                            />
                            <input
                              type="text"
                              placeholder="Drop Address"
                              className="form-control custom-input-booking"
                              id="dest_address"
                              name="dest_address"
                              defaultValue={daddress}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="rn-icon-input mb-3">
                            <i
                              className="bi bi-calendar-check"
                              aria-hidden="true"
                            />
                            <input
                              type="text" // Use text input instead of date input
                              placeholder="Journey From Date"
                              className="form-control custom-input-booking"
                              id="journey_date"
                              name="journey_date"
                              value={pdate} // Format the default date value
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="rn-icon-input mb-3">
                            <i
                              className="bi bi-calendar-check-fill"
                              aria-hidden="true"
                            />
                            <input
                              type="text"
                              placeholder="Journey To Date"
                              className="form-control custom-input-booking"
                              id="end_date"
                              name="end_date"
                              value={ddate}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="rn-icon-input mb-3">
                            <i className="bi bi-clock-fill" />
                            <input
                              type="time"
                              placeholder="Time"
                              id="journey_time"
                              className="form-control custom-input-booking"
                              name="journey_time"
                              defaultValue={ptime}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-check mb-3 mr-3 d-flex align-items-center">
                            <Checkbox
                              size="large"
                              type="checkbox"
                              className="form-check-input click-checkbox"
                              id="hideFieldsCheckbox"
                              checked={hideFields}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label same-traveller-details"
                              htmlFor="hideFieldsCheckbox"
                            >
                              Same Traveller Details
                            </label>
                          </div>

                          <div
                            className={`rn-icon-input mb-3 ${
                              hideFields ? "d-none" : ""
                            }`}
                          >
                            <i className="fa fa-user" />
                            <input
                              type="text"
                              placeholder="Enter Traveller Name"
                              className="form-control custom-input-booking"
                              value={travellerName}
                              onChange={(e) => setTravellerName(e.target.value)}
                              required={!hideFields}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            className={`rn-icon-input mb-3 ${
                              hideFields ? "d-none" : ""
                            }`}
                          >
                            <i className="bi bi-envelope-fill" />
                            <input
                              type="email"
                              name="g_email"
                              className="form-control custom-input-booking"
                              value={travellerEmail}
                              onChange={(e) =>
                                setTravellerEmail(e.target.value)
                              }
                              placeholder="Enter Traveller Email"
                              required={!hideFields}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            className={`rn-icon-input mb-3 ${
                              hideFields ? "d-none" : ""
                            }`}
                          >
                            <PhoneInput
                              country="IN"
                              defaultCountry="IN"
                              className="input-phone-traveller phone-length"
                              id="input-height"
                              placeholder="Enter Traveller Phone Number"
                              defaultValue={contact_number.g_phone}
                              onChange={setTravellerPhoneNumber}
                              onBlur={handleBlur}
                              required={!hideFields}
                            />
                          </div>
                        </div>
                      </div>
                      <Checkbox
                        required
                        size="large"
                        className="terms-condition-left click-checkbox"
                      >
                        <medium>
                          By proceeding to book, I Agree to CarsInAfrica{" "}
                          <Link
                            to="/privacy-policy"
                            className="custom-checkbox privacy-policy-link"
                          >
                            Privacy Policy
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/terms-and-conditions"
                            className="custom-checkbox privacy-policy-link"
                          >
                            Terms of Service.
                          </Link>
                        </medium>
                      </Checkbox>
                      <div>
                        <input
                          type="submit"
                          defaultValue="submit"
                          name="send"
                          id="send"
                          value="Book Now"
                          className="btn btn-primary btn-lg btn-shadow btn-block custom-submit-button"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // END -> Savaari Vehicle Details Showing

  // START -> CIA Vehicle Not Found
  else if (vehicleLoading === false && vehicleResultFlag === false) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        {/* Car Results thorugh map*/}
        <Header />
        <div className="rn-section rn-car-search-results loader-container">
          <div>
            <div className=""></div>
            <div>
              <h1>Vehicles Not Available!</h1>
              <div className=""></div>
              <Link
                className="btn btn-success btn-lg btn-shadow custom-link-vehicle"
                to={`/`}
              >
                Go Home!
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
  // END -> CIA Vehicle Not Found
}
