import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
export default function CancellationPolicy() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("cancellation-policy");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/cancellation_policy.jpg"}
          className="img-fluid"
          alt="cancellation policy"
        />
        <div className="banner-overlay"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Cancellation Policy</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="shadow-sm p-3 mb-1 bg-body rounded">
                <li>Prior to 48 Hours - No Cancellation Charge</li>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="shadow-sm p-3 mb-1 bg-body rounded">
                <li>
                  Within 24-48 hours from rental time - No Cancellation Charge
                </li>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="shadow-sm p-3 mb-1 bg-body rounded">
                <li>Within 0 - 24 hours from rental time - 100%</li>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="shadow-sm p-3 mb-1 bg-body rounded">
                <li>No show - 100%</li>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      {/* <CancellationPolicyBanner />
            <CancellationPolicyContent /> */}
      <Footer />
    </>
  );
}
