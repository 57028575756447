import { DatePicker } from "antd";
import { Form, div, Input } from "reactstrap";
import React, {
  useState,
  useEffect,
  createRef,
  isSubscribed,
  handleChange,
  useLayoutEffect,
} from "react";
import {
  getToken,
  storeBooking,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import AutoComplete from "react-google-autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ReactFlagsSelect from "react-flags-select";
import "./Search-form.css";
import { useCookies } from "react-cookie";
import { useIsFocusVisible } from "@mui/material";
import Swal from "sweetalert2";
// START - search form
// END - search form

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}
// const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
//   console.log('onOk: ', value);
// };
const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

const Slider = () => {
  const [pickupNewDateTime, setPickupNewDateTime] = useState(null);
  const [dropNewDateTime, setDropNewDateTime] = useState(null);
  const [cookies] = useCookies(["countryiso"]);

  const searchInput = createRef(null);
  const [address, setAddress] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [userid, setUserid] = useState(0);
  const [select, setSelect] = useState(
    cookies.countryiso === undefined ? "" : cookies.countryiso
  );

  const onSelect = (code) => setSelect(code);
  const countryIso2 = [
    "AF",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BQ",
    "BA",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "BI",
    "CV",
    "KH",
    "CM",
    "CA",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CK",
    "CR",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "CI",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "SZ",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KP",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "MK",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
  ];

  const handleFlagSelect = (countryCode) => {
    setSelect(countryCode);

    const countryISO2Map = {
      AF: "af", // Afghanistan
      AX: "ax", // Åland Islands
      AL: "al", // Albania
      DZ: "dz", // Algeria
      AS: "as", // American Samoa
      AD: "ad", // Andorra
      AO: "ao", // Angola
      AI: "ai", // Anguilla
      AQ: "aq", // Antarctica
      AG: "ag", // Antigua and Barbuda
      AR: "ar", // Argentina
      AM: "am", // Armenia
      AW: "aw", // Aruba
      AU: "au", // Australia
      AT: "at", // Austria
      AZ: "az", // Azerbaijan
      BS: "bs", // Bahamas
      BH: "bh", // Bahrain
      BD: "bd", // Bangladesh
      BB: "bb", // Barbados
      BY: "by", // Belarus
      BE: "be", // Belgium
      BZ: "bz", // Belize
      BJ: "bj", // Benin
      BM: "bm", // Bermuda
      BT: "bt", // Bhutan
      BO: "bo", // Bolivia
      BQ: "bq", // Bonaire, Sint Eustatius and Saba
      BA: "ba", // Bosnia and Herzegovina
      BW: "bw", // Botswana
      BV: "bv", // Bouvet Island
      BR: "br", // Brazil
      IO: "io", // British Indian Ocean Territory
      BN: "bn", // Brunei Darussalam
      BG: "bg", // Bulgaria
      BF: "bf", // Burkina Faso
      BI: "bi", // Burundi
      CV: "cv", // Cabo Verde
      KH: "kh", // Cambodia
      CM: "cm", // Cameroon
      CA: "ca", // Canada
      KY: "ky", // Cayman Islands
      CF: "cf", // Central African Republic
      TD: "td", // Chad
      CL: "cl", // Chile
      CN: "cn", // China
      CX: "cx", // Christmas Island
      CC: "cc", // Cocos (Keeling) Islands
      CO: "co", // Colombia
      KM: "km", // Comoros
      CG: "cg", // Congo
      CD: "cd", // Democratic Republic of the Congo
      CK: "ck", // Cook Islands
      CR: "cr", // Costa Rica
      HR: "hr", // Croatia
      CU: "cu", // Cuba
      CW: "cw", // Curaçao
      CY: "cy", // Cyprus
      CZ: "cz", // Czech Republic
      CI: "ci", // Ivory Coast
      DK: "dk", // Denmark
      DJ: "dj", // Djibouti
      DM: "dm", // Dominica
      DO: "do", // Dominican Republic
      EC: "ec", // Ecuador
      EG: "eg", // Egypt
      SV: "sv", // El Salvador
      GQ: "gq", // Equatorial Guinea
      ER: "er", // Eritrea
      EE: "ee", // Estonia
      SZ: "sz", // Eswatini
      ET: "et", // Ethiopia
      FK: "fk", // Falkland Islands
      FO: "fo", // Faroe Islands
      FJ: "fj", // Fiji
      FI: "fi", // Finland
      FR: "fr", // France
      GF: "gf", // French Guiana
      PF: "pf", // French Polynesia
      TF: "tf", // French Southern Territories
      GA: "ga", // Gabon
      GM: "gm", // Gambia
      GE: "ge", // Georgia
      DE: "de", // Germany
      GH: "gh", // Ghana
      GI: "gi", // Gibraltar
      GR: "gr", // Greece
      GL: "gl", // Greenland
      GD: "gd", // Grenada
      GP: "gp", // Guadeloupe
      GU: "gu", // Guam
      GT: "gt", // Guatemala
      GG: "gg", // Guernsey
      GN: "gn", // Guinea
      GW: "gw", // Guinea-Bissau
      GY: "gy", // Guyana
      HT: "ht", // Haiti
      HM: "hm", // Heard Island and McDonald Islands
      VA: "va", // Holy See
      HN: "hn", // Honduras
      HK: "hk", // Hong Kong
      HU: "hu", // Hungary
      IS: "is", // Iceland
      IN: "in", // India
      ID: "id", // Indonesia
      IR: "ir", // Iran
      IQ: "iq", // Iraq
      IE: "ie", // Ireland
      IM: "im", // Isle of Man
      IL: "il", // Israel
      IT: "it", // Italy
      JM: "jm", // Jamaica
      JP: "jp", // Japan
      JE: "je", // Jersey
      JO: "jo", // Jordan
      KZ: "kz", // Kazakhstan
      KE: "ke", // Kenya
      KI: "ki", // Kiribati
      KP: "kp", // North Korea
      KR: "kr", // South Korea
      KW: "kw", // Kuwait
      KG: "kg", // Kyrgyzstan
      LA: "la", // Laos
      LV: "lv", // Latvia
      LB: "lb", // Lebanon
      LS: "ls", // Lesotho
      LR: "lr", // Liberia
      LY: "ly", // Libya
      LI: "li", // Liechtenstein
      LT: "lt", // Lithuania
      LU: "lu", // Luxembourg
      MO: "mo", // Macao
      MG: "mg", // Madagascar
      MW: "mw", // Malawi
      MY: "my", // Malaysia
      MV: "mv", // Maldives
      ML: "ml", // Mali
      MT: "mt", // Malta
      MH: "mh", // Marshall Islands
      MQ: "mq", // Martinique
      MR: "mr", // Mauritania
      MU: "mu", // Mauritius
      YT: "yt", // Mayotte
      MX: "mx", // Mexico
      FM: "fm", // Micronesia
      MD: "md", // Moldova
      MC: "mc", // Monaco
      MN: "mn", // Mongolia
      ME: "me", // Montenegro
      MS: "ms", // Montserrat
      MA: "ma", // Morocco
      MZ: "mz", // Mozambique
      MM: "mm", // Myanmar
      NA: "na", // Namibia
      NR: "nr", // Nauru
      NP: "np", // Nepal
      NL: "nl", // Netherlands
      NC: "nc", // New Caledonia
      NZ: "nz", // New Zealand
      NI: "ni", // Nicaragua
      NE: "ne", // Niger
      NG: "ng", // Nigeria
      NU: "nu", // Niue
      NF: "nf", // Norfolk Island
      MK: "mk", // North Macedonia
      MP: "mp", // Northern Mariana Islands
      NO: "no", // Norway
      OM: "om", // Oman
      PK: "pk", // Pakistan
      PW: "pw", // Palau
      PS: "ps", // Palestine
      PA: "pa", // Panama
      PG: "pg", // Papua New Guinea
      PY: "py", // Paraguay
      PE: "pe", // Peru
      PH: "ph", // Philippines
      PN: "pn", // Pitcairn
      PL: "pl", // Poland
      PT: "pt", // Portugal
      PR: "pr", // Puerto Rico
      QA: "qa", // Qatar
      RE: "re", // Réunion
      RO: "ro", // Romania
      RU: "ru", // Russia
      RW: "rw", // Rwanda
      BL: "bl", // Saint Barthélemy
      SH: "sh", // Saint Helena
      KN: "kn", // Saint Kitts and Nevis
      LC: "lc", // Saint Lucia
      MF: "mf", // Saint Martin
      PM: "pm", // Saint Pierre and Miquelon
      VC: "vc", // Saint Vincent and the Grenadines
      WS: "ws", // Samoa
      SM: "sm", // San Marino
      ST: "st", // São Tomé and Príncipe
      SA: "sa", // Saudi Arabia
      SN: "sn", // Senegal
      RS: "rs", // Serbia
      SC: "sc", // Seychelles
      SL: "sl", // Sierra Leone
      SG: "sg", // Singapore
      SX: "sx", // Sint Maarten
      SK: "sk", // Slovakia
      SI: "si", // Slovenia
      SB: "sb", // Solomon Islands
      SO: "so", // Somalia
      ZA: "za", // South Africa
      GS: "gs", // South Georgia
      SS: "ss", // South Sudan
      ES: "es", // Spain
      LK: "lk", // Sri Lanka
      SD: "sd", // Sudan
      SR: "sr", // Suriname
      SJ: "sj", // Svalbard and Jan Mayen
      SE: "se", // Sweden
      CH: "ch", // Switzerland
      SY: "sy", // Syria
      TW: "tw", // Taiwan
      TJ: "tj", // Tajikistan
      TZ: "tz", // Tanzania
      TH: "th", // Thailand
      TL: "tl", // Timor-Leste
      TG: "tg", // Togo
      TK: "tk", // Tokelau
      TO: "to", // Tonga
      TT: "tt", // Trinidad and Tobago
      TN: "tn", // Tunisia
      TR: "tr", // Turkey
      TM: "tm", // Turkmenistan
      TC: "tc", // Turks and Caicos Islands
      TV: "tv", // Tuvalu
      UG: "ug", // Uganda
      UA: "ua", // Ukraine
      AE: "ae", // United Arab Emirates
      GB: "gb", // United Kingdom
      US: "us", // United States
      UM: "um", // United States Minor Outlying Islands
      UY: "uy", // Uruguay
      UZ: "uz", // Uzbekistan
      VU: "vu", // Vanuatu
      VE: "ve", // Venezuela
      VN: "vn", // Vietnam
      VG: "vg", // Virgin Islands (British)
      VI: "vi", // Virgin Islands (U.S.)
      WF: "wf", // Wallis and Futuna
      EH: "eh", // Western Sahara
      YE: "ye", // Yemen
      ZM: "zm", // Zambia
      ZW: "zw", // Zimbabwe
    };

    const iso2Code = countryISO2Map[countryCode];
    const redirectURL = iso2Code ? `#` : "#";

    window.location.href = redirectURL;
  };

  useEffect(() => {
    const userLoggedData = getToken();

    if (userLoggedData.user_id !== null) {
      setUserid(userLoggedData.user_id);
    }
  }, []);
  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };
  const [showFields, setShowFields] = useState(false);

  const handleAddField = () => {
    setShowFields(true);
  };
  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // calculate the distance between two places.
  function calculateAirportTransferDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance;
  }

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);

    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );

    // Add event listener for input changes
    searchInput.current.addEventListener("input", handleInput);

    // Handle initial suggestions
    handleInput();
  };

  // Handle input changes and fetch suggestions
  const handleInput = () => {
    if (!searchInput.current) return;

    const input = searchInput.current.value;

    // Use AutocompleteService to fetch predictions
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      {
        input,
        types: ["(regions)"], // Restrict to regions (cities and countries)
        componentRestrictions: { country: "US" }, // Replace with your desired country code
      },
      (predictions) => {
        const transformedSuggestions = predictions.map((prediction) => {
          const { description, structured_formatting } = prediction;
          const { main_text, secondary_text } = structured_formatting;
          const [placeName, cityName, countryName] = [
            main_text,
            secondary_text,
            description,
          ];
          return {
            placeName,
            cityName,
            countryName,
            description,
          };
        });
        setSuggestions(transformedSuggestions);
      }
    );
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  {
    /* START - Search Console */
  }

  const [transferType, setTransferType] = useState("null");

  {
    /* START - Pickup Use-State */
  }
  const [paddress, setPAddress] = useState("");
  const [pcity, setPCity] = useState("");
  const [pstate, setPState] = useState("");
  const [pcountry, setPCountry] = useState("");
  const [pcountryIso, setPCountryIso] = useState("");
  const [pcoordinates, setPCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [daddress, setDAddress] = useState("");
  const [dcity, setDCity] = useState("");
  const [dstate, setDState] = useState("");
  const [dcountry, setDCountry] = useState("");
  const [dcountryIso, setDCountryIso] = useState("");
  const [dcoordinates, setDCoordinates] = useState({
    lat: null,
    lng: null,
  });
  {
    /* END - Drop Use-State */
  }

  const [date, setDate] = useState([]);
  const [time, setTime] = useState("");

  const [pdate, setPDate] = useState("");
  const [ptime, setPTime] = useState("");

  const [ddate, setDDate] = useState("");
  const [dtime, setDTime] = useState("");

  const [value, onChange] = useState("10:00");
  const [distance, setDistance] = useState(0);
  const [activeTab, setActiveTab] = useState("local");

  // START - date and time handler
  const [currentDateHandler, setCurrentDateHandler] = useState(false);
  const [currentDropDateHandler, setCurrentDropDateHandler] = useState(false);
  // END - date and time handler

  {
    /* END - Search Console */
  }

  const handlePickupSelection = (place) => {
    // Extract details from the place object
    const city =
      place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name || "null";
    const address = place.formatted_address || "null";
    const country =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.long_name || "null";
    const state =
      place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name || "null";
    const countryIso =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.short_name || "null";
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    setPAddress(address);
    setPCoordinates({ lat: latLng.lat, lng: latLng.lng });
    setPCity(city);
    setPState(state);
    setPCountry(country);
    setPCountryIso(countryIso);
  };

  const handleDropSelection = (place) => {
    // Extract details from the place object
    const city =
      place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name || "null";
    const address = place.formatted_address || "null";
    const country =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.long_name || "null";
    const state =
      place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name || "null";
    const countryIso =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.short_name || "null";
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    setDAddress(address);
    setDCoordinates({ lat: latLng.lat, lng: latLng.lng });
    setDCity(city);
    setDState(state);
    setDCountry(country);
    setDCountryIso(countryIso);
  };

  const format = "HH:mm";

  const { RangePicker } = DatePicker;

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };

  const disabledDropDate = (current) => {
    const pickupDate = dayjs(pdate, "DD-MM-YYYY"); // Convert pdate to dayjs object
    // Can not select days before pickupDate
    return current && current.isBefore(pickupDate, "day");
  };

  const disabledAfterTimeDropDate = (current) => {
    const pickupDate = dayjs(pdate, "DD-MM-YYYY"); // Convert pdate to dayjs object
    const minSelectableDate = pickupDate.add(1, "day"); // pickupDate + 1 day

    // Can not select days before minSelectableDate
    return current && current.isBefore(minSelectableDate, "day");
  };

  // END - Search form from Google

  const [currentTime, setCurrentTime] = useState(new Date());

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [backgroundColor, setBackgroundColor] = useState("");
  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [isSelfDriveCheckboxChecked, setIsSelfDriveCheckboxChecked] =
    useState(false);

  const handleSelfDriveCheckboxChange = () => {
    setIsSelfDriveCheckboxChecked(!isSelfDriveCheckboxChecked);
    setBackgroundColor(isSelfDriveCheckboxChecked ? "#FFFFFF" : "#FF0000"); // Toggle background color
  };

  const handleLocalDisposal = () => {
    if (pcountryIso == "") {
      alert("Please Fill Pickup Address");
    } else if (paddress && pdate && ptime && ddate && dtime) {
      const url = `/vehicles/local/fullday/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
      window.location.href = url;
    } else {
      alert("Please Fill All Fields");
    }
  };

  const handleLocalFullDay = () => {
    if (pcountryIso == "") {
      alert("Please Fill Pickup Address");
    } else if (paddress && pdate && ptime && ddate && dtime) {
      const url = `/vehicles/local/fullday/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;

      window.location.href = url;
    } else {
      alert("Please Fill All Fields");
    }
  };

  const handleLocalHalfDay = () => {
    if (pcountryIso == "") {
      alert("Please Fill Pickup Address");
    } else if (paddress && pdate && ptime && ddate && dtime) {
      const url = `/vehicles/local/halfday/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;

      window.location.href = url;
    } else {
      alert("Please Fill All Fields");
    }
  };

  const handleOutStation = () => {
    if (pcountryIso === "") {
      alert("Please Fill Pickup Address");
    } else if (dcountryIso === "") {
      alert("Please Fill Drop Address");
    } else if (paddress && daddress && pdate && ptime && ddate && dtime) {
      const url = `/vehicles/outstation/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;

      window.location.href = url;
    } else {
      alert("Please Fill All Fields");
    }
  };

  const handleTransfer = () => {
    const transferDistance = calculateAirportTransferDistance(
      pcoordinates.lat,
      pcoordinates.lng,
      dcoordinates.lat,
      dcoordinates.lng
    );

    if (
      (transferType === "pick-airport" || transferType === "drop-airport") &&
      paddress &&
      daddress &&
      pdate &&
      ptime
    ) {
      if (ddate && dtime) {
        if (pcity === dcity) {
          if (transferType === "pick-airport") {
            const url = `/vehicles/transfer/${transferType}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
            window.location.href = url;
          } else {
            const url = `/vehicles/transfer/${transferType}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
            window.location.href = url;
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Your service will now be switched to Outstation.",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            customClass: {
              confirmButton: "swal-confirm-button",
              cancelButton: "swal-cancel-button",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              if (transferType === "pick-airport") {
                const url = `/vehicles/outstation/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
                window.location.href = url;
              } else {
                const url = `/vehicles/outstation/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
                window.location.href = url;
              }
            }
          });
        }
      } else {
        if (pcity === dcity) {
          if (transferType === "pick-airport") {
            const url = `/vehicles/transfer/${transferType}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${pdate}/${ptime}`;
            window.location.href = url;
          } else {
            const url = `/vehicles/transfer/${transferType}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${pdate}/${ptime}`;
            window.location.href = url;
          }
        } else if (transferDistance < 50) {
          if (transferType === "pick-airport") {
            const url = `/vehicles/transfer/${transferType}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${pdate}/${ptime}`;
            window.location.href = url;
          } else {
            const url = `/vehicles/transfer/${transferType}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${pdate}/${ptime}`;
            window.location.href = url;
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Your service will now be switched to Outstation.",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            customClass: {
              confirmButton: "swal-confirm-button",
              cancelButton: "swal-cancel-button",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              if (transferType === "pick-airport") {
                const url = `/vehicles/outstation/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${pdate}/${ptime}`;
                window.location.href = url;
              } else {
                const url = `/vehicles/outstation/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${pdate}/${ptime}`;
                window.location.href = url;
              }
            }
          });
        }
      }
    } else {
      alert("Please Fill All Fields");
    }
  };

  const handleSelfDrive = () => {
    if (
      isSelfDriveCheckboxChecked &&
      paddress &&
      pdate &&
      ptime &&
      ddate &&
      dtime
    ) {
      if (pcountryIso == "") {
        alert("Please Fill Pickup Address");
      } else {
        const url = `/vehicles/selfdrive/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
        window.location.href = url;
      }
    } else if (paddress && daddress && pdate && ptime && ddate && dtime) {
      if (pcountryIso == "") {
        alert("Please Fill Pickup Address");
      } else if (dcountryIso == "") {
        alert("Please Fill Drop Address");
      } else {
        const url = `/vehicles/selfdrive/${paddress}/${pcity}/${pstate}/${pcountry}/${pcountryIso}/${pcoordinates.lat}/${pcoordinates.lng}/${userid}/${daddress}/${dcity}/${dstate}/${dcountry}/${dcountryIso}/${dcoordinates.lat}/${dcoordinates.lng}/${pdate}/${ptime}/${ddate}/${dtime}`;
        window.location.href = url;
      }
    } else {
      alert("Please Fill All Fields");
    }
  };

  // new implemention for date and time picker
  const [openTransferPickupDatePicker, setOpenTransferPickupDatePicker] =
    useState(false);
  const [openTransferDropDatePicker, setOpenTransferDropDatePicker] =
    useState(false);

  const [
    openLocalHalfDayPickupDatePicker,
    setOpenLocalHalfDayPickupDatePicker,
  ] = useState(false);

  const [openLocalHalfDayDropDatePicker, setOpenLocalHalfDayDropDatePicker] =
    useState(false);

  const [
    openLocalFullDayPickupDatePicker,
    setOpenLocalFullDayPickupDatePicker,
  ] = useState(false);

  const [openLocalFullDayDropDatePicker, setOpenLocalFullDayDropDatePicker] =
    useState(false);

  const [openOutStationPickupDatePicker, setOpenOutStationPickupDatePicker] =
    useState(false);
  const [openOutStationDropDatePicker, setOpenOutStationDropDatePicker] =
    useState(false);

  const [openSelfDrivePickupDatePicker, setOpenSelfDrivePickupDatePicker] =
    useState(false);
  const [openSelfDriveDropDatePicker, setOpenSelfDriveDropDatePicker] =
    useState(false);

  const handleTransferPickupInputClick = () => {
    setOpenTransferPickupDatePicker(true);
  };

  const handleTransferDropInputClick = () => {
    setOpenTransferDropDatePicker(true);
  };

  const handleLocalHalfDayPickupInputClick = () => {
    setOpenLocalHalfDayPickupDatePicker(true);
  };

  const handleLocalHalfDayDropInputClick = () => {
    setOpenLocalHalfDayDropDatePicker(true);
  };

  const handleLocalFullDayPickupInputClick = () => {
    setOpenLocalFullDayPickupDatePicker(true);
  };

  const handleLocalFullDayDropInputClick = () => {
    setOpenLocalFullDayDropDatePicker(true);
  };

  const handleOutStationPickupInputClick = () => {
    setOpenOutStationPickupDatePicker(true);
  };

  const handleOutStationDropInputClick = () => {
    setOpenOutStationDropDatePicker(true);
  };

  const handleSelfDrivePickupInputClick = () => {
    setOpenSelfDrivePickupDatePicker(true);
  };

  const handleSelfDriveDropInputClick = () => {
    setOpenSelfDriveDropDatePicker(true);
  };

  const locales = "en-gb";

  const handlePickupDateTimeChange = (date) => {
    // Handle pickup date change if needed
    setPickupNewDateTime(date);

    // Extract date and time components
    const formattedDate = date.format("DD-MM-YYYY");
    const formattedTime = date.format("HH:mm");

    // Set state variables
    setPDate(formattedDate);
    setPTime(formattedTime);
  };

  const handleDropDateTimeChange = (date) => {
    // Handle drop date change if needed
    setDropNewDateTime(date);
    // Extract date and time components
    const formattedDate = date.format("DD-MM-YYYY");
    const formattedTime = date.format("HH:mm");

    // Set state variables
    setDDate(formattedDate);
    setDTime(formattedTime);
  };

  const shouldDisableTime = (value, view) => {
    // Disable the next 4 hours from the current time
    if (view === "hours") {
      const currentTime = dayjs();
      const selectedTime = dayjs(value);
      const timeDifferenceInHours = selectedTime.diff(currentTime, "hours");
      if (select === "IN") {
        return timeDifferenceInHours < 6;
      } else {
        return timeDifferenceInHours < 4;
      }
    }
    return false;
  };

  const shouldDropDisableTime = (value, view) => {
    if (pickupNewDateTime) {
      // If pickup date is selected, disable the time options before the pickup time + 2 hours
      if (view === "hours") {
        const pickupTime = dayjs(pickupNewDateTime);
        const adjustedEndTime = pickupTime.add(2, "hours");
        const selectedTime = dayjs(value);
        return selectedTime.isBefore(adjustedEndTime, "hour");
      }
    }
    return false;
  };

  const shouldDropDisableDate = (day) => {
    if (pickupNewDateTime) {
      // Disable dates before the selected pickup date
      const pickupDate = dayjs(pickupNewDateTime).startOf("day");
      return dayjs(day).isBefore(pickupDate, "day");
    }
    return false;
  };
  const [selectedPlace, setSelectedPlace] = useState(null);

  const clearSelectedPlace = () => {
    setSelectedPlace(null);
  };
  const [halfDayClicked, setHalfDayClicked] = useState(false);

  const handleHalfDayClick = () => {
    setHalfDayClicked(!halfDayClicked);
  };
  return (
    <>
      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="./images/banner-image2.webp"
              className="d-block w-100"
              alt="Slider 1"
            />
            <div className="position-absolute top-0 start-0 w-100 h-100 bg-opacity-80"></div>
          </div>
          <div className="carousel-item">
            <img
              src="./images/banner-image.webp"
              className="d-block w-100"
              alt="Slider 2"
            />
            <div className="position-absolute top-0 start-0 w-100 h-100 bg-opacity-80"></div>
          </div>
        </div>
        <div className="container">
          <div className="carousel-caption text-center">
            <h1 className="display-3 fw-bold">Search & Find Cars</h1>
            <p>For business For leisure.</p>
            <div className="container">
              <div className="row">
                <div className="wrapper" id="wrapper-padding">
                  <div className="tabs">
                    {/* START - Transfer */}
                    <input
                      type="radio"
                      name="tabs"
                      id="tabthree"
                      defaultChecked
                    />
                    <label htmlFor="tabthree">
                      <i className="bi bi-car-front-fill"></i> Airport Transfer
                    </label>
                    <div className="tab">
                      <Form className="form">
                        <div className="row row-1">
                          <div className="col-lg-2 col-xl-2 col-sm-6 form__group">
                            <ReactFlagsSelect
                              selected={select}
                              onSelect={handleFlagSelect}
                              countries={countryIso2}
                              searchable
                            />
                          </div>
                          <div className="col-lg-2 col-xl-2 col-sm-6 form__group">
                            <div className="rn-icon-input">
                              <div>
                                <select
                                  className="custom-select-dropdown"
                                  value={transferType}
                                  onChange={(e) =>
                                    setTransferType(e.target.value)
                                  }
                                  required
                                >
                                  <option value="" id="select-trip">
                                    Select Trip
                                  </option>
                                  <option value="pick-airport">
                                    Pick from Airport
                                  </option>
                                  <option value="drop-airport">
                                    Drop To Airport
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {transferType === "pick-airport" ? (
                            <>
                              <div className="col-lg-3 col-xl-3 col-sm-6 form__group">
                                <div className="rn-icon-input">
                                  <AutoComplete
                                    placeholder="Pickup Airport"
                                    className="pickup_transfer"
                                    options={{
                                      types: ["airport"],
                                      componentRestrictions: {
                                        country: select,
                                      },
                                    }}
                                    apiKey={apiKey}
                                    onPlaceSelected={(place) =>
                                      handlePickupSelection(place)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-xl-3 col-sm-6 form__group margin-left-1">
                                <div className="rn-icon-input">
                                  <AutoComplete
                                    placeholder="Drop Address"
                                    className="drop_transfer"
                                    options={{
                                      types: ["establishment"],
                                      componentRestrictions: {
                                        country: select,
                                      },
                                    }}
                                    apiKey={apiKey}
                                    onPlaceSelected={(place) =>
                                      handleDropSelection(place)
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-lg-3 col-xl-3 col-sm-6 form__group">
                                <div className="rn-icon-input">
                                  <AutoComplete
                                    placeholder="Drop Airport"
                                    className="drop_airport"
                                    options={{
                                      types: ["airport"],
                                      componentRestrictions: {
                                        country: select,
                                      },
                                    }}
                                    apiKey={apiKey}
                                    onPlaceSelected={(place) =>
                                      handleDropSelection(place)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-xl-3 col-sm-6 form__group margin-left-1">
                                <div className="rn-icon-input">
                                  <AutoComplete
                                    placeholder="Pickup Address"
                                    className="pickup_address"
                                    options={{
                                      types: ["establishment"],
                                      componentRestrictions: {
                                        country: select,
                                      },
                                    }}
                                    apiKey={apiKey}
                                    onPlaceSelected={(place) =>
                                      handlePickupSelection(place)
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row custom-at">
                          <div className="col-lg-4 col-md-2 col-sm-12 form__group ">
                            <div className="form-control">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locales}
                              >
                                <DateTimePicker
                                  open={openTransferPickupDatePicker} // Control opening/closing based on state
                                  onClose={() =>
                                    setOpenTransferPickupDatePicker(false)
                                  }
                                  ampm={false}
                                  disablePast={true}
                                  onChange={handlePickupDateTimeChange}
                                  shouldDisableTime={shouldDisableTime}
                                  disablePointerEvents={true}
                                  showToolbar
                                  showTodayButton
                                  format="DD/MM/YYYY HH:mm"
                                  slotProps={{
                                    textField: {
                                      placeholder: "Pickup Date & Time",
                                      onClick: handleTransferPickupInputClick,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          {/* 
                          <div className="col-lg-4 col-md-2 col-sm-12 form__group">
                            <div className="form-control">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locales}
                              >
                                <DateTimePicker
                                  open={openTransferDropDatePicker} // Control opening/closing based on state
                                  onClose={() =>
                                    setOpenTransferDropDatePicker(false)
                                  }
                                  ampm={false}
                                  disablePast={true}
                                  onChange={handleDropDateTimeChange}
                                  shouldDisableTime={shouldDropDisableTime}
                                  shouldDisableDate={shouldDropDisableDate}
                                  format="DD/MM/YYYY HH:mm"
                                  slotProps={{
                                    textField: {
                                      placeholder: "Drop Date & Time",
                                      onClick: handleTransferDropInputClick,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div> */}

                          <div className="col-lg-2 col-md-2 col-md-2 ml-4">
                            <button
                              type="button"
                              className="btn btn-primary btn-lg custom-background custom-no-border"
                              id="find-car"
                              onClick={handleTransfer}
                            >
                              Find Car
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    {/* END - Transfer */}

                    {/* START - Local */}
                    <input type="radio" name="tabs" id="tabone" />
                    <label htmlFor="tabone">
                      <i className="bi bi-house-fill"></i> Local
                    </label>
                    <div className="tab nav-item" id="localTab" role="tabpanel">
                      <div className="tab-content">
                        <div className="row">
                          <div className="sub-tabs">
                            {/* START -> Full Day Search Form */}
                            <input
                              type="radio"
                              name="subtabs"
                              id="subtabfulltrip"
                              defaultChecked={true}
                            />
                            <label
                              htmlFor="subtabfulltrip"
                              className="local-full-day"
                            >
                              <i className="btn-search-subcategory"></i>
                              {select === "IN" ? "12 Hrs / 120 Km" : "Full Day"}
                            </label>
                            <div
                              className="tab nav-item"
                              id="subtabfulltrip"
                              role="tablist"
                            >
                              <Form className="form">
                                <div className="row">
                                  <div className="col-lg-2 col-xl-2 col-sm-12 form__group">
                                    <ReactFlagsSelect
                                      selected={select}
                                      onSelect={handleFlagSelect}
                                      countries={countryIso2}
                                      searchable
                                    />
                                  </div>
                                  <div className="col-lg-4 col-sm-12 col-md-4 form__group">
                                    <div className="rn-icon-input">
                                      <div>
                                        <AutoComplete
                                          placeholder="Pickup Location"
                                          className="pickup_local"
                                          options={{
                                            types: ["establishment"],
                                            componentRestrictions: {
                                              country: select,
                                            },
                                          }}
                                          apiKey={apiKey}
                                          onPlaceSelected={(place) => {
                                            handlePickupSelection(place);
                                            setSelectedPlace(place);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 form__group">
                                    <div className="form-control">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={locales}
                                      >
                                        <DateTimePicker
                                          open={
                                            openLocalFullDayPickupDatePicker
                                          } // Control opening/closing based on state
                                          onClose={() =>
                                            setOpenLocalFullDayPickupDatePicker(
                                              false
                                            )
                                          }
                                          ampm={false}
                                          disablePast={true}
                                          onChange={handlePickupDateTimeChange}
                                          shouldDisableTime={shouldDisableTime}
                                          showToolbar
                                          showTodayButton
                                          format="DD/MM/YYYY HH:mm"
                                          slotProps={{
                                            textField: {
                                              placeholder: "Pickup Date & Time",
                                              onClick:
                                                handleLocalFullDayPickupInputClick,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 form__group">
                                    <div className="form-control">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={locales}
                                      >
                                        <DateTimePicker
                                          open={openLocalFullDayDropDatePicker} // Control opening/closing based on state
                                          onClose={() =>
                                            setOpenLocalFullDayDropDatePicker(
                                              false
                                            )
                                          }
                                          ampm={false}
                                          disablePast={true}
                                          onChange={handleDropDateTimeChange}
                                          shouldDisableTime={
                                            shouldDropDisableTime
                                          }
                                          shouldDisableDate={
                                            shouldDropDisableDate
                                          }
                                          format="DD/MM/YYYY HH:mm"
                                          slotProps={{
                                            textField: {
                                              placeholder: "Drop Date & Time",
                                              onClick:
                                                handleLocalFullDayDropInputClick,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <div className="col-md-2 mt-3">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-lg custom-background custom-no-border"
                                      id="find-car"
                                      onClick={handleLocalFullDay}
                                    >
                                      Find Car
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                            {/* END - Full Day Search From */}

                            {/* START -> Half Day Search Form */}
                            <input
                              type="radio"
                              name="subtabs"
                              id="subtabhalftrip"
                              onChange={handleHalfDayClick}
                            />
                            <label
                              htmlFor="subtabhalftrip"
                              className={
                                halfDayClicked
                                  ? "local-half-day clicked"
                                  : "local-half-day"
                              }
                            >
                              <i className="btn-search-subcategory"></i>{" "}
                              {select === "IN" ? "8 Hrs / 80 Km" : "Half Day"}
                            </label>
                            <div
                              className="tab nav-item"
                              id="subtabhalftrip"
                              role="tablist"
                            >
                              <Form className="form">
                                <div className="row">
                                  <div className="col-lg-2 col-xl-2 col-sm-6 form__group">
                                    <ReactFlagsSelect
                                      selected={select}
                                      onSelect={handleFlagSelect}
                                      countries={countryIso2}
                                      searchable
                                    />
                                  </div>
                                  <div className="col-lg-4 col-sm-12 col-md-4 form__group">
                                    <div className="rn-icon-input">
                                      <AutoComplete
                                        placeholder="Pickup Location"
                                        className="pickup_local"
                                        options={{
                                          types: ["establishment"],
                                          componentRestrictions: {
                                            country: select,
                                          },
                                        }}
                                        apiKey={apiKey}
                                        onPlaceSelected={(place) =>
                                          handlePickupSelection(place)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 form__group">
                                    <div className="form-control">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={locales}
                                      >
                                        <DateTimePicker
                                          open={
                                            openLocalHalfDayPickupDatePicker
                                          } // Control opening/closing based on state
                                          onClose={() =>
                                            setOpenLocalHalfDayPickupDatePicker(
                                              false
                                            )
                                          }
                                          ampm={false}
                                          disablePast={true}
                                          onChange={handlePickupDateTimeChange}
                                          shouldDisableTime={shouldDisableTime}
                                          showToolbar
                                          showTodayButton
                                          format="DD/MM/YYYY HH:mm"
                                          slotProps={{
                                            textField: {
                                              placeholder: "Pickup Date & Time",
                                              onClick:
                                                handleLocalHalfDayPickupInputClick,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 form__group">
                                    <div className="form-control">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={locales}
                                      >
                                        <DateTimePicker
                                          open={openLocalHalfDayDropDatePicker}
                                          onClose={() =>
                                            setOpenLocalHalfDayDropDatePicker(
                                              false
                                            )
                                          }
                                          ampm={false}
                                          disablePast={true}
                                          onChange={handleDropDateTimeChange}
                                          shouldDisableTime={
                                            shouldDropDisableTime
                                          }
                                          shouldDisableDate={
                                            shouldDropDisableDate
                                          }
                                          format="DD/MM/YYYY HH:mm"
                                          slotProps={{
                                            textField: {
                                              placeholder: "Drop Date & Time",
                                              onClick:
                                                handleLocalHalfDayDropInputClick,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <div className="col-md-2 mt-3">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-lg custom-background custom-no-border"
                                      id="find-car"
                                      onClick={handleLocalHalfDay}
                                    >
                                      Find Car
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                            {/* END - Half Day Search From */}
                          </div>
                          {/* Transfer Sub Tabs */}
                          {activeTab === "transfer" && (
                            <div className="tabs-content">
                              {/* Content for Transfer Sub Tabs */}
                            </div>
                          )}

                          {/* Self Drive Sub Tabs */}
                          {activeTab === "self-drive" && (
                            <div className="tabs-content">
                              {/* Content for Self Drive Sub Tabs */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* END - Local */}

                    {/* START - Out Station Service */}
                    <input type="radio" name="tabs" id="tabtwo" />
                    <label htmlFor="tabtwo">
                      <i className="bi bi-box-fill"></i> Intercity
                    </label>
                    <div className="tab nav-item" id="myTab" role="tablist">
                      <Form className="form">
                        <div className="row">
                          <div className="col-lg-2 col-xl-2 col-sm-6 form__group">
                            <ReactFlagsSelect
                              selected={select}
                              onSelect={handleFlagSelect}
                              countries={countryIso2}
                              searchable
                            />
                          </div>
                          <div className="col-lg-3 col-sm-12 col-md-3 form__group">
                            <div className="rn-icon-input">
                              <AutoComplete
                                placeholder="Pickup Location"
                                className="pickup_intercity"
                                options={{
                                  types: ["establishment"],
                                  componentRestrictions: {
                                    country: select,
                                  },
                                }}
                                apiKey={apiKey}
                                onPlaceSelected={(place) =>
                                  handlePickupSelection(place)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-12 col-md-3 form__group">
                            <div className="rn-icon-input">
                              <AutoComplete
                                placeholder="Drop Location"
                                className="drop_intercity"
                                options={{
                                  types: ["establishment"],
                                  componentRestrictions: {
                                    country: select,
                                  },
                                }}
                                apiKey={apiKey}
                                onPlaceSelected={(place) =>
                                  handleDropSelection(place)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-3 col-sm-12 form__group">
                            <div className="form-control">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locales}
                              >
                                <DateTimePicker
                                  open={openOutStationPickupDatePicker} // Control opening/closing based on state
                                  onClose={() =>
                                    setOpenOutStationPickupDatePicker(false)
                                  }
                                  ampm={false}
                                  disablePast={true}
                                  onChange={handlePickupDateTimeChange}
                                  shouldDisableTime={shouldDisableTime}
                                  showToolbar
                                  showTodayButton
                                  format="DD/MM/YYYY HH:mm"
                                  slotProps={{
                                    textField: {
                                      placeholder: "Pickup Date & Time",
                                      onClick: handleOutStationPickupInputClick,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-3 col-sm-12 form__group custom-at">
                            <div className="form-control">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locales}
                              >
                                <DateTimePicker
                                  open={openOutStationDropDatePicker} // Control opening/closing based on state
                                  onClose={() =>
                                    setOpenOutStationDropDatePicker(false)
                                  }
                                  ampm={false}
                                  disablePast={true}
                                  onChange={handleDropDateTimeChange}
                                  shouldDisableTime={shouldDropDisableTime}
                                  shouldDisableDate={shouldDropDisableDate}
                                  format="DD/MM/YYYY HH:mm"
                                  slotProps={{
                                    textField: {
                                      placeholder: "Drop Date & Time",
                                      onClick: handleOutStationDropInputClick,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 form__group pt-3">
                            <button
                              type="button"
                              className="btn btn-primary btn-lg custom-background custom-no-border"
                              id="find-car"
                              onClick={handleOutStation}
                            >
                              Find Car
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    {/* END - Out Station Service */}

                    {/* START - Self Drive Service */}
                    <input type="radio" name="tabs" id="tabfour" />
                    <label htmlFor="tabfour">
                      <i className="bi bi-ev-front-fill"></i> Self Drive
                    </label>
                    <div className="tab">
                      <Form className="form">
                        <div className="row">
                          <div className="col-lg-2 col-xl-2 col-sm-6 form__group">
                            <ReactFlagsSelect
                              selected={select}
                              onSelect={handleFlagSelect}
                              countries={countryIso2}
                              searchable
                            />
                          </div>
                          {isSelfDriveCheckboxChecked === false ? (
                            <>
                              <div className="col-lg-3 col-sm-6 col-md-3 form__group ">
                                <AutoComplete
                                  placeholder="Pickup Location"
                                  className="custom-input"
                                  options={{
                                    types: ["establishment"],
                                    componentRestrictions: {
                                      country: select,
                                    },
                                  }}
                                  apiKey={apiKey}
                                  onPlaceSelected={(place) =>
                                    handlePickupSelection(place)
                                  }
                                />
                              </div>
                              <div className="col-lg-3 col-sm-6 col-md-3 form__group">
                                <div className="rn-icon-input">
                                  <AutoComplete
                                    placeholder="Drop Location"
                                    className="custom-input"
                                    options={{
                                      types: ["establishment"],
                                      componentRestrictions: {
                                        country: select,
                                      },
                                    }}
                                    apiKey={apiKey}
                                    onPlaceSelected={(place) =>
                                      handleDropSelection(place)
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-lg-3 col-xl-3 col-sm-4 col-md-3 form__group">
                              <AutoComplete
                                placeholder="Pickup Location"
                                className="custom-input"
                                options={{
                                  types: ["establishment"],
                                  componentRestrictions: {
                                    country: select,
                                  },
                                }}
                                apiKey={apiKey}
                                onPlaceSelected={(place) =>
                                  handlePickupSelection(place)
                                }
                              />
                            </div>
                          )}

                          <div className="col-lg-4 col-md-2 col-sm-12 form__group">
                            <div className="form-control">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locales}
                              >
                                <DateTimePicker
                                  open={openSelfDrivePickupDatePicker} // Control opening/closing based on state
                                  onClose={() =>
                                    setOpenSelfDrivePickupDatePicker(false)
                                  }
                                  ampm={false}
                                  disablePast={true}
                                  onChange={handlePickupDateTimeChange}
                                  shouldDisableTime={shouldDisableTime}
                                  showToolbar
                                  showTodayButton
                                  format="DD/MM/YYYY HH:mm"
                                  slotProps={{
                                    textField: {
                                      placeholder: "Pickup Date & Time",
                                      onClick: handleSelfDrivePickupInputClick,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div
                            className={`col-lg-4 col-md-2 col-sm-2 form__group self-drive-padding ${
                              isSelfDriveCheckboxChecked ? "pt-3" : "pt-3"
                            }`}
                          >
                            <div className="form-control">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locales}
                              >
                                <DateTimePicker
                                  open={openSelfDriveDropDatePicker} // Control opening/closing based on state
                                  onClose={() =>
                                    setOpenSelfDriveDropDatePicker(false)
                                  }
                                  ampm={false}
                                  disablePast={true}
                                  onChange={handleDropDateTimeChange}
                                  shouldDisableTime={shouldDropDisableTime}
                                  shouldDisableDate={shouldDropDisableDate}
                                  format="DD/MM/YYYY HH:mm"
                                  slotProps={{
                                    textField: {
                                      placeholder: "Drop Date & Time",
                                      onClick: handleSelfDriveDropInputClick,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 form__group pt-3">
                            <button
                              type="button"
                              className="btn btn-primary btn-lg custom-background custom-no-border"
                              id="find-car"
                              onClick={handleSelfDrive}
                            >
                              Find Car
                            </button>
                          </div>
                          <div className="col-lg-12 col-md-12 form__group">
                            <div className="custom-flex custom-margin-top-5 mb-checkbox">
                              <input
                                type="checkbox"
                                className="self-drive-checkbox"
                                style={{ backgroundColor }}
                                checked={isSelfDriveCheckboxChecked}
                                onChange={handleSelfDriveCheckboxChange}
                                id="pickupLocation"
                                name="pickupLocation"
                              />
                              <span
                                className="custom-margin-left-5"
                                id="pickup-Location"
                              >
                                Same as pickup location
                              </span>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </Form>
                    </div>
                    {/* END - Self Drive Service */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Slider;
