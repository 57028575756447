/*
@copyright

Cars In Africa v0.5

Copyright (C) 2023 Satguru Travel And Tourism Pvt Ltd <https://satgurutravel.com/> All rights reserved.
Design and developed by Satguru Travel And Tourism Pvt Ltd <https://satgurutravel.com/>

 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const userAuthApi = createApi({
  reducerPath: 'userAuthApi',
  baseQuery: fetchBaseQuery({ baseUrl: `https://backend.carsinafrica.com/` }),
  endpoints: (builder) => {
    if (!builder) {
      console.error('Error: API builder is undefined.');
      return;
    }

    return {
      registerUser: builder.mutation({
        query: (user) => {
          if (!user) {
            return;
          }
          return {
            url: 'frontend/user-register',
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
              'Content-type': 'application/json',
              "accept": "application/json",
            },
          };
        },
      }),
      loginUser: builder.mutation({
        query: (user) => {
          if (!user) {
            return;
          }
          return {
            url: 'frontend/user-login',
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
            },
          };
        },
      }),
      loginUserBB: builder.mutation({
        query: (user) => {
          if (!user) {
            return;
          }
          return {
            url: 'frontend/user-login-bb',
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
            },
          };
        },
      }),
      loginUserCC: builder.mutation({
        query: (user) => {
          if (!user) {
            return;
          }
          return {
            url: 'frontend/user-login-cc',
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
            },
          };
        },
      }),
      leaseLoginUser: builder.mutation({
        query: (user) => {
          if (!user) {
            return;
          }
          return {
            url: 'frontend/lease_login',
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
            },
          };
        },
      }),
      logoutUser: builder.mutation({
        query: ({ api_token }) => {
          if (!api_token) {
            return;
          }
          return {
            url: 'api/user-logout',
            method: 'POST',
            body: {},
            headers: {
              'authorization': `Bearer ${api_token}`,
              "Content-Type": "application/json",
              "accept": "application/json",
            },
          };
        },
      }),
      booking: builder.mutation({
        query: (data) => {
          if (!data) {
            return;
          }
          return {
            url: 'frontend/booking',
            method: 'POST',
            body: data,
            headers: {
              // "Content-Type": "application/json",
              // "accept": "application/json",
            },
          };
        },
      }),

      leaseBooking: builder.mutation({
        query: (data) => {
          if (!data) {
            return;
          }
          return {
            url: 'frontend/storeLeaseBookings',
            method: 'POST',
            body: data,
            headers: {
              // "Content-Type": "application/json",
              // "accept": "application/json",
            },
          };
        },
      }),
      savaariBooking: builder.mutation({
        query: (data) => {
          if (!data) {
            return;
          }
          return {
            url: 'frontend/savaari-booking',
            method: 'POST',
            body: data,
            headers: {
              // "Content-Type": "application/json",
              // "accept": "application/json",
            },
          };
        },
      }),
      changeUserPassword: builder.mutation({
        query: ({ actualData, token }) => {
          if (!actualData || !token) {
            return;
          }
          return {
            url: '/forgot-password',
            method: 'POST',
            body: actualData,
            headers: {
              'authorization': `Bearer ${token}`,
            },
          };
        },
      }),
      sendPasswordResetEmail: builder.mutation({
        query: (user) => {
          if (!user) {
            return;
          }
          return {
            url: 'send-reset-password-email',
            method: 'POST',
            body: user,
            headers: {
              'Content-type': 'application/json',
            },
          };
        },
      }),
      resetPassword: builder.mutation({

        query: ({ actualData, token }) => {
          if (!actualData || !token) {
            return;
          }

          return {
            url: '/forgot-password',
            method: 'POST',
            body: actualData,
            headers: {
              'authorization': `Bearer ${token}`,
            },
          };
        },
      }),
    }
  }
});

export const { useRegisterUserMutation, useBookingMutation, useLeaseBookingMutation, useSavaariBookingMutation, useLoginUserMutation, useLoginUserBBMutation, useLoginUserCCMutation, useLeaseLoginUserMutation , useLogoutUserMutation, useGetLoggedUserQuery } = userAuthApi
