/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  getToken,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import { useLoginUserMutation } from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";
import { setUserToken } from "../../services/Apis/CarsInAfrica/Auth/features/authSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import "./Dashboard.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function UserProfile() {
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState(null);
  const [isbookingHistoryLoading, setIsbookingHistoryLoading] = useState(true);

  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useEffect(() => {
    // Make an API Meta Tag request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=dashboard&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);

  const itemsPerPage = 6; // Number of items to display per page
  const [userbooking, setUserBooking] = useState({
    date: "",
    from: "",
    to: "",
    payment: "",
    status: "",
  });

  const [bookingHistory, setBookinghistory] = useState([]);
  const [cancelBookingId, setCancelBookingId] = useState(null);
  const [cancelBookingDetailToggle, setCancelBookingDetailToggle] =
    useState(false);
  const [cancelBookingMainId, setCancelBookingMainId] = useState(null);
  const [cancelBookingOwner, setCancelBookingOwner] = useState(null);
  const [cancellationReason, setCancellationReason] = useState("");
  const [viewBookingDetailToggle, setViewBookingDetailToggle] = useState(false);
  const [viewBookingDetail, setViewBookingDetail] = useState({});
  const [viewInvoiceBookingDetail, setViewInvoiceBookingDetail] = useState([]);
  const [viewBookingId, setViewBookingId] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [userProgressPercentage, setUserProgressPercentage] = useState(0);
  const [reasonError, setReasonError] = useState("");

  const handleDownload = async (bookingId) => {
    try {
      // Create an iframe to load the PDF content
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      // Set the source of the iframe to the PDF URL
      iframe.src = `https://backend.carsinafrica.com/download/${bookingId}`;

      // Wait for the iframe to load the PDF
      await new Promise((resolve) => {
        iframe.onload = resolve;
      });

      // Access the iframe's document and trigger a print operation
      iframe.contentWindow.print();

      // Clean up by removing the iframe after the print operation
      document.body.removeChild(iframe);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const userLoggedData = getToken();

  const handleLogout = async () => {
    removeToken(userLoggedData);
    window.location.href = "/user-login";
  };

  function calculateProfileCompletion(profileObject) {
    let totalAttributes = Object.keys(profileObject).length;
    let trueAttributes = Object.values(profileObject).filter(
      (value) => value === true
    ).length;

    let completionPercentage = (trueAttributes / totalAttributes) * 100;
    return completionPercentage;
  }

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-details?id=${userLoggedData.user_id}`,
          {
            responseType: "json",
          }
        );
        setUserDetails(response.data.data);
      } catch (error) {
        console.error(error);
        navigate("/");
        window.location.reload();
      }
    };

    const fetchUserProgressData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-progressBar?id=${userLoggedData.user_id}`,
          {
            responseType: "json",
          }
        );
        if (response.data.status === "Success") {
          const profileData = response.data.data;
          const completionPercentage = calculateProfileCompletion(
            response.data.data
          );
          setUserProgressPercentage(Math.round(completionPercentage));
        } else {
          navigate("/");
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
        navigate("/");
        window.location.reload();
      }
    };

    fetchData();
    fetchUserProgressData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/fuser_booking_history/${userLoggedData.user_id}`,
          {
            responseType: "json",
          }
        );
        setBookinghistory(response.data);
        const completedRides = response.data.filter(
          (ride) => ride.receipt === 1
        );
        setViewInvoiceBookingDetail(completedRides);
        setIsbookingHistoryLoading(false);
      } catch (error) {
        console.error("this", error);
        setIsbookingHistoryLoading(false);
        navigate("/");
        window.location.reload();
      }
    };
    fetchData();
  }, []);

  // START -> Handle Cancel Booking

  // Function to toggle the display of booking details
  const handleCancellationReason = (event) => {
    const value = event.target.value;
    setCancellationReason(value);
  };

  const cancelBookingDetails = ({bookingId, booking_owner, id}) => {
    setCancelBookingDetailToggle(true);
    setCancelBookingOwner(booking_owner);
    setCancelBookingId(id);
    setCancelBookingMainId(bookingId);
  };

  const handleCloseCancelBookingDetails = () => {
    setCancelBookingDetailToggle(false);
  };

  const viewBookingDetails = (bookingId, owner) => {
    setViewBookingId(bookingId === cancelBookingId ? null : bookingId);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/booking-details?id=${bookingId}&user_id=${userLoggedData.user_id}&owner=${owner}`,
          {
            responseType: "json",
          }
        );
        setViewBookingDetail(response.data.data);
        setViewBookingDetailToggle(true);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCloseViewBookingDetails = () => {
    setViewBookingDetailToggle(false);
  };

  const actualCancellationData = {
    booking_id: cancelBookingMainId,
    user_id: userLoggedData.user_id,
    other: cancellationReason === "" ? "cancelled" : cancellationReason,
    id: cancelBookingId,
  };

  const handleCancelBooking = async (event) => {
    event.preventDefault();

    if (cancelBookingOwner === "cia") {
      try {
        // Make the API request

        const response = await axios.post(
          "https://backend.carsinafrica.com/frontend/cancel-booking",
          actualCancellationData
        );

        if (response.data.status === "Failed") {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            window.location.href = `/dashboard`;
          });
        }
      } catch (error) {
        // Handle any other errors (e.g., network issues or server errors)
        console.error("An error occurred:", error);
        // Optionally, show an error message to the user
        Swal.fire({
          title: "Error",
          text: "An error occurred while processing your request",
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          window.location.href = `/dashboard`;
        });
      }
    } else {
      const savaariParams = new URLSearchParams();
      savaariParams.append("bookingId", actualCancellationData.booking_id);
      savaariParams.append("cancellationReason", "Changed my mind");

      try {
        // Make the API request

        const response = await axios.post(
          "https://api.betasavaari.com/partner_api/public/booking/cancel",
          savaariParams
        );

        if (response.data.status === "failure") {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
          });
        } else {
          const response = await axios.post(
            "https://backend.carsinafrica.com/frontend/cancel-booking",
            actualCancellationData
          );

          if (response.data.status === "Failed") {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then(() => {
              window.location.href = `/dashboard`;
            });
          }
        }
      } catch (error) {
        // Handle any other errors (e.g., network issues or server errors)
        console.error("An error occurred:", error);
        // Optionally, show an error message to the user
        Swal.fire({
          title: "Error",
          text: "An error occurred while processing your request",
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          window.location.href = `/dashboard`;
        });
      }
    }
  };

  // const handleCancelBooking = async (event) => {
  //   event.preventDefault();

  //   try {
  //     // Make the API request
  //     const response = await axios.post(
  //       "https://backend.carsinafrica.com/frontend/cancel-booking",
  //       actualCancellationData
  //     );

  //     if (response.data.status === "Failed") {
  //       Swal.fire({
  //         title: "Error",
  //         text: response.data.message,
  //         icon: "error",
  //       });
  //     } else {
  //       Swal.fire({
  //         title: "Success",
  //         text: response.data.message,
  //         icon: "success",
  //         customClass: {
  //           confirmButton: "custom-confirm-button-class",
  //         },
  //       }).then(() => {
  //         window.location.href = `/dashboard`;
  //       });
  //     }
  //   } catch (error) {
  //     // Handle any other errors (e.g., network issues or server errors)
  //     console.error("An error occurred:", error);
  //     // Optionally, show an error message to the user
  //     Swal.fire({
  //       title: "Error",
  //       text: "An error occurred while processing your request",
  //       icon: "error",
  //       customClass: {
  //         confirmButton: "custom-confirm-button-class",
  //       },
  //     }).then(() => {
  //       window.location.href = `/dashboard`;
  //     });
  //   }
  // };

  // END -> Handle Cancel Booking
  // Todays Date
  const now = new Date();
  const todaysDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  );

  // Create a new Date object
  const currentDate = new Date();

  // Get the year, month, and day
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const currentDay = String(currentDate.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  // START -> Filtered bookings finding and saving

  const upcomingBookingHistory = bookingHistory.filter((booking) => {
    const journeyDateParts = booking.journey_date.split("-"); // Split the date string
    const year = parseInt(journeyDateParts[2]);
    const month = parseInt(journeyDateParts[1]) - 1; // Months are 0-based
    const day = parseInt(journeyDateParts[0]);
    const journeyDate = new Date(year, month, day);
    return journeyDate > todaysDate && booking.ride_status !== "Cancelled";
  });

  const cancelledBookingHistory = bookingHistory.filter((booking) => {
    return booking.ride_status === "Cancelled";
  });

  const activeBookingHistory = bookingHistory.filter((booking) => {
    const journeyDateParts = booking.journey_date.split("-"); // Split the date string
    const year = parseInt(journeyDateParts[2]);
    const month = parseInt(journeyDateParts[1]) - 1; // Months are 0-based
    const day = parseInt(journeyDateParts[0]);
    const journeyDate = new Date(year, month, day);
    // Convert Date objects to strings for comparison
    const journeyDateString = journeyDate.toDateString();
    const todaysDateString = todaysDate.toDateString();

    // Compare the date strings
    return (
      journeyDateString === todaysDateString &&
      booking.ride_status !== "Cancelled"
    );
  });

  const oldBookingHistory = bookingHistory;

  // END -> Filtered bookings finding and saving

  // START -> Pagonation For Upcoming Bookings
  const [currentUpcomingBookingsPage, setCurrentUpcomingBookingsPage] =
    useState(1);

  const handlePageUpcomingBookingsChange = (newPage) => {
    setCurrentUpcomingBookingsPage(newPage);
  };

  // Calculate the start and end indices for the current page
  const startUpcomingBookingsIndex =
    (currentUpcomingBookingsPage - 1) * itemsPerPage;
  const endUpcomingBookingsIndex = startUpcomingBookingsIndex + itemsPerPage;

  // Slice the data for the current page
  const currentUpcomingBookingsData = upcomingBookingHistory.slice(
    startUpcomingBookingsIndex,
    endUpcomingBookingsIndex
  );

  // END -> Pagonation For Upcoming Bookings

  // START -> Pagonation For Booking History
  const [currentBookingsHistoryPage, setBookingsHistoryPage] = useState(1);

  const handlePageBookingsHistoryChange = (newPage) => {
    setBookingsHistoryPage(newPage);
  };

  // Calculate the start and end indices for the current page
  const startBookingsHistoryIndex =
    (currentBookingsHistoryPage - 1) * itemsPerPage;
  const endBookingsHistoryIndex = startBookingsHistoryIndex + itemsPerPage;

  // Slice the data for the current page
  const currentBookingsHistoryData = bookingHistory.slice(
    startBookingsHistoryIndex,
    endBookingsHistoryIndex
  );

  // END -> Pagonation For Booking History

  // START -> Pagonation For Active Bookings

  const [currentActiveBookingsPage, setCurrentActiveBookingsPage] = useState(1);

  const handlePageActiveBookingsChange = (newPage) => {
    setCurrentActiveBookingsPage(newPage);
  };

  // Calculate the start and end indices for the current page
  const startActiveBookingsIndex =
    (currentActiveBookingsPage - 1) * itemsPerPage;
  const endActiveBookingsIndex = startActiveBookingsIndex + itemsPerPage;

  // Slice the data for the current page
  const currentActiveBookingsData = activeBookingHistory.slice(
    startActiveBookingsIndex,
    endActiveBookingsIndex
  );

  // END -> Pagonation For Active Bookings

  // START -> Pagonation For Cancelled Bookings

  const [cancelledBookingsPage, setCancelledBookingsPage] = useState(1);

  const handlePageCancelledBookingsChange = (newPage) => {
    setCancelledBookingsPage(newPage);
  };

  // Calculate the start and end indices for the current page
  const startCancelledBookingsIndex =
    (cancelledBookingsPage - 1) * itemsPerPage;
  const endCancelledBookingsIndex = startCancelledBookingsIndex + itemsPerPage;

  // Slice the data for the current page
  const currentCancelledBookingsData = cancelledBookingHistory.slice(
    startCancelledBookingsIndex,
    endCancelledBookingsIndex
  );

  // END -> Pagonation For Cancelled Bookings

  // START -> Pagonation For Invoice Section Bookings

  const [currentInvoiceBookingsPage, setCurrentInvoiceBookingsPage] =
    useState(1);

  const handlePageInvoiceBookingsChange = (newPage) => {
    setCurrentInvoiceBookingsPage(newPage);
  };

  // Calculate the start and end indices for the current page
  const startInvoiceBookingsIndex =
    (currentInvoiceBookingsPage - 1) * itemsPerPage;
  const endInvoiceBookingsIndex = startInvoiceBookingsIndex + itemsPerPage;

  // Slice the data for the current page
  const currentInvoiceBookingsData = viewInvoiceBookingDetail.slice(
    startInvoiceBookingsIndex,
    endInvoiceBookingsIndex
  );

  function formatTime(time24) {
    const [hours, minutes] = time24.split(":");
    const date = new Date(2023, 0, 1, hours, minutes); // January 1, 2023 (arbitrary date)
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
  const [activeTab, setActiveTab] = useState("active-bookings");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const activeClass = "active-tab";
  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant", // Scroll instantly without smooth scrolling
    });
  }
  // END -> Pagonation For Invoice Section Bookings

  if (cancelBookingDetailToggle === true) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <div className="full-width">
          <section className="signup pt-5 mb-5 mb-dashboard-padding">
            <div className="sign_up cancel-booking-width">
              <div className="signup-content">
                <div className="signup-form">
                  <h2 className="form-title fw-bold">Cancel Booking</h2>
                  <form className="register-form" id="register-form">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="booking-id"
                        name="booking-id"
                        size="large"
                        value={
                          cancelBookingOwner === "savaari"
                            ? cancelBookingMainId
                            : cancelBookingId
                        }
                        placeholder="Booking ID"
                        prefix={
                          <CalendarOutlined className="site-form-item-icon" />
                        }
                        className="fw-bold"
                      />
                    </div>
                    <div className="form-group">
                      <Input.TextArea
                        type="text"
                        id="cancel-reason"
                        name="reason"
                        size="large"
                        placeholder="Enter your cancel reason"
                        onChange={handleCancellationReason}
                        required
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        className="custom-input w-100"
                      />
                      {reasonError && (
                        <span style={{ color: "red" }}>{reasonError}</span>
                      )}
                    </div>
                    <div className="row d-flex">
                      <div className="col-md-3">
                        <div className="form-group btn-cancel-booking">
                          <input
                            type="submit"
                            className="btn btn-success"
                            value="Submit"
                            onClick={handleCancelBooking}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-close-align">
                          <input
                            type="button"
                            className="btn btn-success"
                            value="Close"
                            onClick={handleCloseCancelBookingDetails}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="signup-image">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/partner_login.jpg`}
                    alt="Example"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  } 
  else if (viewBookingDetailToggle === true) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        {viewBookingDetail.booking_owner === "savaari" ? (
          <div className="container full-width rounded bg-white mb-dashboard-padding">
            <div className="bg row d-flex justify-content-center mb-5 mt-5">
              <div className="row pt-2 pb-2" id="payment-section">
                <div className="col-md-9">
                  <div className="pt-2"></div>
                </div>
                <div className="col-md-3" />
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src={`${viewBookingDetail.vehicle_image}`}
                      className="card-img-top"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="pl-2">
                      <p className="fw-bold fs-4 mb-active-booking">
                        {viewBookingDetail.car_name}
                      </p>

                      <p className="fw-bold mb-4">
                        Service :{" "}
                        <span>{viewBookingDetail.booking_service_type}</span>
                      </p>

                      <div className="fw-bold mb-4">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="/images/mdi_air-conditioning.svg"
                            alt="AC"
                            className="mr-1"
                          />
                          AC
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/arcticonscar.svg"
                            alt="Sedan"
                            className="mr-1"
                          />
                          &nbsp;
                          {viewBookingDetail.carType}
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/mdi_petrol-pump-outline.svg"
                            alt="Petrol"
                            className="mr-1"
                          />
                          {viewBookingDetail.vehicle_engine_type}
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/mdi_seat-passenger.svg"
                            alt="4 Seater"
                            className="mr-1"
                          />
                          {viewBookingDetail.vehicle_seats}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="pl-2">
                            <p className="fw-bold">Pick up</p>
                            <p>
                              {viewBookingDetail.pickup} |{" "}
                              {viewBookingDetail.pickupTime}
                            </p>
                            <p className="fw-bold">
                              <i className="bi bi-geo-alt-fill" />{" "}
                              {viewBookingDetail.pickup_addr}
                            </p>
                          </div>
                        </div>
                        {viewBookingDetail.dest_addr === null ? null : (
                          <>
                            {" "}
                            <img
                              src="./images/fluent_arrow-swap-20-regular.svg"
                              className="custom-img"
                            />
                            <div className="col-md-5">
                              <div className="pl-2">
                                <p className="fw-bold">Drop Location</p>
                                {viewBookingDetail.pickup ===
                                viewBookingDetail.dropoff ? (
                                  <p>
                                    <br />
                                  </p>
                                ) : (
                                  <p>
                                    {viewBookingDetail.dropoff} |{" "}
                                    {viewBookingDetail.endTime}
                                  </p>
                                )}

                                <p className="fw-bold">
                                  <i className="bi bi-geo-alt-fill" />{" "}
                                  {viewBookingDetail.dest_addr}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pl-2">
                      <p className="fw-bold fs-4">Booking Details</p>
                      <p className="mt-2 fw-bold">
                        Booking ID: {viewBookingDetail.id}
                      </p>
                      <p className="mt-2 fw-bold">
                        Payment ID: {viewBookingDetail.payment_id}
                      </p>
                      <p className="fw-bold">
                        Booking Status:{" "}
                        <span>
                          {viewBookingDetail.ride_status == null
                            ? "Pending"
                            : viewBookingDetail.ride_status}
                        </span>
                      </p>
                      <div className="pl-2">
                        <p className="fw-bold">
                          Total : ₹
                          <span>{viewBookingDetail.booking_amount}</span>
                        </p>
                        <div className="row">
                          <div className="col-md-3 custom-margin-bottom">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleCloseViewBookingDetails}
                            >
                              Close
                            </button>
                          </div>
                          <div className="col-md-8">
                            {viewBookingDetail.receipt == 1 ? (
                              <button
                                type="button"
                                className="btn btn-success w-100"
                                onClick={() => handleDownload(viewBookingId)}
                              >
                                Print Invoice
                              </button>
                            ) : (
                              ""
                            )}
                            <div></div>
                            {viewBookingDetail.ride_status === null ||
                            viewBookingDetail.ride_status === "Confirmed" ? (
                              viewBookingDetail.booking_owner === "cia" ? (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    cancelBookingDetails({
                                      id: viewBookingDetail.id,
                                      booking_owner:
                                        viewBookingDetail.booking_owner,
                                      bookingId: viewBookingDetail.booking_id,
                                    })
                                  }
                                >
                                  Cancel Booking
                                </button>
                              ) : (
                                <>
                                  {viewBookingDetail.pickup ===
                                  formattedCurrentDate ? null : (
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={() =>
                                        cancelBookingDetails({
                                          id: viewBookingDetail.id,
                                          booking_owner:
                                            viewBookingDetail.booking_owner,
                                          bookingId:
                                            viewBookingDetail.booking_id,
                                        })
                                      }
                                    >
                                      Cancel Booking
                                    </button>
                                  )}
                                </>
                              )
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container full-width rounded bg-white mb-dashboard-padding">
            <div className="bg row d-flex justify-content-center mb-5 mt-5">
              <div className="row pt-2 pb-2" id="payment-section">
                <div className="col-md-9">
                  <div className="pt-2"></div>
                </div>
                <div className="col-md-3" />
                <div className="row">
                  <div className="col-md-3">
                    {viewBookingDetail.vehicle_image_url === null ? (
                      // If bookings.vehicle_image_url is null, render an image using the URL from bookings.vehicle_details.image
                      <img
                        src={`https://backend.carsinafrica.com/uploads/${viewBookingDetail.vehicle_image}`}
                        className="card-img-top"
                      />
                    ) : (
                      // If bookings.vehicle_image_url is not null, render an image using the URL from bookings.vehicle_image_url
                      <img
                        src={`${viewBookingDetail.vehicle_image}`}
                        className="card-img-top"
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="pl-2">
                      <p className="fw-bold fs-4 mb-active-booking">
                        {viewBookingDetail.vehicle_make
                          .charAt(0)
                          .toUpperCase() +
                          viewBookingDetail.vehicle_make.slice(1)}{" "}
                        {viewBookingDetail.vehicle_model
                          .charAt(0)
                          .toUpperCase() +
                          viewBookingDetail.vehicle_model.slice(1)}
                      </p>

                      <p className="fw-bold">
                        Car Type: <span>{viewBookingDetail.vehicle_type}</span>
                      </p>
                      <div className="fw-bold mb-2">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="/images/mdi_air-conditioning.svg"
                            alt="AC"
                            className="mr-1"
                          />
                          AC
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/arcticonscar.svg"
                            alt="Sedan"
                            className="mr-1"
                          />
                          &nbsp;
                          {viewBookingDetail.vehicle_type}
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/mdi_petrol-pump-outline.svg"
                            alt="Petrol"
                            className="mr-1"
                          />
                          {viewBookingDetail.vehicle_engine_type}
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/mdi_seat-passenger.svg"
                            alt="4 Seater"
                            className="mr-1"
                          />
                          {viewBookingDetail.vehicle_seats}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="pl-2">
                            <p className="fw-bold">Pick up</p>
                            <p>{viewBookingDetail.pickup}</p>
                            <p className="fw-bold">
                              <i className="bi bi-geo-alt-fill" />{" "}
                              {viewBookingDetail.pickup_addr}
                            </p>
                          </div>
                        </div>
                        {viewBookingDetail.dest_addr === null ? null : (
                          <>
                            {" "}
                            <img
                              src="./images/fluent_arrow-swap-20-regular.svg"
                              className="custom-img"
                            />
                            <div className="col-md-5">
                              <div className="pl-2">
                                <p className="fw-bold">Drop Location</p>
                                <p>{viewBookingDetail.dropoff}</p>
                                <p className="fw-bold">
                                  <i className="bi bi-geo-alt-fill" />{" "}
                                  {viewBookingDetail.dest_addr}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pl-2">
                      <p className="fw-bold fs-4">Booking Details</p>
                      <p className="mt-2">Booking ID: {viewBookingDetail.id}</p>
                      <p className="fw-bold">
                        Booking Status:{" "}
                        <span>
                          {viewBookingDetail.ride_status == null
                            ? "Pending"
                            : viewBookingDetail.ride_status}
                        </span>
                      </p>
                      <div className="pl-2">
                        <p className="fw-bold">
                          Total : $
                          <span>{viewBookingDetail.booking_amount}</span>
                        </p>
                        <div className="row">
                          <div className="col-md-3 custom-margin-bottom">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleCloseViewBookingDetails}
                            >
                              Close
                            </button>
                          </div>
                          <div className="col-md-8">
                            {viewBookingDetail.receipt == 1 ? (
                              <button
                                type="button"
                                className="btn btn-success w-100"
                                onClick={() => handleDownload(viewBookingId)}
                              >
                                Print Invoice
                              </button>
                            ) : (
                              ""
                            )}
                            <div></div>
                            {viewBookingDetail.ride_status === null ||
                            viewBookingDetail.ride_status === "Confirmed" ? (
                              viewBookingDetail.booking_owner === "cia" ? (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    cancelBookingDetails({
                                      id: viewBookingDetail.id,
                                      booking_owner:
                                        viewBookingDetail.booking_owner,
                                      bookingId: viewBookingDetail.booking_id,
                                    })
                                  }
                                >
                                  Cancel Booking
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    cancelBookingDetails({
                                      id: viewBookingDetail.id,
                                      booking_owner:
                                        viewBookingDetail.booking_owner,
                                      bookingId: viewBookingDetail.booking_id,
                                    })
                                  }
                                >
                                  Cancel Booking
                                </button>
                              )
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  } else {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}
        <Header />
        <section className="banner-section">
          <img
            src={`${process.env.PUBLIC_URL}/images/cia-dashboard-image.jpg`}
            className="img-fluid"
          />
          <div className="banner-overlay"></div>
          <div className="container full-width">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">Dashboard</h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 header">
          <div className="container full-width">
            <div className="row">
              <div className="col-md-3">
                <div className="card mb-3">
                  <div className="card-body align-items-center mb-profile">
                    <div className="text-center">
                      <img
                        src="images/profile.png"
                        alt="User Photo"
                        className="img-fluid rounded-circle-profile-icon mb-2 profile-width"
                      />
                      <h5 className="mb-2 fw-bold">{userDetails.name}</h5>
                      <br />
                      <div className="progress process-height">
                        <div
                          class="progress-bar progress-bar-striped"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: `${userProgressPercentage}%`,
                            backgroundColor:
                              userProgressPercentage < 35
                                ? "red"
                                : userProgressPercentage < 75
                                ? "orange"
                                : "green",
                          }}
                        ></div>
                      </div>
                      <div className="process-bar-leftalign">
                        {userProgressPercentage}%
                      </div>
                      <div className="text-center pt-2">
                        <Link
                          to="/profile"
                          onClick={scrollTop}
                          className="btn btn-secondary detail"
                        >
                          <div className="">
                            <span className="label">&nbsp;Manage Account </span>
                            <span className="arrow">&#8594;</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Tabs nav */}
                <div
                  className="nav flex-column nav-pills nav-pills-custom height-fixed"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding  ${
                      activeTab === "active-bookings" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("active-bookings")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        data-rtl-flip="true"
                      >
                        <path d="M.75 4.5h16.34a3.5 3.5 0 1 0 0-1.5H.75a.75.75 0 0 0 0 1.5M20.5 1.75a2 2 0 1 1-2 2 2 2 0 0 1 2-2m2.75 17.75H9.46a3.5 3.5 0 0 0-6.83 0H.75a.75.75 0 0 0 0 1.5h1.88a3.5 3.5 0 0 0 6.83 0h13.79a.75.75 0 0 0 0-1.5m-17.2 2.75a2 2 0 1 1 2-2 2 2 0 0 1-2 2M23.25 11h-7.84a3.49 3.49 0 0 0-6.82 0H.75a.75.75 0 0 0 0 1.5h7.84a3.49 3.49 0 0 0 6.82 0h7.84a.75.75 0 0 0 0-1.5M12 13.75a2 2 0 1 1 2-2 2 2 0 0 1-2 2"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Active Bookings
                    </span>
                  </a>
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "upcoming-bookings" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("upcoming-bookings")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22.5 14.249v4.5a2.25 2.25 0 0 1-2.25 2.25H3.75a2.25 2.25 0 0 1-2.25-2.25v-9a2.25 2.25 0 0 1 2.25-2.25h16.5a2.25 2.25 0 0 1 2.25 2.25zm1.5 0v-4.5a3.75 3.75 0 0 0-3.75-3.75H3.75A3.75 3.75 0 0 0 0 9.749v9a3.75 3.75 0 0 0 3.75 3.75h16.5a3.75 3.75 0 0 0 3.75-3.75zm-18-7.5v15a.75.75 0 0 0 1.5 0v-15a.75.75 0 0 0-1.5 0m10.5 0v15a.75.75 0 0 0 1.5 0v-15a.75.75 0 0 0-1.5 0m0 0v-3a2.25 2.25 0 0 0-2.25-2.25h-4.5a2.25 2.25 0 0 0-2.25 2.25v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v3a.75.75 0 0 0 1.5 0"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Upcoming Bookings
                    </span>
                  </a>
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "booking-history" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("booking-history")}
                    role="tab"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22.5 17.25a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0m1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0m-7.5-3v6a.75.75 0 0 0 1.5 0v-6a.75.75 0 0 0-1.5 0M14.25 18h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 0 0 0 1.5M1.5 17.25a6.003 6.003 0 0 1 8.356-5.518.75.75 0 0 0 .588-1.38A7.504 7.504 0 0 0 0 17.25a.75.75 0 0 0 1.5 0m9.375-12.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0m1.5 0a4.875 4.875 0 1 0-9.75 0 4.875 4.875 0 0 0 9.75 0"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Booking History
                    </span>
                  </a>
                  <a
                    className={`nav-link-profile p-3 d-flex align-items-center justify-content-left profile-tab-padding ${
                      activeTab === "cancellation" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("cancellation")}
                    role="tab"
                    id="cancellation-border"
                  >
                    <div className="icon-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        data-rtl-flip="true"
                      >
                        <path d="M10.875 7.875a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0m1.5 0a4.875 4.875 0 1 0-9.75 0 4.875 4.875 0 0 0 9.75 0M1.5 20.25a6 6 0 0 1 12 0 .75.75 0 0 0 1.5 0 7.5 7.5 0 0 0-15 0 .75.75 0 0 0 1.5 0m18.852-10.125a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0m1.5 0a4.125 4.125 0 1 0-8.25 0 4.125 4.125 0 0 0 8.25 0m-5.78 5.647A4.776 4.776 0 0 1 22.5 20.25a.75.75 0 0 0 1.5 0 6.276 6.276 0 0 0-8.446-5.886.75.75 0 1 0 .518 1.408"></path>
                      </svg>
                    </div>
                    <span className="text-decoration-none fw-bold">
                      Cancellation
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-md-9">
                {/* Tabs content */}
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "booking-history" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    {/* START -> Booking History */}
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-history"
                      role="tabpanel"
                      aria-labelledby="v-pills-history-tab"
                      tabIndex={0}
                    >
                      <h2 className="fw-bold">Booking History</h2>
                      <hr></hr>
                      {isbookingHistoryLoading ? (
                        <div className="custom-container-gif">
                          <img
                            src={process.env.PUBLIC_URL + "/images/loader.gif"}
                            className="center-text"
                          />
                        </div>
                      ) : oldBookingHistory.length === 0 ? (
                        <h2 className="center-text">
                          No Booking History Available
                        </h2>
                      ) : (
                        <section className="custom-section">
                          <div className="row row-cols-1 row-cols-md-2 g-4">
                            {oldBookingHistory
                              .slice(
                                startBookingsHistoryIndex,
                                endBookingsHistoryIndex
                              )
                              .map((bookings, key) => (
                                <div key={key} className="col-md-12">
                                  <div className="card">
                                    <div className="row align-items-center mb-dashboard-padding">
                                      <div className="col-md-4">
                                        {bookings.vehicle_image_url === null ? (
                                          <img
                                            src={`https://backend.carsinafrica.com/uploads/${bookings.vehicle_details.image}`}
                                            className="card-img-top"
                                          />
                                        ) : (
                                          <img
                                            src={`${bookings.vehicle_image_url}`}
                                            className="card-img-top"
                                          />
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card-body">
                                          <h5 className="card-title pb-1">
                                            Booking Id: {bookings.id}
                                          </h5>
                                          <p className="card-text pb-2">
                                            Date & Time: {bookings.journey_date}{" "}
                                            {bookings.journey_time}
                                          </p>
                                          <p className="card-text pb-1">
                                            <i className="bi bi-geo-alt-fill"></i>{" "}
                                            Pickup Location :{" "}
                                            <span className="text-color custom-color">
                                              {bookings.pickup_addr === null
                                                ? "N/A"
                                                : bookings.pickup_addr}
                                            </span>
                                          </p>
                                          <p className="card-text pb-2">
                                            <i className="bi bi-geo-alt-fill"></i>{" "}
                                            Drop Location :{" "}
                                            <span className="text-color custom-color">
                                              {bookings.dest_addr === null
                                                ? "N/A"
                                                : bookings.dest_addr}
                                            </span>
                                          </p>

                                          <p className="card-text">
                                            Payment Status:{" "}
                                            {bookings.payment_status ===
                                            "success" ? (
                                              <span className="text-warning badge bg-success text-white">
                                                Paid
                                              </span>
                                            ) : (
                                              <span className="text-warning badge bg-danger text-white">
                                                Pending
                                              </span>
                                            )}
                                          </p>
                                          <p className="card-text">
                                            Booking Status:{" "}
                                            <span
                                              className={
                                                bookings.ride_status ===
                                                "Cancelled"
                                                  ? "text-warning badge bg-danger text-white"
                                                  : "text-success badge bg-success text-white"
                                              }
                                            >
                                              {bookings.ride_status}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        {bookings.booking_owner ===
                                        "savaari" ? (
                                          <button
                                            className="btn btn-primary me-2 view-booking"
                                            onClick={() =>
                                              viewBookingDetails(
                                                bookings.booking_id ||
                                                  bookings.id,
                                                "savaari"
                                              )
                                            }
                                          >
                                            View{" "}
                                            <i className="bi bi-eye-fill"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary me-2 view-booking"
                                            onClick={() =>
                                              viewBookingDetails(
                                                bookings.booking_id ||
                                                  bookings.id,
                                                "cia"
                                              )
                                            }
                                          >
                                            View{" "}
                                            <i className="bi bi-eye-fill"></i>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <nav aria-label="Pagination">
                            <ul className="pagination justify-content-left pt-3">
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageBookingsHistoryChange(
                                      currentBookingsHistoryPage - 1
                                    )
                                  }
                                  disabled={currentBookingsHistoryPage === 1}
                                >
                                  Previous
                                </button>
                              </li>
                              <li className="page-item">
                                <span className="page-link">
                                  {currentBookingsHistoryPage}
                                </span>
                              </li>
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageBookingsHistoryChange(
                                      currentBookingsHistoryPage + 1
                                    )
                                  }
                                  disabled={
                                    endBookingsHistoryIndex >=
                                    bookingHistory.length
                                  }
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </nav>
                        </section>
                      )}
                    </div>
                    {/* END -> Booking History */}
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === "active-bookings" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    {/* START -> Active Booking History */}
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-history"
                      role="tabpanel"
                      aria-labelledby="v-pills-history-tab"
                      tabIndex={0}
                    >
                      <h2 className="fw-bold mb-active-booking">
                        Active Bookings
                      </h2>
                      <hr></hr>
                      {isbookingHistoryLoading ? (
                        <div className="custom-container-gif">
                          <img
                            src={process.env.PUBLIC_URL + "/images/loader.gif"}
                            className="center-text"
                          />
                        </div>
                      ) : activeBookingHistory.length === 0 ? (
                        <h2 className="center-text">
                          No Active Bookings Available
                        </h2>
                      ) : (
                        <section className="custom-section">
                          <div className="row row-cols-1 row-cols-md-2 g-4">
                            {activeBookingHistory
                              .slice(
                                startBookingsHistoryIndex,
                                endBookingsHistoryIndex
                              )
                              .map((bookings, key) => (
                                <div key={key} className="col-md-12">
                                  <div className="card">
                                    <div className="row align-items-center mb-dashboard-padding">
                                      <div className="col-md-4">
                                        {bookings.vehicle_image_url === null ? (
                                          // If bookings.vehicle_image_url is null, render an image using the URL from bookings.vehicle_details.image
                                          <img
                                            src={`https://backend.carsinafrica.com/uploads/${bookings.vehicle_details.image}`}
                                            className="card-img-top"
                                          />
                                        ) : (
                                          // If bookings.vehicle_image_url is not null, render an image using the URL from bookings.vehicle_image_url
                                          <img
                                            src={`${bookings.vehicle_image_url}`}
                                            className="card-img-top"
                                          />
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card-body">
                                          <h5 className="card-title pb-1">
                                            Booking Id: {bookings.id}
                                          </h5>
                                          <p className="card-text pb-2">
                                            Date & Time: {bookings.journey_date}{" "}
                                            {bookings.journey_time}
                                          </p>
                                          <p className="card-text pb-1">
                                            <i className="bi bi-geo-alt-fill pick-location"></i>
                                            <span className="pick-loation">
                                              &nbsp;Pickup Location :
                                            </span>{" "}
                                            <span className="text-color ">
                                              {bookings.pickup_addr === null
                                                ? "N/A"
                                                : bookings.pickup_addr}
                                            </span>
                                          </p>
                                          <p className="card-text pb-2">
                                            <i className="bi bi-geo-alt-fill drop-location"></i>
                                            <span className="pick-loation">
                                              &nbsp;Drop Location :
                                            </span>{" "}
                                            <span className="text-color drop-location">
                                              {bookings.dest_addr === null
                                                ? "N/A"
                                                : bookings.dest_addr}
                                            </span>
                                          </p>

                                          <p className="card-text">
                                            Payment Status:{" "}
                                            {bookings.payment_status ===
                                            "success" ? (
                                              <span className="text-warning badge bg-success text-white">
                                                Paid
                                              </span>
                                            ) : (
                                              <span className="text-warning badge bg-danger text-white">
                                                Pending
                                              </span>
                                            )}
                                          </p>
                                          <p className="card-text">
                                            Booking Status:{" "}
                                            <span
                                              className={
                                                bookings.ride_status ===
                                                "Cancelled"
                                                  ? "text-warning badge bg-danger text-white"
                                                  : "text-success badge bg-success text-white"
                                              }
                                            >
                                              {bookings.ride_status}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="d-flex-middle">
                                          {bookings.booking_owner ===
                                          "savaari" ? (
                                            <button
                                              className="btn btn-primary me-2 view-booking"
                                              onClick={() =>
                                                viewBookingDetails(
                                                  bookings.booking_id ||
                                                    bookings.id,
                                                  "savaari"
                                                )
                                              }
                                            >
                                              View{" "}
                                              <i className="bi bi-eye-fill"></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary me-2 view-booking"
                                              onClick={() =>
                                                viewBookingDetails(
                                                  bookings.booking_id ||
                                                    bookings.id,
                                                  "cia"
                                                )
                                              }
                                            >
                                              View{" "}
                                              <i className="bi bi-eye-fill"></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <nav aria-label="Pagination">
                            <ul className="pagination justify-content-left pt-3">
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageActiveBookingsChange(
                                      currentActiveBookingsPage - 1
                                    )
                                  }
                                  disabled={currentActiveBookingsPage === 1}
                                >
                                  Previous
                                </button>
                              </li>
                              <li className="page-item">
                                <span className="page-link">
                                  {currentActiveBookingsPage}
                                </span>
                              </li>
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageActiveBookingsChange(
                                      currentActiveBookingsPage + 1
                                    )
                                  }
                                  disabled={
                                    endActiveBookingsIndex >=
                                    activeBookingHistory.length
                                  }
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </nav>
                        </section>
                      )}
                    </div>
                    {/* END -> Active Booking History */}
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === "upcoming-bookings" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    {/* START -> Upcoming Booking History */}
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-history"
                      role="tabpanel"
                      aria-labelledby="v-pills-history-tab"
                      tabIndex={0}
                    >
                      <h2 className="fw-bold">Upcoming Bookings</h2>
                      <hr></hr>
                      {isbookingHistoryLoading ? (
                        <div className="custom-container-gif">
                          <img
                            src={process.env.PUBLIC_URL + "/images/loader.gif"}
                            className="center-text"
                          />
                        </div>
                      ) : upcomingBookingHistory.length === 0 ? (
                        <h2 className="center-text">
                          No Upcoming Bookings Available
                        </h2>
                      ) : (
                        <section className="custom-section">
                          <div className="row row-cols-1 row-cols-md-2 g-4">
                            {upcomingBookingHistory
                              .slice(
                                startBookingsHistoryIndex,
                                endBookingsHistoryIndex
                              )
                              .map((bookings, key) => (
                                <div key={key} className="col-md-12">
                                  <div className="card">
                                    <div className="row align-items-center mb-dashboard-padding">
                                      <div className="col-md-4">
                                        {bookings.vehicle_image_url === null ? (
                                          // If bookings.vehicle_image_url is null, render an image using the URL from bookings.vehicle_details.image
                                          <img
                                            src={`https://backend.carsinafrica.com/uploads/${bookings.vehicle_details.image}`}
                                            className="card-img-top"
                                          />
                                        ) : (
                                          // If bookings.vehicle_image_url is not null, render an image using the URL from bookings.vehicle_image_url
                                          <img
                                            src={`${bookings.vehicle_image_url}`}
                                            className="card-img-top"
                                          />
                                        )}
                                      </div>
                                      <div className="col-md-5">
                                        <div className="card-body">
                                          <h5 className="card-title pb-1">
                                            Booking Id: {bookings.id}
                                          </h5>
                                          <p className="card-text pb-2">
                                            Date & Time: {bookings.journey_date}{" "}
                                            {bookings.journey_time}
                                          </p>
                                          <p className="card-text pb-1">
                                            From:{" "}
                                            {bookings.pickup_addr === null
                                              ? "N/A"
                                              : bookings.pickup_addr}
                                          </p>
                                          <p className="card-text pb-2">
                                            To:{" "}
                                            {bookings.dest_addr === null
                                              ? "N/A"
                                              : bookings.dest_addr}
                                          </p>
                                          <p className="card-text">
                                            Payment Status:{" "}
                                            {bookings.payment_status ===
                                            "success" ? (
                                              <span className="text-warning badge bg-success text-white">
                                                Paid
                                              </span>
                                            ) : (
                                              <span className="text-warning badge bg-danger text-white">
                                                Pending
                                              </span>
                                            )}
                                          </p>
                                          <p className="card-text">
                                            Booking Status:{" "}
                                            <span
                                              className={
                                                bookings.ride_status ===
                                                "Cancelled"
                                                  ? "text-warning badge bg-danger text-white"
                                                  : "text-success badge bg-success text-white"
                                              }
                                            >
                                              {bookings.ride_status}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="d-flex-middle">
                                          {bookings.booking_owner ===
                                          "savaari" ? (
                                            <button
                                              className="btn btn-primary me-2 view-booking"
                                              onClick={() =>
                                                viewBookingDetails(
                                                  bookings.booking_id ||
                                                    bookings.id,
                                                  "savaari"
                                                )
                                              }
                                            >
                                              View{" "}
                                              <i className="bi bi-eye-fill"></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary me-2 view-booking"
                                              onClick={() =>
                                                viewBookingDetails(
                                                  bookings.booking_id ||
                                                    bookings.id,
                                                  "cia"
                                                )
                                              }
                                            >
                                              View{" "}
                                              <i className="bi bi-eye-fill"></i>
                                            </button>
                                          )}
                                          <button
                                            className="btn btn-danger cancel-booking"
                                            onClick={() =>
                                              cancelBookingDetails({
                                                id: bookings.id,
                                                booking_owner:
                                                  bookings.booking_owner,
                                                bookingId: bookings.booking_id,
                                              })
                                            }
                                          >
                                            Cancel{" "}
                                            <i className="bi bi-trash"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <nav aria-label="Pagination">
                            <ul className="pagination justify-content-left pt-3">
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageUpcomingBookingsChange(
                                      currentUpcomingBookingsPage - 1
                                    )
                                  }
                                  disabled={currentUpcomingBookingsPage === 1}
                                >
                                  Previous
                                </button>
                              </li>
                              <li className="page-item">
                                <span className="page-link">
                                  {currentUpcomingBookingsPage}
                                </span>
                              </li>
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageUpcomingBookingsChange(
                                      currentUpcomingBookingsPage + 1
                                    )
                                  }
                                  disabled={
                                    endUpcomingBookingsIndex >=
                                    upcomingBookingHistory.length
                                  }
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </nav>
                        </section>
                      )}
                    </div>
                    {/* END -> Upcoming Booking History */}
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "cancellation" ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    {/* START -> cancelled Bookings */}
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-history"
                      role="tabpanel"
                      aria-labelledby="v-pills-history-tab"
                      tabIndex={0}
                    >
                      <h2 className="fw-bold">Cancelled Booking History</h2>
                      <hr></hr>
                      {isbookingHistoryLoading ? (
                        <div className="custom-container-gif">
                          <img
                            src={process.env.PUBLIC_URL + "/images/loader.gif"}
                            className="center-text"
                          />
                        </div>
                      ) : cancelledBookingHistory.length === 0 ? (
                        <h2 className="center-text">
                          No Cancelled Booking History Available
                        </h2>
                      ) : (
                        <section className="custom-section">
                          <div className="row row-cols-1 row-cols-md-2 g-4">
                            {cancelledBookingHistory
                              .slice(
                                startBookingsHistoryIndex,
                                endBookingsHistoryIndex
                              )
                              .map((bookings, key) => (
                                <div key={key} className="col-md-12">
                                  <div className="card">
                                    <div className="row align-items-center mb-dashboard-padding">
                                      <div className="col-md-4">
                                        {bookings.vehicle_image_url === null ? (
                                          // If bookings.vehicle_image_url is null, render an image using the URL from bookings.vehicle_details.image
                                          <img
                                            src={`https://backend.carsinafrica.com/uploads/${bookings.vehicle_details.image}`}
                                            className="card-img-top"
                                          />
                                        ) : (
                                          // If bookings.vehicle_image_url is not null, render an image using the URL from bookings.vehicle_image_url
                                          <img
                                            src={`${bookings.vehicle_image_url}`}
                                            className="card-img-top"
                                          />
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card-body">
                                          <h5 className="card-title pb-1">
                                            Booking Id: {bookings.id}
                                          </h5>
                                          <p className="card-text pb-2">
                                            Date & Time: {bookings.journey_date}{" "}
                                            {bookings.journey_time}
                                          </p>
                                          <p className="card-text pb-1">
                                            From:{" "}
                                            {bookings.pickup_addr === null
                                              ? "N/A"
                                              : bookings.pickup_addr}
                                          </p>
                                          <p className="card-text pb-2">
                                            To:{" "}
                                            {bookings.dest_addr === null
                                              ? "N/A"
                                              : bookings.dest_addr}
                                          </p>
                                          <p className="card-text">
                                            Payment Status:{" "}
                                            {bookings.payment_status ===
                                            "success" ? (
                                              <span className="text-warning badge bg-success text-white">
                                                Paid
                                              </span>
                                            ) : (
                                              <span className="text-warning badge bg-danger text-white">
                                                Pending
                                              </span>
                                            )}
                                          </p>
                                          <p className="card-text">
                                            Booking Status:{" "}
                                            <span
                                              className={
                                                bookings.ride_status ===
                                                "Cancelled"
                                                  ? "text-warning badge bg-danger text-white"
                                                  : "text-success badge bg-success text-white"
                                              }
                                            >
                                              {bookings.ride_status}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        {bookings.booking_owner ===
                                        "savaari" ? (
                                          <button
                                            className="btn btn-primary me-2 view-booking"
                                            onClick={() =>
                                              viewBookingDetails(
                                                bookings.booking_id ||
                                                  bookings.id,
                                                "savaari"
                                              )
                                            }
                                          >
                                            View{" "}
                                            <i className="bi bi-eye-fill"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary me-2 view-booking"
                                            onClick={() =>
                                              viewBookingDetails(
                                                bookings.booking_id ||
                                                  bookings.id,
                                                "cia"
                                              )
                                            }
                                          >
                                            View{" "}
                                            <i className="bi bi-eye-fill"></i>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <nav aria-label="Pagination">
                            <ul className="pagination justify-content-left pt-3">
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageCancelledBookingsChange(
                                      cancelledBookingsPage - 1
                                    )
                                  }
                                  disabled={cancelledBookingsPage === 1}
                                >
                                  Previous
                                </button>
                              </li>
                              <li className="page-item">
                                <span className="page-link">
                                  {cancelledBookingsPage}
                                </span>
                              </li>
                              <li className="page-item left-space">
                                <button
                                  className="page-link btn btn-success"
                                  onClick={() =>
                                    handlePageCancelledBookingsChange(
                                      cancelledBookingsPage + 1
                                    )
                                  }
                                  disabled={
                                    endCancelledBookingsIndex >=
                                    cancelledBookingHistory.length
                                  }
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </nav>
                        </section>
                      )}
                    </div>
                    {/* END -> cancelled Bookings */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
