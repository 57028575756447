import React, { useEffect } from "react";
import "./WhyChooseUs.css";

function ChooseUsSlider() {
  return (
    <>
      <section className="why-choose-us-slider justify-content-center flex-wrap pb-5">
        <div className="pt-5">
          <h1 className="pb-2 fw-bold text-center">Why Choose Us</h1>
          <p className="border-bottom pb-2 text-center">In the market of car rental services, We provide you with various best services which would make you choose us.</p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className="card why-choose-us-card">
              <div className="card-img-container position-relative">
                <img
                  src="/images/why-choose-us/at-affordable-rates.png"
                  className="card-img-top"
                  alt="At Affordable Rates"
                />
                <div className="card-img-overlay d-flex align-items-end pc-2">
                  <h4 className="card-title why-choose-us-card-title text-white pc-2 rounded fw-bold wcu-fw">
                    At Affordable Rates
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className="card why-choose-us-card">
              <div className="card-img-container position-relative">
                <img
                  src="/images/why-choose-us/flexible-booking-options.png"
                  className="card-img-top"
                  alt="Flexible Booking Options"
                />
                <div className="card-img-overlay d-flex align-items-end pc-2">
                  <h4 className="card-title why-choose-us-card-title text-white pc-2 rounded fw-bold wcu-fw">
                    Flexible Booking Options
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className="card why-choose-us-card">
              <div className="card-img-container position-relative">
                <img
                  src="/images/why-choose-us/quick-secure-payments.png"
                  className="card-img-top"
                  alt="Quick, Secure Payments"
                />
                <div className="card-img-overlay d-flex align-items-end pc-2">
                  <h4 className="card-title why-choose-us-card-title text-white pc-2 rounded fw-bold wcu-fw">
                    Quick, Secure Payments
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
            <div className="card why-choose-us-card">
              <div className="card-img-container position-relative">
                <img
                  src="/images/why-choose-us/currency-flexibility.png"
                  className="card-img-top"
                  alt="Currency Flexibility"
                />
                <div className="card-img-overlay d-flex align-items-end pc-2">
                  <h4 className="card-title why-choose-us-card-title text-white pc-2 rounded fw-bold wcu-fw">
                    Currency Flexibility
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUsSlider;
