import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  getToken,
  storeLocation,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import "./Footer.css";
import { useCookies } from "react-cookie";
export default function Footer() {
  const [cookies, setCookie] = useCookies([
    "city",
    "state",
    "country",
    "countryiso",
  ]);
  const [primaryContactEmail, setprimaryContactEmail] = useState();
  const [secondaryContactEmail, setsecondaryContactEmail] = useState();
  const [primaryContactNumber, setprimaryContactNumber] = useState();
  const [secondaryContactNumber, setsecondaryContactNumber] = useState();
  const [contactAddress, setcontactAddress] = useState();
  const encodedCountry = encodeURIComponent(cookies.country || null);
  const encodedCity = encodeURIComponent(cookies.city || null);
  const userLoggedData = getToken();

  // START -> useEffect when Page Is Reloaded
  useEffect(() => {
    getContactDetails();
  }, []);
  // END -> useEffect when Page Is Reloaded

  function getContactDetails() {
    axios
      .get(
        `https://backend.carsinafrica.com/onlineApi/search_address_pno.php?city=${encodedCity}&country=${encodedCountry}`
      )
      .then(function (response) {
        setprimaryContactEmail(response.data[0].contact_email_primary);
        setsecondaryContactEmail(response.data[0].contact_email_secondary);
        setprimaryContactNumber(response.data[0].contact_number_primary);
        setsecondaryContactNumber(response.data[0].contact_number_secondary);
        setcontactAddress(response.data[0].address);
      });
  }
  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant", // Scroll instantly without smooth scrolling
    });
  }

  return (
    <>
      <div className="container-fluid text-white bg-footer">
        <div className="container full-width">
          <footer className="col-md-12 col-lg-12 pt-5 py-3">
            <div className="row">
              <div className="col-md-4">
                <h5 className="mb-4">About Us</h5>
                <p className="mb-5">
                  Cars In Africa is a market-driven entity and a formidable
                  partner in the hospitality & tourism industry specializing in
                  the car rental services and packages.
                </p>
                <ul className="list-unstyled d-flex">
                  <li className="ms-0">
                    <a
                      className="link-dark"
                      href="https://www.facebook.com/carsinafrica1"
                      target="_blank"
                    >
                      <button className="facebook btn" type="button">
                        <i className="fa-brands fa-facebook"></i>
                      </button>
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-dark"
                      href="https://twitter.com/CarsInAfrica1"
                      target="_blank"
                    >
                      <button
                        className="btn btn bg-white twitter"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-twitter-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                        </svg>
                      </button>
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-dark"
                      href="https://www.instagram.com/_carsinafrica_/"
                      target="_blank"
                    >
                      <button
                        className="btn btn-danger instagram-size"
                        type="button"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </button>
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-dark"
                      href="https://api.whatsapp.com/send?phone=917709998446"
                      target="_blank"
                    >
                      <button className="btn btn-success" type="button">
                        <i className="fa-brands fa-whatsapp"></i>
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <h5>Login</h5>
                {userLoggedData.api_token == null ? (
                  <div className="pt-4">
                    <Link to="/partner-login" onClick={scrollTop}>
                      <button
                        type="button"
                        className="btn btn-danger login-bg-color partner-login"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={process.env.PUBLIC_URL + "/images/vector.svg"}
                            alt="Icon"
                            className="mr-2 partner-vector"
                          />
                          <span className="text-white ml-partner">
                            Partner Login
                          </span>
                        </div>
                      </button>
                    </Link>
                    {/* Add a gap here, you can adjust the height as needed */}
                    <div className="footer-margin-height"></div>
                    <Link to="/corporate-login" onClick={scrollTop}>
                      <button
                        type="button"
                        className="btn btn-danger corporate-bg partner-login"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={process.env.PUBLIC_URL + "/images/vector.svg"}
                            alt="Icon"
                            className="mr-2 btn-corporate-size"
                          />
                          <span className="text-white ml-corporate">
                            Corporate Login
                          </span>
                        </div>
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Link to="/dashboard">
                      <button
                        type="button"
                        className="btn btn-danger btn-bg-dashboard"
                      >
                        <div className="d-flex align-items-center partner-login">
                          <img
                            src={process.env.PUBLIC_URL + "/images/vector.svg"}
                            alt="Icon"
                            className="mr-2 btn-dashboard-size"
                          />
                          <span className="text-white dashboard-ml-btn">
                            Dashboard
                          </span>
                        </div>
                      </button>
                    </Link>
                    {/* Add a gap here, you can adjust the height as needed */}
                    <div className="footer-margin-height"></div>
                  </div>
                )}
                <div className="mb-4"></div>
              </div>
              <div className="col-md-2 col-6">
                <h5>Quick Links</h5>
                <ul className="nav flex-column pt-3">
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link to="/about-us" className="nav-link p-0 text-light">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link
                      to="/our-services"
                      className="nav-link p-0 text-light"
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <a
                      href="https://carsinafrica.com/blogs/"
                      className="nav-link p-0 text-light"
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link to="/faqs" className="nav-link p-0 text-light">
                      FAQ's
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link to="/contact-us" className="nav-link p-0 text-light">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 col-6 mt-5">
                <ul className="nav flex-column">
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link
                      to="/track-booking"
                      className="nav-link p-0 text-light"
                    >
                      Track Booking
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link to="/data-policy" className="nav-link p-0 text-light">
                      Data Policy
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link
                      to="/cancellation-policy"
                      className="nav-link p-0 text-light"
                    >
                      Cancellation Policy
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link
                      to="/terms-and-conditions"
                      className="nav-link p-0 text-light"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="nav-item mb-2" onClick={scrollTop}>
                    <Link
                      to="/privacy-policy"
                      className="nav-link p-0 text-light"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-2 ">
                <div class="support-box">
                  <h5 className="">
                    <img src="/images/clock2x.png" width="19%" />
                    &nbsp;24/7 Support
                  </h5>
                </div>
                <ul className="nav flex-column footer-contact-us-width pt-3">
                  <li className="nav-item mb-2 d-flex align-items-center">
                    <img
                      className="location-icon mr-2"
                      alt="Call"
                      src={
                        process.env.PUBLIC_URL + "/images/icbaselinecall.svg"
                      }
                    />
                    <a
                      href={`tel:${primaryContactNumber}`}
                      className="nav-link p-0 text-light"
                    >
                      <span>{primaryContactNumber}</span>
                    </a>
                  </li>
                  <li className="nav-item mb-2 d-flex align-items-center">
                    <img
                      className="location-icon mr-2"
                      alt="Call"
                      src={
                        process.env.PUBLIC_URL + "/images/icbaselinecall.svg"
                      }
                    />
                    <a
                      href={`tel:${secondaryContactNumber}`}
                      className="nav-link p-0 text-light"
                    >
                      <span>{secondaryContactNumber}</span>
                    </a>
                  </li>
                  <li className="nav-item mb-2 d-flex align-items-center pt-4">
                    <img
                      className="location-icon mr-2"
                      alt="Call"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/materialsymbolsmailoutline.svg"
                      }
                    />
                    &nbsp;
                    <a
                      href={`mailto:${primaryContactEmail}`}
                      className="nav-link p-0 text-light"
                    >
                      <tel className="p-0 email-address">
                        {primaryContactEmail}
                      </tel>
                    </a>
                  </li>
                  <li className="nav-item mb-2 d-flex align-items-center">
                    <img
                      className="location-icon mr-2"
                      alt="Call"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/materialsymbolsmailoutline.svg"
                      }
                    />
                    &nbsp;
                    <a
                      href={`mailto:${secondaryContactEmail}`}
                      className="nav-link p-0 text-light"
                    >
                      <tel className="p-0 email-address">
                        {secondaryContactEmail}
                      </tel>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-3 border-top border-gray">
              <p>
                Copyright @ SATGURU TRAVEL AND TOURISM PRIVATE LIMITED 2024, All
                rights reserved.
              </p>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
