import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Topbar from '../../components/Topbar/Topbar'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import './GlobalPresence.css'
export default function GlobalPresence() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useEffect(() => {
    // Make an API request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=global-presence&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);
  return (
    <>
      <Header />
      {/* <GlobalPresenceBanner /> */}
      <section className="banner-section">
        <img src={process.env.PUBLIC_URL + "/images/Global_Presence.jpg"} className="img-fluid" alt="Global Presence" />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">GLOBAL PRESENCE</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">CENTRAL AFRICA</h2>
          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="bi/burundi"
                className="btn btn-outline-dark btn-lg custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/burundi-flag.gif`}
                  alt=""
                  className="custom-img-global"
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  <font>BURUNDI</font>
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="cm/cameroon"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/cameroon-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  CAMEROON
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="cf/central-african-republic"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/car-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  CENTRAL AFRICAN REP
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="cg/congo"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/congo-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt="flags"
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  CONGO
                </span>
              </Link>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="cd/dr-congo/"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/drc-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  DR CONGO
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="gq/equatorial-guinea"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/equatorial-guinea-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  EQUATORIAL GUINEA
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="ga/gabon"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/gabon-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  GABON
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> CENTRAL AFRICA */}

      {/* START -> EAST AFRICA */}
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">EAST AFRICA</h2>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="km/camoros"
                className="btn btn-outline-dark btn-lg custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/comoros-flag.gif`}
                  alt=""
                  className='custom-img-global'
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  <font>COMOROS</font>
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="et/ethiopia"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/ethiopia-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  ETHIOPIA
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="ke/kenya"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/kenya-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  KENYA
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="mg/madagascar/"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/madagascar-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  MADAGASCAR
                </span>
              </Link>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="mu/mauritius"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/mauritius-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  MAURITIUS
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="rw/rwanda"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/rwanda-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  RWANDA
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="tz/tanzania"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/tanzania-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  TANZANIA
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="ug/uganda"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/uganda-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  UGANDA
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> EAST AFRICA */}

      {/* START -> NORTH AFRICA */}
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">NORTH AFRICA</h2>
          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="eg/egypt"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/egypt-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  EGYPT
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> NORTH AFRICA */}

      {/* START -> SOUTHERN AFRICA */}
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">SOUTHERN AFRICA</h2>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="ao/angola"
                className="btn btn-outline-dark btn-lg custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/angola-flag.gif`}
                  alt=""
                  decoding="async"
                  className='custom-img-global'
                />
                <span className="menu-image-title-after menu-image-title">
                  <font>ANGOLA</font>
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="mw/malawi"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/malawi-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  MALAWI
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="mz/mozambique"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/mozambique-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  MOZAMBIQUE
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="na/namibia"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/namibia-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  NAMIBIA
                </span>
              </Link>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="za/south-africa"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/south-africa-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  SOUTH AFRICA
                </span>
              </Link>
            </div>

            <div className="col-md-3" id="spacing">
              <Link
                to="zm/zambia"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/zambia-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  ZAMBIA
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="zw/zimbabwe"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/zimbabwe-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  ZIMBABWE
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> SOUTHERN AFRICA */}

      {/* START -> WEST AFRICA */}
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">WEST AFRICA</h2>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="bj/benin"
                className="btn btn-outline-dark btn-lg custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/benin-flag.gif`}
                  alt=""
                  decoding="async"
                  className='custom-img-global'
                />
                <span className="menu-image-title-after menu-image-title">
                  <font>BENIN</font>
                </span>
              </Link>
            </div>
            <div className="col-md-3" id="spacing">
              <Link
                to="ng/nigeria"
                className="btn btn-outline-dark btn-lg custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/nigeria-flag.gif`}
                  alt=""
                  decoding="async"
                  className='custom-img-global'
                />
                <span className="menu-image-title-after menu-image-title">
                  <font>NIGERIA</font>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> WEST AFRICA */}

      {/* START -> AMERICA EAST */}
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">AMERICA</h2>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="ca/canada"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/canada-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  CANADA
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> AMERICA EAST */}

      {/* START -> EUROPE EAST */}
      <div className="container full-width pt-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">EUROPE</h2>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="gb/united-kingdom"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/united-kingdom-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  UNITED KINGDOM
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* END -> EUROPE EAST */}

      {/* START -> ASIA */}
      <div className="container full-width pt-5 pb-5 mb-3">
        <div className="row">
          <h2 className="fw-bold">ASIA</h2>

          <div className="row pt-3">
            <div className="col-md-3" id="spacing">
              <Link
                to="in/india"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/india-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  INDIA
                </span>
              </Link>
            </div>
            {/* <div className="col-md-3" id="spacing">
              <Link
                to="jp/japan"
                className="btn btn-outline-dark btn-lg menu-image-title-after menu-image-not-hovered elementor-item custom-link"
              >
                <img
                  loading="lazy"
                  width={18}
                  height={10}
                  src={`${process.env.PUBLIC_URL}/images/all-country-flags/japan-flag.gif`}
                  className="menu-image menu-image-title-after custom-img-global"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  JAPAN
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
