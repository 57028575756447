import React, { useLayoutEffect, useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./Aboutus.css";
import axios from "axios";
import CountUp from "react-countup";
export default function AboutUs() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [ourValues, setOurValues] = useState([
    {
      serving_countries: 0,
      locations: 0,
      happy_miles: 0,
      vehicle_inventories: 0,
      happy_miles_million: 0,
      vehicle_inventories_thousand: 0,
    },
  ]);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("about-us");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  useEffect(() => {
    axios
      .get("https://backend.carsinafrica.com/frontend/vehiclescount")
      .then(function (response) {
        setOurValues(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      {/* About Us -> START*/}
      <Header />
      <section className="banner-section">
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/metallic-color-mini-coupe-driving-accross-forest-about-us.jpg"
          }
          className="img-fluid"
          alt="About us"
        />
        <div className="banner-overlay"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption shadow-text">About Us</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6 col-xl-5">
              <img
                className="img-fluid rounded"
                loading="lazy"
                src="https://bootstrapbrain.com/demo/components/abouts/about-1/assets/img/about-img-1.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-7">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h2 className="h1 mb-3 fw-bold">Who Are We?</h2>
                  <p className="mb-5 who-we-are-description">
                    Cars in Africa are committed to providing high-quality
                    vehicles to customers throughout Africa and its nearby
                    countries. With years of experience in the automotive
                    industry, our team offers a wide selection of vehicles to
                    meet the diverse needs of our customers, from hatchbacks and
                    sedans to SUVs and pickup trucks. We work with trusted
                    manufacturers and suppliers to ensure that our inventory
                    meets the highest standards of quality and reliability. Our
                    goal is to make the car-renting process as easy as possible
                    for our customers. We offer a wide range of cars available
                    for daily/ weekly/ monthly rentals and an option for
                    self-drive and chauffeur service.
                  </p>
                  <div className="row gy-4 gy-md-0 gx-xxl-5X">
                    <div className="col-12 col-md-6">
                      <div className="d-flex">
                        <div className="me-4 text-primary">
                          <div className="icon-container-aboutus">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="#10572d"
                              className="bi bi-eye-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM0 8s4-5.5 8-5.5c4 0 8 5.5 8 5.5s-4 5.5-8 5.5C4 13.5 0 8 0 8zm8-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div>
                          <h4 className="mb-3">Our Vision</h4>
                          <p className="text-secondary mb-0">
                            Our vision at Cars in Africa is to be the most
                            trusted and preferred provider of high-quality
                            vehicles and exceptional service in the African
                            market. We aim to deliver a seamless and hassle-free
                            car buying experience that exceeds our customers'
                            expectations
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex">
                        <div className="me-4 text-primary">
                          <div className="icon-container-aboutus">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={32}
                              height={32}
                              fill="#10572d"
                              className="bi bi-mission-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
                            </svg>
                          </div>
                        </div>
                        <div>
                          <h4 className="mb-3">Our Mission</h4>
                          <p className="text-secondary mb-0">
                            To provide our customers with the highest quality
                            vehicles and exceptional car rental service. We are
                            committed to helping our customers find the perfect
                            car to meet their needs and budget while delivering
                            a seamless and hassle-free car buying experience.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bsb-tpl-bg-alabaster py-5 py-xl-8 bsb-section-py-xxl-1">
        <div className="container bsb-section-mt-1">
          <div className="row gy-3 gy-md-5 gy-lg-0">
            <div className="col-12 col-lg-5">
              <h2 className="display-1 mb-3 mb-xl-4 fw-bold">
                <span className="text-orange">Our</span> Achievements
              </h2>

              <p className="mb-5">
                Driving your dreams to reality with an exquisite fleet of
                versatile vehicles for unforgettable journeys.
              </p>
              <Link
                to="/contact-us"
                className="btn btn-lg text-white bg-contact-us"
              >
                Contact Us
              </Link>
            </div>
            <div className="col-12 col-lg-7">
              <div className="row justify-content-xl-end">
                <div className="col-12 col-xl-11">
                  <div className="row gy-3 gy-md-4">
                    <div className="col-12 col-sm-6">
                      <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden bg-aboutus">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <CountUp
                            className="display-1 mb-2 bsb-tpl-font"
                            end={ourValues[0].serving_countries}
                          />
                          <span className="display-1 mb-2 bsb-tpl-font">+</span>
                          <p className="fs-5 mb-0 text-secondary position-relative z-1">
                            Serving Countries
                          </p>
                          <div className="bsb-circle bg-accent border border-2 border-accent position-absolute top-100 start-100 translate-middle" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden bg-aboutus">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <CountUp
                            className="display-1 mb-2 bsb-tpl-font"
                            end={ourValues[0].locations}
                          />
                          <span className="display-1 mb-2 bsb-tpl-font">+</span>
                          <p className="fs-5 mb-0 text-secondary position-relative z-1">
                            Locations
                          </p>
                          <div className="bsb-circle bg-accent border border-2 border-accent position-absolute top-100 start-100 translate-middle" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden bg-aboutus">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <CountUp
                            className="display-1 mb-2 bsb-tpl-font"
                            end={ourValues[0].happy_miles_million}
                          />
                          <span className="display-1 mb-2 bsb-tpl-font">
                            M+
                          </span>
                          <p className="fs-5 mb-0 text-secondary position-relative z-1">
                            Happy Miles
                          </p>
                          <div className="bsb-circle bg-accent border border-2 border-accent position-absolute top-100 start-100 translate-middle" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden bg-aboutus">
                        <div className="card-body text-center p-4 p-xxl-5">
                          <CountUp
                            className="display-1 mb-2 bsb-tpl-font"
                            end={ourValues[0].vehicle_inventories_thousand}
                          />
                          <span className="display-1 mb-2 bsb-tpl-font">
                            K+
                          </span>
                          <p className="fs-5 mb-0 text-secondary position-relative z-1">
                            Vehicle Inventories
                          </p>
                          <div className="bsb-circle bg-accent border border-2 border-accent position-absolute top-100 start-100 translate-middle" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container full-width">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-sm-6 mx-auto mb-4">
            <h3 className="pt-4">
              <b>Our Vision</b>
            </h3>
            <img
              src={process.env.PUBLIC_URL + "/images/vision.jpg"}
              className="img-fluid rounded-3"
              alt="vision"
            />
            <div className="my-3">
              <p className="pt-4 lh-lg">
                Our vision at Cars in Africa is to be the most trusted and
                preferred provider of high-quality vehicles and exceptional
                service in the African market. We aim to deliver a seamless and
                hassle-free car buying experience that exceeds our customers'
                expectations
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-sm-6 mx-auto mb-4">
            <h3 className="pt-4">
              <b>Our Mission</b>
            </h3>
            <img
              src={process.env.PUBLIC_URL + "/images/mission.jpg"}
              className="img-fluid rounded-3"
              alt="mission"
            />
            <div className="my-3">
              <p className="pt-4 lh-lg">
                To provide our customers with the highest quality vehicles and
                exceptional car rental service. We are committed to helping our
                customers find the perfect car to meet their needs and budget
                while delivering a seamless and hassle-free car buying
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="banner-section" id="about-us-banner">
        <div className="container full-width">
          <img
            src={process.env.PUBLIC_URL + "/images/About_Company.jpg"}
            className="img-fluid banner-overlay"
            alt="about_company"
          />
          <div className="row align-items-center text-left">
            <div className="col-md-10">
              <h2 className="about-caption">About Company</h2>
              <p className="about-para">
                Cars in Africa are committed to providing high-quality vehicles
                to customers throughout Africa and its nearby countries. With
                years of experience in the automotive industry, our team offers
                a wide selection of vehicles to meet the diverse needs of our
                customers, from hatchbacks and sedans to SUVs and pickup trucks.
                We work with trusted manufacturers and suppliers to ensure that
                our inventory meets the highest standards of quality and
                reliability.
              </p>
              <p className="about-para">
                Our goal is to make the car-renting process as easy as possible
                for our customers. We offer a wide range of cars available for
                daily/ weekly/ monthly rentals and an option for self-drive and
                chauffeur service.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Banner />
            <VisionMission />
            <AboutCompany /> */}
      <Footer />
      {/* About Us -> END*/}
    </>
  );
}
