import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { FaqBanner } from "../../components/Banner/Banner";
import { Faq } from "../../components/StaticContent/StaticContent";
import Footer from "../../components/Footer/Footer";
import "./Faqs.css";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { Helmet } from "react-helmet";
export default function Faqs() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("faqs");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  const buttons = document.querySelectorAll(".faq-title");

  function Onclick() {
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const parentCard = button.closest(".card");
        const collapseElement = parentCard.querySelector(".collapse");
        collapseElement.classList.toggle("show");
      });
    });
  }

  const [expandedItem, setExpandedItem] = useState(null);

  const handleAccordionItemClick = (itemId) => {
    if (expandedItem === itemId) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemId);
    }
  };

  const isItemExpanded = (itemId) => expandedItem === itemId;

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/faqs.jpg"}
          className="img-fluid"
          alt="faqs"
        />
        <div className="banner-overlay"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">
                Frequently Asked Questions
              </h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-section mb-5">
        <div className="container full-width">
          <div className="row">
            {/* ***** FAQ Start ***** */}
            <div className="col-md-12">
              <div className="faq" id="accordion">
                <div className="card">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-1")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-1") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">01</span>How do I contact
                        Carsinafrica?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className={`collapse ${isItemExpanded("faqCollapse-1") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        There are three main booking channels to make a
                        reservation, Web Portal, email and telephone.
                        <br />
                        <b>Email:</b>{" "}
                        <a href="mailto:support@carsinafrica.com">
                          support@carsinafrica.com
                        </a>
                        <br />
                        <b>Telephone:</b> +91 848 4918 239 (Carsinafrica Help
                        Desk)
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-3")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-3") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">02</span>How do I know if
                        Carsinafrica has confirmed my rental?
                      </h5>
                    </div>
                  </div>

                  <div
                    id="faqCollapse-3"
                    className={`collapse ${isItemExpanded("faqCollapse-3") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        A confirmation email with a confirmation will be sent to
                        your email address.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-4")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-4") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">03</span>How do I modify a
                        reservation?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className={`collapse ${isItemExpanded("faqCollapse-4") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Contact the Carsinafrica reservation team via email or
                        telephone, provide your Carsinafrica confirmation # and
                        the relevant details of the change.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-5")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-5") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">04</span>How do I cancel a
                        reservation?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className={`collapse ${isItemExpanded("faqCollapse-5") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Contact the Carsinafrica reservation team via email or
                        telephone and provide your Carsinafrica Reservation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-6")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-6") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">05</span>What types of chauffeur
                        services can Carsinafrica provide?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className={`collapse ${isItemExpanded("faqCollapse-6") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        I. Airport Transfers
                        <br />
                        - Pickup from airport or drop-off to airport
                        <br />
                        II. Half-day rental
                        <br />
                        - Allows passenger to use vehicle/driver for 4 hours
                        <br />
                        III. Daily Rental
                        <br />
                        - Allows passenger to use vehicle/driver for 8 hours
                        <br />
                        IV. Point to Point
                        <br />
                        - Pickup from one location and drop-off to another non
                        airport location
                        <br />
                        V. Intercity
                        <br />
                        - Transportation between two cities from Bandar Seri
                        Begawan - Kuala Belait
                        <br />
                        VI. Cross border
                        <br />
                        - Transportation between two countries
                        <br />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-7")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-7") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">06</span>What are the available
                        vehicle types?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className={`collapse ${isItemExpanded("faqCollapse-7") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        I. Standard Elite: Toyota Alphard or similar
                        <br />
                        II. Intermediate: Toyota Avensis or similar
                        <br />
                        III. Special: Hyundai H1
                        <br />
                        IV. Luxury: Mercedes E250 or similar
                        <br />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-8")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-8") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">07</span>What are the payment
                        options?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className={`collapse ${isItemExpanded("faqCollapse-8") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        I. Offline Credit Card Charge
                        <br />
                        - This is the preferred method of payment
                        <br />
                        - Provide your CC number during the reservation process
                        and the Carsinafrica finance team will manually charge
                        your credit card after the service has been completed
                        <br />
                        II. Bank Transfer
                        <br />- This option is only available for clients with
                        corporate contracts with Carsinafrica
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-9")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-9") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">08</span>What is credit card
                        pre-authorization?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className={`collapse ${isItemExpanded("faqCollapse-9") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        This is an authorization of 1.5 times the basic rental
                        amount and is used to cover any potential extra costs
                        that may be incurred during the rental and to ensure
                        that the credit card provided has the sufficient funds
                        to cover the expense.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-10")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-10") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">09</span>How can I get an
                        invoice/receipt for the service?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className={`collapse ${isItemExpanded("faqCollapse-10") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        All the necessary documents such as Chauffeur Drive Hire
                        Agreement and the official receipt can be collected from
                        the driver directly after the service has been done.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-11">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-11")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-11") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">10</span>Will the driver call or
                        send me a text message before pickup?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-11"
                    className={`collapse ${isItemExpanded("faqCollapse-11") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-11"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>We send vehicle details both via SMS and Email.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-12">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() =>
                          handleAccordionItemClick("faqCollapse-12")
                        }
                      >
                        <i
                          className={`bi bi-caret-${isItemExpanded("faqCollapse-12") ? "up" : "down"
                            } custom-caret`}
                        />
                        <span className="badge">11</span>How do I contact
                        Carsinafrica in case of emergencies?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-12"
                    className={`collapse ${isItemExpanded("faqCollapse-12") ? "show" : ""
                      }`}
                    aria-labelledby="faqHeading-12"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>Contact our service hotline.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Faq /> */}
      <Footer />
    </>
  );
}
