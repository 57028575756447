import React from "react";
import "./lease-offers.css";

const LeaseOffers = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <h2 class="title_lines mb-5">Latest Offers</h2>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src="../images/lease/lease.jpg"
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                <div className="promo-code btn btn-primary">GRAB NOW</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src="../images/lease/lease2.jpg"
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3 pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                <div className="promo-code btn btn-primary">GRAB NOW</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src="../images/lease/lease3.jpg"
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                <div className="promo-code btn btn-primary">GRAB NOW</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src="../images/lease/lease4.jpg"
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                <div className="promo-code btn btn-primary">GRAB NOW</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src="../images/lease/lease.jpg"
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                <div className="promo-code btn btn-primary">GRAB NOW</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src="../images/lease/lease2.jpg"
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                <div className="promo-code btn btn-primary">GRAB NOW</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container mt-5">
        <div className="promo-card">
          <div>
            <h5>FLAT 40% OFF ON SELECT VEHICLES</h5>
            <p>
              Book your airport, local and outstation ride at FLAT 40% OFF* from
              May 1st, 2024
            </p>
          </div>
          <div className="promo-code">GRAB NOW</div>
        </div>
        <div className="promo-card">
          <div>
            <h5>FLAT 40% OFF ON SELECT VEHICLES</h5>
            <p>
              Book your airport, local and outstation ride at FLAT 40% OFF* from
              May 1st, 2024
            </p>
          </div>
          <div className="promo-code">GRAB NOW</div>
        </div>
        <div className="promo-card">
          <div>
            <h5>FLAT 40% OFF ON SELECT VEHICLES</h5>
            <p>
              Book your airport, local and outstation ride at FLAT 40% OFF* from
              May 1st, 2024
            </p>
          </div>
          <div className="promo-code">GRAB NOW</div>
        </div>
        <div className="promo-card">
          <div>
            <h5>FLAT 40% OFF ON SELECT VEHICLES</h5>
            <p>
              Book your airport, local and outstation ride at FLAT 40% OFF* from
              May 1st, 2024
            </p>
          </div>
          <div className="promo-code">GRAB NOW</div>
        </div>
        <div className="promo-card">
          <div>
            <h5>FLAT 40% OFF ON SELECT VEHICLES</h5>
            <p>
              Book your airport, local and outstation ride at FLAT 40% OFF* from
              May 1st, 2024
            </p>
          </div>
          <div className="promo-code">GRAB NOW</div>
        </div>
        <div className="promo-card">
          <div>
            <h5>FLAT 40% OFF ON SELECT VEHICLES</h5>
            <p>
              Book your airport, local and outstation ride at FLAT 40% OFF* from
              May 1st, 2024
            </p>
          </div>
          <div className="promo-code">GRAB NOW</div>
        </div>

        <div class="terms">
          <p>Terms & Conditions</p>
          <p>Applicable on bookings between 1st May, 2024 to 30th June, 2024</p>
        </div>
      </div> */}

      {/* <div className="container">
        <div className="card mb-3 custom-card-vehicle">
          <div className="row vehicle-information-col">
            <div className="col-md-4">
              <img
                src={`https://backend.carsinafrica.com/uploads`}
                className="carlist-image"
                alt="Carlist Image"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body car-list-data">
                <h5 className="card-title pt-3 font-weight-bold">
                  <a>
                    <b></b>
                  </a>
                </h5>
                <i className="bi bi-geo-alt-fill"></i> <span>adddddd</span>
                <div className="img-fluid"></div>
                <div className="d-inline-flex align-items-center m-1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/mdi_air-conditioning.svg"
                    }
                    alt="AC"
                    className="mr-1"
                  />
                  AC
                </div>
                <div className="d-inline-flex align-items-center m-1">
                  <img
                    src={process.env.PUBLIC_URL + "/images/arcticonscar.svg"}
                    alt="Sedan"
                    className="mr-1"
                  />
                  <span className="vehicle-span">typeeeeeee</span>
                </div>
                <div className="d-inline-flex align-items-center m-1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/mdi_petrol-pump-outline.svg"
                    }
                    alt="Petrol"
                    className="mr-1"
                  />
                  engine type
                </div>
                <div className="d-inline-flex align-items-center m-1">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/mdi_seat-passenger.svg"
                    }
                    alt="4 Seater"
                    className="mr-1"
                  />
                  5 Seater
                </div>
                <hr className="my-3" />
                <div className="row">
                  <div className="col-md-8">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <div className="chip me-0">
                          <b className="custom-bold">
                            Allotted km: Unlimited km/
                          </b>
                          <b></b> |<b className="custom-bold-left"></b>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LeaseOffers;
