/*
@copyright

Cars In Africa v0.5

Copyright (C) 2023 Satguru Travel And Tourism Pvt Ltd <https://satgurutravel.com/> All rights reserved.
Design and developed by Satguru Travel And Tourism Pvt Ltd <https://satgurutravel.com/>

*/

import React, { useState, useEffect } from "react";
import "./pagenotfound.css";
// import { Link } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function Not_Found() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useEffect(() => {
    // Make an API request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=about-us&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);
  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <div className="page-not-found-container">
        <h2>Oops! Page not found.</h2>
        <h1>404</h1>
        <p>We can't find the page you're looking for.</p>
        <a href="/">Go back home</a>
      </div>
      <Footer />
    </>
  );
}
