import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./routing/App/App";
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from '../src/services/Apis/CarsInAfrica/Auth/app/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
