import React, { useState, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import axios from "axios";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import {
  getToken,
  storeToken,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import { useLoginUserCCMutation } from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";
import Swal from "sweetalert2";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
export default function Corporate() {
  const navigate = useNavigate();
  const [loginUser] = useLoginUserCCMutation();
  const userLoggedData = getToken();
  const uuserId = localStorage.getItem("user_id");
  const [userIsLogged, setUserIsLogged] = useState(true);

  const [userLoginCredits, setUserLoginCredits] = useState({});
  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserLoginCredits((values) => ({ ...values, [name]: value }));
  };

  const handleUserLogin = async (event) => {
    event.preventDefault();
    try {
      const res = await loginUser(userLoginCredits);

      if (res.data && res.data.status === "success") {
        // Store Token Code here
        storeToken(res.data.userinfo);
        Swal.fire({
          title: "Success",
          text: res.data.message,
          icon: "success",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          window.location.href = `/`;
        });
      } else {
        Swal.fire({
          title: "Error",
          text: res.data.message,
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-status?id=${uuserId}`,
          {
            responseType: "json",
          }
        );
        if (response.data.status === "Success") {
          removeToken(userLoggedData);
        } else {
          removeToken(userLoggedData);
        }
      } catch (error) {
        removeToken(userLoggedData);
      }
    };
    fetchData();
  }, [userIsLogged]);

  return (
    <>
      <Header />
      <div className="container full-width">
        <section className="signup">
          <div className="sign_up corporate">
            <div className="signup-content ">
              <div className="signup-form">
                <h2 className="form-title fw-bold">Corporate Login</h2>
                <p className="font-weight-semibold">
                  If you don't have an account,
                  <br />
                  You can{" "}
                  <Link
                    to="/enquiry"
                    className="font-weight-bold custom-text-color"
                  >
                    Enquiry Here!
                  </Link>
                </p>

                <form
                  className="register-form"
                  id="register-form"
                  onSubmit={handleUserLogin}
                >
                  <div className="form-group">
                    <Input
                      type="text"
                      id="username"
                      name="email"
                      size="large"
                      placeholder="Enter Your Email ID"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      className="custom-input w-100"
                      onChange={handleUserLoginDetails}
                    />
                  </div>
                  <div className="form-group">
                    <Input.Password
                      type="password"
                      id="password"
                      name="password"
                      size="large"
                      className="custom-input w-100"
                      placeholder="Enter Your Password"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onChange={handleUserLoginDetails}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-9 col-lg-9">
                      <Link
                        to="/forgot-password"
                        className="font-weight-bold partner-forget"
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                  <div className="form-group form-button pt-10">
                    <div className="button">
                      <input
                        className="reg-button"
                        type="submit"
                        name="send"
                        id="send"
                        value="Log In"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="signup-image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/partner_login.jpg`}
                  alt="Partner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
