import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import {
  getLeaseUserToken,
  storeLeaseUserToken,
  removeLeaseUserToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import { useLeaseLoginUserMutation } from "../../services/Apis/CarsInAfrica/Auth/userAuthApi";
import Swal from "sweetalert2";
import axios from "axios";

export default function LeaseLoginComp() {
  const [isLoading, setIsLoading] = useState(false);
  const [leaseUserIsLogged, setLeaseUserIsLogged] = useState(true);
  const [leaseLoginUser] = useLeaseLoginUserMutation();
  const [userid, setUserid] = useState(0);
  const userLoggedData = getLeaseUserToken();
  const leaseUserId = localStorage.getItem("lease_user_id");
  const LeaseUserLoggedData = getLeaseUserToken();

  const {
    term,
    pAddress,
    pCity,
    pState,
    pCountry,
    pCountryIso,
    pLatitude,
    pLongitude,
    vehicleAgeType,
    vehicleTypeId,
    vehicleTypeName,
    userId,
    months,
    years,
  } = useParams();

  useEffect(() => {
    if (userLoggedData?.lease_user_id != null) {
      setUserid(userLoggedData.lease_user_id);
    }
  }, [userLoggedData?.lease_user_id]);

  useLayoutEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://backend.carsinafrica.com/frontend/user-status?id=${leaseUserId}`,
          {
            responseType: "json",
          }
        );
        if (response.data.status === "Success") {
          removeLeaseUserToken(LeaseUserLoggedData);
        } else {
          setLeaseUserIsLogged(false);
          removeLeaseUserToken(LeaseUserLoggedData);
        }
      } catch (error) {
        removeLeaseUserToken(LeaseUserLoggedData);
      }
    };
    fetchUserData();
  }, [leaseUserIsLogged]);

  const [userLoginCredits, setUserLoginCredits] = useState({});

  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserLoginCredits((values) => ({ ...values, [name]: value }));
  };

  const handleUserLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const res = await leaseLoginUser(userLoginCredits);

      if (res.data && res.data.success === "1") {
        // Store Token Code here
        storeLeaseUserToken(res.data.userinfo);
        setIsLoading(false);
        Swal.fire({
          title: "Success",
          text: "You have successfully logged in.",
          icon: "success",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          if (term === undefined) {
            window.location.href = `/lease`;
          } else {
            window.location.href = `/lease/lease-booking/${term}/${pAddress}/${pCity}/${pState}/${pCountry}/${pCountryIso}/${pLatitude}/${pLongitude}/${vehicleAgeType}/${vehicleTypeId}/${vehicleTypeName}/${res.data.userinfo.lease_user_id}/${months}/${years}`;
          }
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "Error",
          text: "Invalid Login Credentials",
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: "Error",
        text: "Invalid Login Credentials",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid"
              alt="Loading..."
            />
          </div>
        </div>
      ) : (
        <div className="container full-width animate__animated animate__bounce pt-5">
          <section className="signup">
            <div className="sign_up corporate">
              <div className="signup-content lease-height mt-5">
                <div className="signup-form">
                  <h2 className="form-title fw-bold">Lease Login</h2>
                  <p className="font-weight-semibold">
                    If you don't have an account,
                    <br />
                    You can{" "}
                    <Link
                      to="/lease-user-registration"
                      className="font-weight-bold custom-text-color"
                      style={{ color: "#f57b20" }}
                    >
                      Register Here!
                    </Link>
                  </p>
                  <form
                    className="register-form"
                    id="register-form"
                    onSubmit={handleUserLogin}
                  >
                    <div className="form-group">
                      <Input
                        type="text"
                        id="username"
                        name="username"
                        size="large"
                        placeholder="Enter Your Email ID"
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        className="custom-input w-100"
                        onChange={handleUserLoginDetails}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <Input.Password
                        type="password"
                        id="password"
                        name="password"
                        size="large"
                        className="custom-input w-100"
                        placeholder="Enter Your Password"
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onChange={handleUserLoginDetails}
                        required
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-9 col-lg-9">
                        <Link
                          to="/lease-forgot-password"
                          className="font-weight-bold custom-text-color text-decoration-none"
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                    </div>
                    <div className="form-group form-button pt-10">
                      <div className="button">
                        <input
                          className="reg-button"
                          type="submit"
                          name="send"
                          id="send"
                          value="Log In"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="signup-image">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/partner_login.jpg`}
                    alt="Example"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
