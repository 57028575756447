import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Mainmenu.css";
import {
  getToken,
  storeLocation,
  removeToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";

export default function Mainmenu() {
  const activeLinkLocation = useLocation().pathname;
  const userLoggedData = getToken();
  const countryUrl = process.env.REACT_APP_URL_COUNTRY_CODE;
  const scrollRef = useRef(null);
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    const handleMenuClick = () => {
      setNavbarCollapsed(true);
    };

    const menuLinks = document.querySelectorAll(".nav-link");
    menuLinks.forEach((link) => {
      link.addEventListener("click", handleMenuClick);
    });

    return () => {
      // Clean up the event listeners
      menuLinks.forEach((link) => {
        link.removeEventListener("click", handleMenuClick);
      });
    };
  }, []);

  const scrollToContent = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleNavbar = () => {
    setNavbarCollapsed(!isNavbarCollapsed);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handleLogout = async () => {
    console.log(userLoggedData);
    removeToken(userLoggedData);
    window.location.href = `/user-login`;
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm bg-light navbar-light tablet-view">
        <div className="container nav-bar-tablet" id="header-width">
          {/* START -> Mobile Responsive code */}
          {userLoggedData.user_id == null ? (
            ""
          ) : (
            <div className="navbar-nav menu-nav-margin px-2" id="nav-toggle">
              <a
                href="#"
                className="d-profile text-decoration-none dropdown-toggle text-black"
                onClick={toggleDropdown}
              >
                <i
                  className="bi bi-person-circle text-black small-profile-circle"
                  id="profile_circle"
                />{" "}
              </a>
              <div
                className={`dropdown-menu dropdown-menu-start text-small ${
                  isDropdownOpen ? "show" : ""
                }`}
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to="/dashboard"
                    onClick={closeDropdown} // Close the dropdown on link click
                  >
                    <i className="fas fa-chart-bar me-2"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/profile"
                    onClick={closeDropdown} // Close the dropdown on link click
                  >
                    <i className="fas fa-user me-2"></i> My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/change-password"
                    onClick={closeDropdown} // Close the dropdown on link click
                  >
                    <i className="fas fa-lock me-2"></i> Change Password
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <a className="dropdown-item" href="#" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt me-2"></i> Sign out
                  </a>
                </li>
              </div>
            </div>
          )}
          {/* END -> Mobile Responsive code */}
          <button
            type="button"
            className={`navbar-toggler ${isNavbarCollapsed ? "collapsed" : ""}`}
            onClick={toggleNavbar}
            id="cia-nav"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse navbar-collapse ${
              isNavbarCollapsed ? "" : "show"
            }`}
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                  aria-controls="collapsibleNavbar"
                  aria-expanded={!isNavbarCollapsed}
                  style={{ color: activeLinkLocation === "/" ? "red" : null }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/about-us"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                  aria-controls="collapsibleNavbar"
                  aria-expanded={!isNavbarCollapsed}
                  style={{
                    color: activeLinkLocation === "/about-us" ? "red" : null,
                  }}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/our-services"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                  aria-controls="collapsibleNavbar"
                  aria-expanded={!isNavbarCollapsed}
                  style={{
                    color:
                      activeLinkLocation === "/our-services" ? "red" : null,
                  }}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item menu-alignment main-menu-nav-item">
                <a
                  className="nav-link"
                  href="https://carsinafrica.com/blogs/"
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Necessary for security
                >
                  Blogs
                </a>
              </li>
              <li className="nav-item menu-alignment main-menu-nav-item">
                <Link
                  className="nav-link"
                  to="/contact-us"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                  aria-controls="collapsibleNavbar"
                  aria-expanded={!isNavbarCollapsed}
                  style={{
                    color: activeLinkLocation === "/contact-us" ? "red" : null,
                  }}
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  to="/lease"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                  aria-controls="collapsibleNavbar"
                  aria-expanded={!isNavbarCollapsed}
                  className="nav-link"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    type="button"
                    className="btn btn-success lease-link"
                    style={{
                      color: activeLinkLocation === "/lease" ? "red" : null,
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      class="bi bi-car-front-fill"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Lease
                  </button>
                </Link>
              </li>
            </ul>
            {userLoggedData.user_id == null ? (
              <ul className="navbar-nav" id="cia-nav-item">
                <li className="nav-item">
                  <Link
                    to="/user-login"
                    className="nav-link"
                    data-toggle="collapse"
                    data-target="#collapsibleNavbar"
                    aria-controls="collapsibleNavbar"
                    aria-expanded={!isNavbarCollapsed}
                  >
                    <button
                      type="button"
                      className="btn btn-danger main-menu-bg-button"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={process.env.PUBLIC_URL + "/images/vector.svg"}
                          alt="Icon"
                          className="mr-2 main-menu-login"
                        />
                        <span id="cia-login-header">Login</span>
                      </div>
                    </button>
                  </Link>
                </li>
                <li className="nav-item" id="button-spacing">
                  <Link
                    to="/user-registration"
                    className="nav-link"
                    data-toggle="collapse"
                    data-target="#collapsibleNavbar"
                    aria-controls="collapsibleNavbar"
                    aria-expanded={!isNavbarCollapsed}
                  >
                    <button
                      type="button"
                      className="btn btn-danger main-menu-registration-bg"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={process.env.PUBLIC_URL + "/images/vector.svg"}
                          alt="Icon"
                          className="mr-2 btn-main-menu-registration"
                        />
                        <span id="cia-register-header">Register</span>
                      </div>
                    </button>
                  </Link>
                </li>
              </ul>
            ) : (
              <div
                className="navbar-nav main-menu-dropdown-margin px-2"
                id="nav-dropDown"
              >
                <a
                  href=""
                  className="d-profile text-decoration-none dropdown-toggle text-black"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="bi bi-person-circle text-black"
                    id="profile_circle"
                  />{" "}
                  Profile
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-start text-small dashboard-auto-margin"
                  aria-labelledby="dropdownUser1"
                >
                  <li>
                    <Link className="dropdown-item" to="/dashboard">
                      <i className="fas fa-chart-bar me-2"></i> Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      <i className="fas fa-user me-2"></i> My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/change-password">
                      <i className="fas fa-lock me-2"></i> Change Password
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      <i className="fas fa-sign-out-alt me-2"></i> Sign out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
